import axios from 'axios';
import React,{useEffect, useState,Fragment} from 'react'
import { FormText } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { setReportUserDetails } from '../../../AnalyzerSlice';
import Option from './inputs/Option'

// a unique name for each question



    
function Question(props){
    let Qarr=[];
    const [notvalidate, setnotvalidate] = useState(false)
//    let questionId=props.question.questionId;
   const [values,setvalues] = useState(props.question['choices'].reduce((a, key,i) => Object.assign(a, { [key['choice_name']]: key['inputtype']==='checkbox'?false:null }), {}))

   const putValue=(val)=>{
    setnotvalidate(false)
        console.log(val)
        // add validator
       setvalues(prev=>({...prev,...val}))
      
   
    }
    
       
        Qarr=props.question['choices'].map(choice=>(<Option choice={choice} question={props.question} putValue={(val)=>putValue(val)} ></Option>))
       

    
     
   
   const nextPage=()=>{
            console.log(values)
          
        
        if(Object.keys(values).every(key=>values[key])){
            setnotvalidate(false)
            props.gotoNext(values)
           
        }else{
            setnotvalidate(true)
        }
           
   
   }
   
   return(
        <div className="questionContainer" style={{display:props.displayId===props.id?'block':'none'}}>
           <div className='questionTitle'>{props.question.question_title}</div>
           {Qarr}
           {notvalidate?<p style={{color:'red'}}>*Please enter correct value.</p>:''}
           <div className="questionButtons">
                   <div className="prevBtn" onClick={props.id===0?undefined:props.gotoPrev}>Prev</div>
                    <div className="nextBtn" onClick={nextPage}>{ props.id!==props.fullLength-1?'Next':'Submit'}</div>
                    
            </div>
       </div>
   
   )
}

    
        
    

function QuestionSlide(props) {
    const [display,setdisplay]=useState(props.opt)
    const [QuestionData,setQuestion]=useState(props.QuestionData)
    const [questionNo,setquestionNo]=useState(0)
    const [value,setvalue]=useState([])
    const report_id=useParams().rid
    const health_score = useSelector(state=>state.analyzer.report_details.patient_details.health_score);
    const dispatch = useDispatch()
   
    
   const nextPage=(data)=>{
       console.log(data)
       if(questionNo!==QuestionData.questions.length-1){
         setquestionNo(prev=>prev+1)
        }
        setvalue(prev=>([...prev,data]))
        if(questionNo===QuestionData.questions.length-1){
            console.log(value)
            setdisplay(false)

        }
       
   }
   useEffect(()=>{
       if(questionNo===QuestionData.questions.length-1 && value.length===QuestionData.questions.length){
           console.log('inn')
           let patient_details = {}
           let resData = {}
           value.forEach(pair=>{
               patient_details[Object.keys(pair)[0]] = pair[Object.keys(pair)[0]]
            })
            resData.report_id = report_id
            resData.patient_details = patient_details
            resData.patient_details.age = parseInt(resData.patient_details.age)
            if('test_taken_date' in patient_details){
                resData.test_taken_date = patient_details['test_taken_date'];
                delete resData.patient_details.test_taken_date;
                
            }
            
            resData.patient_details.health_score = health_score
            resData.statusCode = 200
            console.log(resData)
            
            axios.post("https://1vghspa9zh.execute-api.ap-south-1.amazonaws.com/Production/update_family_report_info-handler",resData,{headers:{
                'x-api-key': 'vByc1RZdswafTBn87Wlqx1reenvU1HK9Yjw7a5xi'
            }})
            .then(res=>{
                console.log(res)
                console.log(res.data)
                if(res.data.statusCode===200){
                    dispatch(setReportUserDetails({updated_user_details:resData.patient_details,test_taken_date:resData.test_taken_date}))
                }
            }).catch(err=>console.log(err))

        }
   },[value])
   const prevPage=()=>{
    setquestionNo(prev=>prev-1)
}
    


    
    

    
    return (
        <div className='QuestionWrapper' style={display?{display:'block'}:{display:'none'}}>
             
            {QuestionData && <div className="QuestionContainer">
                <div className="questionHeader">
                    
                    <div onClick={()=>setdisplay(false)} style={{width:'fit-content',marginLeft:'auto'}}><i className='bx bx-x' style={{    fontSize: 'xx-large'}}></i></div>
                    <p className='questionslide_title'>{QuestionData.title}</p>
                    
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{fontColor:'gray',fontSize:'small'}}>Question {questionNo+1} of {QuestionData.questions.length}</div>
                        <div style={{height:'4px',backgroundColor:'#a5a4a4',flexGrow:'1',marginLeft:'1rem'}}>
                            <div style={{width:((questionNo+1)/QuestionData.questions.length)*100+'%',backgroundColor:'black',height:'100%',transition:'all 0.3s ease-in-out'}}></div>
                        </div>
                    </div>
                    
                </div>
            

        
                <div>
                    {QuestionData.questions.map((question,i,arr)=><Question fullLength={arr.length} question={question} displayId={questionNo} id={i} gotoNext={(data)=>nextPage(data)} gotoPrev={prevPage} ></Question>)}

                </div>
                
            </div>}

   
            
           
            
          
        </div>
    )
}

export default QuestionSlide
