import React, { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query, where, limit, startAt, startAfter  } from 'firebase/firestore';
import { db } from '../../../firebase-config'
import BlogCard from '../BlogCards/BlogCard';
import moment from 'moment';

function PaginationContainer(props) {
    const {initialBlogs, category, onePageCapacity, totalPages} = props;
    const [categoryPage, setCategoryPage] = useState(0);
    const [categoryBlogs, setCategoryBlogs] = useState([...initialBlogs]);
    
    
    const BlogRef = collection(db, "blog_meta");
    
    

    useEffect(()=>{
        setCategoryBlogs(initialBlogs);
         
    },[initialBlogs])

    const getPreviousBlogs = () => {
        if(categoryPage!==0){
            let blogsArr = [];
            let start_point = new Date(moment.unix(categoryBlogs[0].updated_on.seconds));
            setCategoryPage(prev=>prev-1);
            let retrievedCategoryBlogs = query(BlogRef, where("categories", "array-contains", category), orderBy('updated_on'), startAfter(start_point), limit(onePageCapacity));

            getDocs(retrievedCategoryBlogs).then(blogs=> {
               
                blogs.docs.forEach(blog=>{
                    blogsArr.push(blog.data())
                })
                console.log(blogsArr)
                setCategoryBlogs(blogsArr.reverse())
            })
        }
    }
    const getNextBlogs = () => {
        console.log('click')
        if(categoryPage!==totalPages-1){
            let start_point = new Date(moment.unix(categoryBlogs[categoryBlogs.length-1].updated_on.seconds));
            let blogsArr = [];
            setCategoryPage(prev=>prev+1);
            let retrievedCategoryBlogs = query(BlogRef, where("categories", "array-contains", category), orderBy('updated_on', 'desc'), startAfter(start_point), limit(onePageCapacity));

            getDocs(retrievedCategoryBlogs).then(blogs=> {
                
                blogs.docs.forEach(blog=>{
                    blogsArr.push(blog.data())
                })
                console.log(blogsArr)
                setCategoryBlogs(blogsArr)
            })
        }
    }
    

    
    
  return (
    <div className='category-blogs-pages-container'>
            <div style={{display:'grid',alignItems:'center',gridTemplateColumns:'1fr 1fr',paddingBottom:'2rem'}}>
                <h2 className='big-header-h2' >More Blogs <span style={{color:'var(--secondary-color-purple)'}}>on {category}</span> </h2>
                <div className="category-blog-carousel">
                    <div className='carousel__btn' onClick={getPreviousBlogs}><i class='bx bx-chevron-left' ></i></div>
                    <div>{categoryPage+1} of {totalPages}</div>
                    <div className='carousel__btn' onClick={getNextBlogs}><i class='bx bx-chevron-right'></i></div>
                </div>
            </div>
            
            <div className='category-blogs-page'>
               {categoryBlogs.map((blog,i)=><BlogCard blog={blog} type={window.innerWidth>500?"vertical-blog-card":'horizontal-blog-card'} key={"category-"+i}></BlogCard>
                )}
            </div>
            
                
    </div>
  )
}

export default PaginationContainer