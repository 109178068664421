import React,{Fragment, useCallback, useContext} from 'react'
import { useSelector } from 'react-redux';
import { NavLink,Redirect } from 'react-router-dom'

import {  animateScroll as scroll } from 'react-scroll';
import { patientContext } from '../Context';
import { useHistory, useParams,useRouteMatch } from 'react-router';

function Superfood({recmDetail,allRecms,type,name,filter}) {
    const rid=useParams().rid
    scroll.scrollToTop({delay:0,duration:0})
    const history=useHistory();
    console.log(history)
    const {url} = useRouteMatch()
    console.log(url)
    if(recmDetail){
        // const benefits=recmDetail.benefits.map((benefit,i)=>(<li>{benefit}</li>))
        // const precautions=recmDetail.contraindications.map((el,i)=>(<li>{el}</li>))
       
    
    
    return (
      
        <Fragment>
            <div className="navbar">
                
            <NavLink to={{pathname:`/wb/smart_report/${rid}/recommendations`,recommendations:allRecms,activeState:'yoga',filter:filter}}><i className='bx bx-left-arrow-alt'></i></NavLink>
                <p>{name.replace('_',' ')}</p>
                
            </div>
            <div className="recmImage">
            <img src={`${process.env.PUBLIC_URL}/report_assets/img/${type}/${recmDetail.display_name.toLowerCase().replaceAll(' ','_')}.jpg`} ></img>
            </div>
            <div className="recmDescription">
           
                <h4 className="recmTitle">{name.replace('_',' ')} 
                <span style={{marginLeft:'0.2rem',fontSize:'15px',color:'black'}} > {recmDetail.also_known_as?`(${recmDetail.also_known_as})`:''}</span></h4> 
                <hr style={{backgroundColor:'#664277'}}></hr>
                <div style={{fontWeight:'200',fontSize:'smaller'}}>{recmDetail.description}</div>
                
            
                <div className="instructionWrapper did_you_know">
                    {recmDetail.did_you_know &&<div className='eachInstructionContainer'>
                        <p className="sectionTitle" style={{color:'#FF5F45'}}>
                        <i class='bx bx-bulb'></i> Did You Know?
                        </p>
                        <div className='fact'></div>
                    </div>}
                    <div className='eachInstructionContainer benefits'>
                        <p className="sectionTitle">
                        <i class='bx bx-donate-heart' ></i> Benefits for you
                        </p>
                        <ul className="sectionList">
                        {recmDetail.benefits.map((b,i)=>(<li key={`${type}FullRecm_${i}`}>{b}</li>))}
                        </ul>
                    </div>
                    {type==='supplement' && <div className='eachInstructionContainer natural_sources'>
                        <p className="sectionTitle">
                            Natural sources
                        </p>
                        <ul className="sectionList">
                        
                        {recmDetail.natural_sources.map((b,i)=>(<li key={`${type}FullRecm_${i}`}>{b}</li>))}
                        </ul>
                    </div>}
                    {type==='supplement' && <div className='eachInstructionContainer brands'>
                        <p className="sectionTitle">
                            Brands Available
                        </p>
                        <ul className="sectionList">
                        
                        {recmDetail.brands_available.map((b,i)=>(<li key={`${type}FullRecm_${i}`}>{b}</li>))}
                        </ul>
                    </div>}
                
                    {type==='superfood' && <div className='eachInstructionContainer best_way_consume'>
                        <p className="sectionTitle">
                            Best Way to consume
                        </p>
                        <ul className="sectionList">
                        <p style={{marginBottom:'0.5rem',fontWeight:'200',fontSize:'smaller'}}> Its is recommended to have <strong>{recmDetail.dosage_recommended}</strong> daily</p>
                        {recmDetail.how_to_use.map((b,i)=>(<li key={`${type}FullRecm_${i}`}>{b}</li>))}
                        </ul>
                    </div>}
{/* 
                    {type==='superfood' && <div className='recipesWrapper'>
                        <p className="sectionTitle">
                            Must try recipes
                        </p>
                        <div className='recipeScrollContainer'>
                        <div className='recipeScroll'>
                            <div className='recipeContainer'>
                                <div className='eachRecipe'>Recipe</div>
                                <div className='eachRecipe'>Recipe</div>
                                <div className='eachRecipe'>Recipe</div>
                                <div className='eachRecipe'>Recipe</div>
                                <div className='eachRecipe'>Recipe</div>
                                <div className='eachRecipe'>Recipe</div>
                            </div>
                        </div>

                        </div>
                       

                    </div>} */}
                    {recmDetail.precautions.length>0 && <div className='eachInstructionContainer precautions'>
                        <p className="sectionTitle">
                        <i class='bx bx-info-square' ></i> Precautions And Contraindications
                         
                        </p>
                        <ul className="sectionList">
                        {recmDetail.precautions.map((b,i)=>(<li key={`${type}FullRecm_${i}`}>{b}</li>))}
                        </ul>
                    </div>}
                    {(type==='superfood' ) && <div className='eachInstructionContainer where_to_find'>
                        <p className="sectionTitle">
                        <i class='bx bx-search-alt'></i> Where to find
                        </p>
                        <ul className="sectionList">
                        {recmDetail.where_to_find.map((b,i)=>(<li key={`${type}FullRecm_${i}`}>{b}</li>))}
                        </ul>
                    </div>}
                    
                </div>        
            </div>

        </Fragment>
        
    )}
    else{
        return(<Redirect to='/'></Redirect>)
    }
}

export default Superfood
