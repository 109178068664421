import React, { useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getotp, getOTP, loginUser} from '../../AuthSlice';
import OtpInput from 'react-otp-input';
import './Login.css'
import { useHistory } from 'react-router';
import Header from '../../../../components/Header/Header';

import gsap from 'gsap/all';
import Patterns from '../../../../components/Patterns';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { db } from '../../../../firebase-config';
import { collection, where , query, getDocs, getDoc, doc, setDoc} from 'firebase/firestore';
import Button from '../../../../components/Button/Button';

const usersCollection = collection(db,'users')

function Login({path}) {
    const registerBtnRef = useRef(null)
    
    const user = useSelector(state=>state.auth);
    const dispatch=useDispatch();
    const history= useHistory();
    
    const [phoneno,setphoneno] = useState('');
    const [otp,setotp] = useState('');
    const [errorMsg,seterrorMsg] = useState('');
    useEffect(()=>{
        if(user.statusCode===200){
            // check the history obj
            // history.push('/pdf-analyzer')
            history.push('/profile')
        }
    },[user.statusCode])


    const getotp = () => {
        let auth = getAuth()
        let appVerifier = window.recaptchaVerifier
        signInWithPhoneNumber(auth, '+91'+phoneno, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                dispatch(loginUser({user_details:{mobile_no:'+91'+phoneno} , statusCode:201}))
            }).catch((error) => {
                appVerifier.render().then(function(widgetId) {
                    window.grecaptcha.reset(widgetId);
                });
                console.log(error)
                
        });
    }
     
    const verifyotp = () =>{
   
        window.confirmationResult.confirm(otp)
        .then(res=>{
            console.log(res.user.uid)
            const q = doc(usersCollection,res.user.uid)
            getDoc(q).then((user)=>{
                if(user.exists() && user.data().firstName!==''){
                    dispatch(loginUser({user_details:{...user.data()} , statusCode:200}))
                }else{
                    setDoc(doc(usersCollection, res.user.uid), {uId:res.user.uid, phoneNumber:'+91'+phoneno,createdAt: new Date()})
                    dispatch(loginUser({user_details:{uId:res.user.uid} , statusCode:201}))
                    history.push('/register?mobile_no='+phoneno+'&&?country_code=+91')
                }
                
            })
            .catch(err=>console.log(err))
            // get user info from database
           
        })
        .catch((error)=>{
            console.log(error);
            dispatch(loginUser({ statusCode:""}))
            

        })
    }
    const getCaptcha = () =>{
        if(window.recaptchaVerifier===undefined){
            console.log("entered")
            const auth = getAuth();
            window.recaptchaVerifier =  new RecaptchaVerifier('login-button', {'size': 'invisible'}, auth);
            console.log(window.recaptchaVerifier);
            getotp()
        }
    }

    const registerBtnClicked = () =>{
        gsap.to(registerBtnRef.current,{boxShadow:'none',duration:0.8,ease: 'none'})
        gsap.to(registerBtnRef.current,{boxShadow:'1px 1.2px 5px #4a4a4ac2',duration:0.5,ease: 'none'})
        if(user.statusCode===''){
            
            // check no in database

            console.log("checking database");
            getCaptcha()
           
        }if(user.statusCode===201){
            verifyotp()
        }
        

    }
    
   
    return (
        <>
        <Header></Header>
        <div id="loginContainer">
            <div className="headerWave">
               <Patterns type="LOTUS"></Patterns>
                {user.statusCode==="" ?<h1><span style={{fontSize:'smaller',fontWeight:'300'}}>Hello there!<br></br></span>You're one step closer to good health </h1> : <h1>Verify OTP</h1>}
                
            </div>
            <div className="loginInputsContainer">
                
                <div style={{position:'relative',width:'100%',display:'grid',justifyContent:'center'}}> 

                    <label className="login_labels">{user.statusCode==="" ? 'Enter Mobile Number' : 'Enter OTP' }</label>

                    {(user.statusCode==='') && <div style={{position:'relative',display:'flex',justifyContent:'center'}}><span className="country-code">+91</span><input  className='input-field' type='tel' name='mobile_no' value={phoneno} onChange={(e)=>setphoneno(e.target.value)} style={{paddingLeft: '3.3rem',letterSpacing: '1px'}} autoFocus={true}></input></div>}

                    {(user.statusCode===201) && <OtpInput containerStyle="otpInputContainer" inputStyle="input-field" value={otp} shouldAutoFocus={true} isInputNum={true} onChange={(otp)=>setotp(otp)} numInputs={6} separator={<span style={{padding:'0rem 0.2rem'}}>-</span>}/>}
                </div>
                
                
                {<div className='center action-btn__primary' ref={registerBtnRef} onClick={()=>registerBtnClicked()} id="login-button" statusMessages={{0:'Get OTP',1:'Get OTP',2:'Sending OTP'}} key='login-button'>{user.statusCode===''?'Get OTP':'Verify OTP'}</div>   }
                <div className='errorMsg' style={{color:'red'}}>{errorMsg}</div>
            </div>
            
            
        </div>
        
        </>
    )
}

export default Login
