import React, { Fragment, useEffect, useRef, useState } from 'react';
import './Questionnaire.css';
import gsap from 'gsap';
import * as QuestionData from '../../../../components/Constants/QuestionnairesData'
import QuestionSlider from '../../../../components/QuestionSlider/QuestionSlider';
import { ImageUrl } from '../../../../components/HelperFunctions';
import KeyboardInputs from '../../../../components/Inputs/KeyboardInputs';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { db } from '../../../../firebase-config';
import { collection,  getDoc, doc, setDoc} from 'firebase/firestore';
import Button from '../../../../components/Button/Button'
import { loginUser } from '../../../auth/AuthSlice';
import { useParams } from 'react-router';
const {nanoid} = require('nanoid');
// this component takes the whole questionnaires data and passes the questions to questinSLide component
console.log(Object.values(QuestionData))



const usersCollection = collection(db,'users')
// start, ongoing, calculating, enter_patient_details, results 
function Questionnaire() {
    const dispatch = useDispatch()
    const questionnaireID = useParams().qid
    const questionData = Object.values(QuestionData).filter(questionnaire=>questionnaire.questionnaireID===questionnaireID)[0]
    const loaderRef = useRef(null);
    const [survey_data, setsurvey_data] = useState({})
    const questionnaireRef = useRef(null);
    const [questionnairePageStatus,setquestionnairePageStatus] = useState('start');
    const user_details = useSelector(state=>state.auth);
    
    const [error, seterror] = useState('')
    const [user_details_inputs,setuser_details_inputs] = useState({mobile_no:'',opt:''});
    const [score, setscore] = useState(-1)
    const startQuestionnaire = () =>{
        
        let start = gsap.timeline()
        start.to(loaderRef.current,{
            opacity:1
        })
        start.to(loaderRef.current,{
            yPercent:-100,
            opacity:0,
            display:'none',
            duration:0.5
        },'+=1')
        start.to(questionnaireRef.current,{
            opacity:1,
            display:'block'
        },'+=0.2')
       
    }
    const hideQuestionnaire = () =>{
        setquestionnairePageStatus('calculating')
        let hidingQuestionnaire = gsap.timeline({})
        hidingQuestionnaire.to(questionnaireRef.current,{opacity:0,display:'none'})
        hidingQuestionnaire.fromTo(loaderRef.current,{yPercent:0},{opacity:1,display:'block',duration:1.5})
    }


    const getotp = (mobile_no) => {
        let auth = getAuth()
        let appVerifier = window.recaptchaVerifier
        signInWithPhoneNumber(auth, '+91'+mobile_no, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                dispatch(loginUser({user_details:{mobile_no:'+91'+mobile_no} , statusCode:201}))
            }).catch((error) => {
                appVerifier.render().then(function(widgetId) {
                    window.grecaptcha.reset(widgetId);
                });
                console.log(error)
                
        });
    }
     
    const verifyotp = (otp) =>{
        
        seterror('')
        if(otp.length===6){
        window.confirmationResult.confirm(otp)
        .then(res=>{
            console.log(res.user.uid)
            const q = doc(usersCollection,res.user.uid)
            getDoc(q).then((user)=>{
                if(user.exists()){
                    
                    dispatch(loginUser({user_details:{...user.data()} , statusCode:200}))
                }else{
                    setDoc(doc(usersCollection, res.user.uid), {uId:res.user.uid, phoneNumber:'+91'+user_details.user_details.mobile_no,createdAt: new Date()})
                    dispatch(loginUser({user_details:{uId:res.user.uid} , statusCode:201}))
                }
                
            })
            .catch(err=>console.log(err))
            // get user info from database
           
        })
        .catch((error)=>{
            console.log(error);
            seterror("Invalid OTP. Retry again")
            

        })
    }
    }
    const getCaptcha = (mobile_no) =>{
        console.log("hi", mobile_no)
        if(window.recaptchaVerifier===undefined){
            console.log("entered")
            const auth = getAuth();
            window.recaptchaVerifier =  new RecaptchaVerifier('login-button', {'size': 'invisible'}, auth);
            console.log(window.recaptchaVerifier);
            getotp(mobile_no)
        }
    }

    
    // start questionnaire when component loads
    useEffect(()=>{startQuestionnaire()},[])

   useEffect(()=>{
       if(user_details.user_details.uId){
        //    add survey data to users collection
        
        let questionnaire_inputs = {};
        survey_data.forEach(q=>{
            questionnaire_inputs = {...questionnaire_inputs,...q}
        })
        console.log(questionnaire_inputs)
        let this_questionnaire_data = {
            questionnaire_id: 'test-1234',
            questionnaire_title:questionData.title,
            timestamp: new Date(),
            inputs:questionnaire_inputs,
            redirected_via:'',
            taken_by:'',
            url_from:'https://neodocs.in/questionnaire/1',
            outputs:{'score':score},
            user_id:user_details.user_details.uId
        }

        console.log(this_questionnaire_data)
        let questionnaireCollection = collection(db, `users/${user_details.user_details.uId}/questionnaires/${questionnaireID}/responses`)
        setDoc(doc(questionnaireCollection, nanoid(4)), this_questionnaire_data)
        setquestionnairePageStatus('results')
           
       }
   },[user_details])



    

    const calculate_score = (survey_data) => {
        let score = 0;
        survey_data.forEach(metric=>score+=parseInt(Object.values(metric)[0]))
        setscore(score)
    }
    const onSubmitQuestionnaire = (questionnaire_data) => {
        console.log('submit')
        hideQuestionnaire()
        
        setsurvey_data(questionnaire_data)
        calculate_score(questionnaire_data)
        setTimeout(()=>{setquestionnairePageStatus('enter_patient_details')},2000)   
    }
  return (
      <div id="questionnaire-main-container">
            <div className='questionnaire-loader' ref={loaderRef}>
                {questionnairePageStatus==='start' && <Fragment>
                <h1 className='big-header-h1'>What kind of a "sleeper" are you?</h1></Fragment>}
                {questionnairePageStatus==='calculating' && <Fragment><lottie-player src="https://assets8.lottiefiles.com/packages/lf20_xrq4wyy3.json"  background="transparent"  speed="1"  style={{height:'10rem'}}  loop autoplay ></lottie-player>

                <h1 className='big-header-h1' style={{'text-align':'center'}}>Calculating your score</h1></Fragment>}
                {questionnairePageStatus==='results' && <Fragment>
                    <h1 className='big-header-h1'>You are {score<8?'Wideawake':score>=8 && score <16 ? 'a napster' : 'a sleepy head'}</h1>
                    
                    <div className='results-infographics'>
                        <img src={ImageUrl('/questionnaires/sleepiness/arrow_you.svg')} id="arrow" style={{left:`${Math.abs((score/24*100))}%`}} alt="info"></img>
                       <img src={ImageUrl('/questionnaires/sleepiness/sleep_linear_gauge.svg')} id='linear-gauge' alt="info" ></img>
                       <div className='result-texts'>
                           <div>You don't tend to doze off while reading books, sitting in a car, talking to people or post lunch!</div>
                           <div>You may doze off while talking to people, reading books, sitting in a car or laying post lunch!</div>
                           <div>You  doze off while talking to people, reading books, sitting in a car or laying post lunch!</div>
                       </div>
                    </div>
                    
                    <div className='upload-report-whatsapp-link-container'>
                        <h5 className='big-header-h2' style={{marginTop:'2rem'}}>Get more insights on your health by uploading your blood report.</h5>
                        <p style={{marginBottom:'1rem'}}>Simply forward you medical report on whatsapp at +91 74002 81971 </p>
                        <a href="https://wa.me/+917400281971?text=Hi"><i class='bx bxl-whatsapp'></i> Upload through whatsapp</a>
                        <a href="" ><i class='bx bx-share-alt'></i>Share the quiz</a>
                    </div>
                </Fragment>}
                {questionnairePageStatus==='enter_patient_details' && <Fragment>
                    <h1 className='big-header-h1'>Yay! 🥳 <br></br>Your results are ready </h1>
                    <h1 className='big-header-h2' style={{marginBottom:'3rem'}}>Validate your phone number to see them.</h1>
                    <div className='user_details_form'>
                       
                            {/* <Fragment>
                                
                                <KeyboardInputs type="text" regex={/^[a-zA-Z]{1,}$/} label="Your Name" onSubmit={}></KeyboardInputs>
                                <KeyboardInputs type="number" regex={/^[1-9]{1,3}$/} label="Your Age" ></KeyboardInputs>
                            </Fragment> */}
                        
                        <KeyboardInputs type="number" regex={/^[7-9][0-9]{9}$/} label="Enter Phone Number" onSubmit={(mobile_no)=>setuser_details_inputs(prev=>({...prev,'mobile_no':mobile_no}))}></KeyboardInputs>
                        <div id="login-button"></div>
                        {/* {user_details.statusCode===201 && <KeyboardInputs type="number" regex={/^[0-9]{6}$/} label="Enter OTP" onSubmit={(otp)=>verifyotp(otp)}></KeyboardInputs>} */}
                        {user_details.statusCode===201 && <Fragment>
                            <div>Enter the OTP</div>
                            <OtpInput containerStyle="otpInputContainer" inputStyle="input-field"  value={user_details_inputs.otp}  shouldAutoFocus={true} isInputNum={true} onChange={(otp)=>setuser_details_inputs(prev=>({...prev,'otp':otp}))} numInputs={6} separator={<span style={{padding:'0rem 0.2rem'}}>-</span>}/>
                        </Fragment>}
                       
                        {user_details.statusCode==='' && <div className='action-btn__primary' onClick={()=>getCaptcha(user_details_inputs.mobile_no)}>Get OTP</div>}
                        {user_details.statusCode===201 && <div className='action-btn__primary' onClick={()=>verifyotp(user_details_inputs.otp)}>Verify OTP</div>}
                        <div className='error-messages'>{error}</div>
                        
                        

                    </div>
                </Fragment>}
            </div>
            <div className='questionnaire-wrapper' ref={questionnaireRef}>
                <QuestionSlider QuestionData={questionData} onSubmitQuestionnaire={onSubmitQuestionnaire}></QuestionSlider>
            </div>
      </div>
    
  )
}

export default Questionnaire