import React, { useEffect, useState } from 'react'


// 0 : disabled button
// 1 : enabled button
// 2 : processing state
function Button(props, ref) {

    
    useEffect(()=>{
        console.log(ref)
    },[])
    
    const {status, style, statusMessages, className,id} = props
    const functionalityStyles = {
        0:{
            cursor:'none',
            filter:'grayscale(0.4)'
        },
        1:{
            cursor:'pointer',
            filter:'none'
        },
        2:{
            cursor:'none',
            filter:'grayscale(0.4)'
        }
    }
    const buttonClicked = () =>{
        props.onClick();

    }

    return (
        <div ref={ref} className={'button '+className} style={{...functionalityStyles[status],...style}}  onClick={()=>buttonClicked()} id={id}>
            {status===2 && <i className='bx bx-loader-circle bx-spin' ></i>}
            {statusMessages[status]}
        </div>
    )
}
const forwardedButton = React.forwardRef(Button)
export default forwardedButton
