import React, { Fragment, useEffect, useState } from 'react';
import './Inputs.css'
const sliderNumberMap={
    1:'Very Unhealthy',
    2:'Unhealthy',
    3:''
}

function Inputs(props) {
    const {type,display,value}=props.options;
    const [ip,setip]=useState(props.question_type==='single'?'':[])

    

    useEffect(()=>{
        if(ip.length>0){
            props.eneteredInput(ip)
        }
    },[ip])

    useEffect(()=>{
        if(!props.active){
            setip('')
        }
    },[props.active])
    
    return (
        <Fragment>
            {(type==='text' || type==='number') && <input className='input' type={type} name={props.question_name} value={ ip[props.question_name] }  onChange={(e)=>setip(e.target.value)}></input>
            }
            {(type==='checkbox') &&<label className={`inputWrap ${props.active && 'active'}`}>
                <p>{display}</p>
                <input className='input' type={type} name={props.question_name} value={ value } onClick={(e)=>setip(e.target.value)}></input>
            </label>}
            {type==='slider' && <div class="diet_slider">
                <label>{ip || 1}</label>
                <input type="range" name={props.question_name} value={ ip[props.question_name]  }  id="diet_range" min="1" max="10"  onChange={(e)=>setip(e.target.value)} />
                <label class="slider_label" style={{left: 0}}>Unhealthy</label>
                <label class="slider_label" style={{right: 0}}>Balanced</label>
            </div>}
            
            
        </Fragment>
        
    )
}

export default Inputs
