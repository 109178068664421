import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import './Profile.css';
import Header from '../../../../components/Header/Header'
import { useSpring, animated } from 'react-spring';
import { ImageUrl } from '../../../../components/HelperFunctions';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
function Profile() {
    const user_details=useSelector(state=>state.auth.user_details);
    const [edit_details,setedit_details]=useState(user_details)
    const [edit,setedit]=useState(false);
    const history = useHistory();
    const spring=useSpring({
        zIndex : edit ? 900 : 0,
        opacity: edit ? 1 :0,
        top: edit ? 90 : 300,
        pointerEvents:edit ? 'visible':'none'
        
    })
    if('uId' in user_details){
    return (
        <>
        <Header></Header>
        <div className='user-profile-container'>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <h2>Hi, <br></br>{user_details.firstName }👋🏻</h2>
                
            </div>
            
            
            {!user_details.first_test && <div className="profileDetails">
            <p className="cursor" onClick={()=>setedit(true)}><i class='bx bx-edit-alt' ></i>{user_details.first_test?'Add your details':'Edit Profile'}</p>
                <div style={{display:'flex',justifyContent:'space-between'}}> 
                    <div>
                        <p className='profileHead'>Gender</p>
                        <p className="">{user_details.gender}</p>
                    </div>
                    <div>
                        <p className='profileHead'>Height</p>
                        <p className="">{user_details.height} cm</p>
                    </div>
                    <div>
                        <p className='profileHead'>Weight</p>
                        <p className="">{user_details.weight} kg</p>
                    </div>
                </div>
                <div style={{borderTop:'1px solid #444444',paddingTop:'1rem', display:"flex",justifyContent:'space-between'}}>
                    <div>
                        <p className='profileHead'><i className='bx bx-bullseye'></i> Goals</p>
                        <div>
                            {user_details.goals && user_details.goals.map(goal=><p className="onegoal"><i class='bx bxs-circle' ></i> {goal}</p>)}
                        </div>
                    </div>
                    <div>
                        <p className='profileHead'><i class='bx bx-basket'></i> Diet</p>
                        <div>
                            {user_details.diet && user_details.diet.map(goal=><p className="onegoal"><i class='bx bxs-circle' ></i> {goal}</p>)}
                        </div>
                    </div>
                </div>
            </div>}
            <div className='services-container'>
                <h3 style={{gridColumn:'1 / 3',color:'#1d1d43',fontWeight:'800',fontSize:'smaller',textTransform:'uppercase'}}>Try our</h3>
                <NavLink to="/pdf-analyzer" className='service pdf-analyzer'>
                        <h5>Pdf Analyzer</h5>
                        <p>Understand your reports better <i class='bx bx-right-arrow-alt'></i></p>
                </NavLink>
                <NavLink to="/drug-interaction-checker" className='service drug-interaction-checker'>
                        <h5>Drug Interaction Checker</h5>
                        <p>Check the various interactions of a medicine <i class='bx bx-right-arrow-alt'></i></p>
                </NavLink>
            </div>

            <animated.div className="editprofile-form" style={spring}>
                <img src={ImageUrl('poct/editprofile.svg')}/>
                <div>
                    <div className='flex-horizontal-between' style={{marginBottom:'2rem'}}>
                        <h4>Edit profile</h4>
                        <div className="cursor" onClick={()=>setedit(false)}><i style={{fontSize:'1.5rem'}} className='bx bx-x'></i></div>
                    </div>
                    <div className="allInputsContainer">
                        <div className='fullColumn'>
                            <label className="editProfile_labels">Email</label>
                            <input className="input-field" name="email_id" type='text' value={edit_details.email_id || ''} onChange={(e)=>setedit_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                        </div>
                        <div>
                            <label className="editProfile_labels">First name</label>
                            <input className="input-field" name="fname" type='text' value={edit_details.fname || ''}  onChange={(e)=>setedit_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                        </div>
                        <div>
                            <label className="editProfile_labels">Last name</label>
                            <input className="input-field" name="lname" type='text' value={edit_details.lname || ''} onChange={(e)=>setedit_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                        </div>
                    
                    </div>
                    <div className='actionBtn center'>Edit Profile</div>

                    
                </div>
                
                
            </animated.div>


        </div>
        </>
    )}else{
        return(<>{history.push('/login')}</>)
    }
}

export default Profile
