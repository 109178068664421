import React from 'react'
import { useLocation, useParams } from 'react-router';

import '../css/knowMore.css'
import Yoga from './eachRecommendation/Yoga'
import Superfood from './eachRecommendation/Superfood'




function Practise(props) {

    const {type,name}=useParams();
    const {recmDetail,filter}=useLocation();
    const allRecms = useLocation(state=>state.analyzer.report_details.recommendations)
   
    if(type==='yoga'){
        return (<Yoga recmDetail={recmDetail} allRecms={allRecms} name={name} type={type} filter={filter}></Yoga>)
    }else{
        return (<Superfood recmDetail={recmDetail} allRecms={allRecms} name={name} type={type} filter={filter}></Superfood>)
    }

    
}

export default Practise
