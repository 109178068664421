// constant questions for question slide component
// question data for a user other than the registered user who uploaded the report
export const DIFFERENT_USER_QUESTIONS = {
        title:"Kindly fill the patient's details whos report has been uploaded.",
        
        questions:[{
                questionId:0,
                question_title:"What is the patient's first name?",
                choices:[
                    {inputtype:"text",choice_title:"First Name",choice_name:"firstName"},
                ],
                
                accepted_no_of_ips:"single"
            },
            {
                questionId:1,
                question_title:"What is the patient's last name?",
                choices:[
                    {inputtype:"text",choice_title:"Last Name",choice_name:"lastName"},
                ],
                
                accepted_no_of_ips:"single"
            },
            {
                questionId:2,
                question_title:"What is the patient's age?",
                choices:[
                    {inputtype:"text",choice_title:"Age",choice_name:'age'},
                ],
                
                accepted_no_of_ips:"single"
            },
            
            {
                questionId:3,
                question_title:"What is the patient's gender?",
                choices:[
                    {inputtype:"radio",choice_title:"Female",choice_name:"gender",val:"female"},
                    {inputtype:"radio",choice_title:"Non Binary",choice_name:"gender",val:'non_binary'},
                    {inputtype:"radio",choice_title:"Male",choice_name:"gender",val:'male'}
                    
                ],
     
                accepted_no_of_ips:"single"
            }
        ]
}

export const NO_TEST_DATE_DIFFERENT_USER_QUESTIONS = {
    title:"Kindly fill the patient's details whos report has been uploaded.",
    questions:[{
            questionId:0,
            question_title:"What is the patient's first name?",
            choices:[
                {inputtype:"text",choice_title:"First Name",choice_name:"firstName"},
            ],
            
            accepted_no_of_ips:"single"
        },
        {
            questionId:1,
            question_title:"What is the patient's last name?",
            choices:[
                {inputtype:"text",choice_title:"Last Name",choice_name:"firstName"},
            ],
            
            accepted_no_of_ips:"single"
        },
        {
            questionId:2,
            question_title:"What is the patient's age?",
            choices:[
                {inputtype:"text",choice_title:"Age",choice_name:'age'},
            ],
            
            accepted_no_of_ips:"single"
        },
        
        {
            questionId:2,
            question_title:"What is the patient's gender?",
            choices:[
                {inputtype:"radio",choice_title:"Female",choice_name:"gender",val:"female"},
                {inputtype:"radio",choice_title:"Non Binary",choice_name:"gender",val:'non_binary'},
                {inputtype:"radio",choice_title:"Male",choice_name:"gender",val:'male'}
                
            ],
 
            accepted_no_of_ips:"single"
        },
        {
            questionId:3,
            question_title:"When was this test taken? ",
            choices:[
                {inputtype:"date",choice_title:"",choice_name:'test_taken_date'},
                
            ],
 
            accepted_no_of_ips:"single"
        }
    ]
}

export const NO_TEST_DATE_SAME_USER_QUESTIONS = {
    title:"Kindly enter the date on which this test was taken. This will help us provide you trends in your smart report.",
    questions:[
        {
            questionId:0,
            question_title:"Test Taken Date: ",
            choices:[
                {inputtype:"date",choice_title:"",choice_name:'test_taken_date'},
                
            ],
 
            accepted_no_of_ips:"single"
        }
    ]
}

