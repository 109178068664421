import React, { useState } from 'react';
import Swipe from 'react-easy-swipe';
import Bar from './Bars';
import CovidReport from './CovidReport';
import { Tab,Tabs } from 'react-bootstrap'
import LineGraph from './LineGraph'
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';
import '../css/panel.css';
import { getString, getArray, ReportImageUrl } from '../../../../components/HelperFunctions';

let Element=Scroll.Element

function PanelHead(props){
    const {data,insights} = props;
    const risk = getString(insights.risk,'');
    return(
        <div className='panelHead'>
            <div className="panelDetailCont">
                <img src={ReportImageUrl(`/panels/${getString(data.icons,'').replace('png','svg')}`)} alt={data.icons}className="panelIcon"></img>
                <div className='metaData'>
                    <div className="biomarker-title">{getString(data.panel_name,'') }</div>
                    <div className={`note_${risk==='risk' ? 'risk' : risk==='good' ? 'safe' : risk==='borderline' ? 'borderline' : ''} small note `}>{risk==='risk' ? 'CONCERN' : risk==='good' ? 'GOOD' : risk==='borderline' ? 'BORDERLINE' : ''}</div>
                </div>
                
            </div>
            {props.children}   
        </div>
    )
}

function PanelSummary(props){
    const {data,panelData} = props
    const abnormalParametersArray = getArray(Object.keys(panelData.details.normal_range).filter(bio=>(panelData.details.normal_range[bio].user_value_flag!==0)),[]);
    const normalParametersArray = getArray(Object.keys(panelData.details.normal_range).filter(bio=>(panelData.details.normal_range[bio].user_value_flag===0 )),[])
    
    return(
        <div className="summary">
            {/* overview */}
            <p className='small summaryHead' >{data.insight ? "OVERVIEW" : ""}</p>
            <p className='panelSummary small'>{getString(data.insight,'')}</p>
            {/* parameters at a glance */}
            <div className='parameter-summary-container' style={{marginTop:'1rem'}}>
                <p className='summaryHead' style={{paddingBottom:'0.5rem',fontSize:'x-small !important'}}>PARAMETERS AT A GLANCE</p>
                <div className='abnormal-parameters-summary parameters-summary'>

                    <div style={{fontSize:'small',fontWeight:'800',textTransform:'uppercase',color:'#595959',letterSpacing:'0.6px'}}><i class='bx bx-sad' ></i> abnormal parameters ({ abnormalParametersArray.length })</div>

                    <div className='parameters-container'>{abnormalParametersArray.length>0 ? abnormalParametersArray.map(bio=>(<p>{panelData.details.normal_range[bio].biomarker_name}</p>)):'No abnormal parameters'}</div>

                </div>
                <div className='normal-parameters-summary parameters-summary'>

                    <div style={{fontSize:'small',fontWeight:'800',textTransform:'uppercase',color:'#595959',letterSpacing:'0.6px'}}><i class='bx bx-smile' ></i>normal parameters ({ normalParametersArray.length })</div>

                    <div className='parameters-container'>{normalParametersArray.length>0 ? normalParametersArray.map(bio=>(<p>{panelData.details.normal_range[bio].biomarker_name}</p>)):'No normal parameters'}</div>
                </div>
                <p className='small summaryHead' style={{fontSize:'x-small!important'}}>Swipe to see parameters in detail <i className='bx bx-right-arrow-alt'></i></p>
            </div>
           
        </div>
    )
}

// (Object.values as replacement for array)
function PanelMarker({biomarkersArr,biomarkersObj}){
    let Biomarker=[]
    if(biomarkersArr===null){
        Biomarker = Object.values(biomarkersObj).map((bio,i)=>{
        return(
            <div className="markerDetail" key={i}>
                <div className="summaryHead">
                    {bio.biomarker_name}
                </div>
                <div className="panelSummary" dangerouslySetInnerHTML={{__html:bio.desc}}></div>
                {bio.ref_range && <div className='reference-range'>
                    <p className='small-note' style={{fontWeight:500,color:'black',fontSize:'x-small'}}>Reference Range:</p>
                    <div className=' small' style={{fontWeight:'bold'}} dangerouslySetInnerHTML={{__html:bio.ref_range}}></div>

                </div>}
                
                    <div className='panelBarValueWrapper'>
                        <div className='summaryHead'>Your Value:<br></br> <span className={`biomarker-value biomarker-value_${bio.user_value_flag}`}>{bio.user_value} </span>
                            {getString(bio.unit,'').toLowerCase()!=='ratio' && <span dangerouslySetInnerHTML={{__html:bio.unit}}></span>}
                        </div>
                        
                        {bio.bar_needed ?  
                            <Bar normalRange={bio}></Bar>:''}
                    </div> 
            </div>
        )})
    }
    else if(biomarkersArr!==null || biomarkersArr!==undefined){
         Biomarker=biomarkersArr.map((bio,i)=>{
            if(bio in biomarkersObj){
                return(

                    <div className="markerDetail" key={i}>
                        <div className="summaryHead">
                            {biomarkersObj[bio].biomarker_name}
                        </div>
                        <div className="panelSummary" dangerouslySetInnerHTML={{__html:biomarkersObj[bio].desc}}></div>

                        {biomarkersObj[bio].ref_range && <div className='reference-range'>
                            <p className='small-note' style={{fontWeight:500,color:'black',fontSize:'x-small'}}>Reference Range:</p>
                            <div className=' small' style={{fontWeight:'bold'}} dangerouslySetInnerHTML={{__html:biomarkersObj[bio].ref_range}}></div>

                        </div>}

                        <div className='panelBarValueWrapper'>
                            <div className='summaryHead'>Your Value:<br></br> 
                            <span className={`biomarker-value biomarker-value_${biomarkersObj[bio].user_value_flag}`}>{biomarkersObj[bio].user_value} </span>
                            {getString(biomarkersObj[bio].unit,'').toLowerCase()!=='ratio' && <span dangerouslySetInnerHTML={{__html:biomarkersObj[bio].unit}}></span>}
                            </div>
                            {(biomarkersObj[bio].bar_needed) ? <Bar normalRange={biomarkersObj[bio]}></Bar>:''}
                        </div>
                    </div>
                )
            }
        })
    }
    return(
        <div>
            <div>{Biomarker.length>0 ? Biomarker: 'No biomarkers were fetched in this report.'}</div>
        </div>
        
    )
    
    
}

// Historic Trends
function PanelHistoricTrends({biomarkersObj,biomarkersArr}){
    let graphs=[];
    // trends_present is a flag that indicates whether any biomarker has a value_history with lenght >0 
    let trends_present = Object.keys(biomarkersObj).some(bio=>getArray(biomarkersObj[bio].value_history).length>1)
    if(biomarkersArr.length===0){
        graphs = Object.values(biomarkersObj).map((biomarker,i)=>(<LineGraph biomarkerDetail={biomarker} key={`bio_graph_${i}`}></LineGraph>));
    }
    else{graphs=biomarkersArr.filter(bio_in_arr => bio_in_arr in biomarkersObj).map((bio,i)=>(<LineGraph biomarkerDetail={biomarkersObj[bio]} key={`bio_graph_${i}`}></LineGraph>))}
    return(
        <div className="historicTrendsConatiner">
          {trends_present ? graphs : <p style={{paddingTop:'1rem'}}>Upload more reports to see trends.</p>}
        </div>
        
    )
}

function PanelInfo({panelNameKey}){
    const report_details = useSelector(state=>state.analyzer.report_details);
    const panelData = report_details.panel_details[panelNameKey] || {};
    const panelInsights = report_details.insights[panelNameKey] || {};
    const covid_report = Object.keys(report_details.panel_details).indexOf('covid') === -1 ? false : true;
    const [activeTab,setactiveTab] = useState('0');
    // swipe functionality
    const swipeRight=(e)=>{if(!covid_report){setactiveTab(prev=>(prev!=='0'?(parseInt(prev)-1).toString():'0'))}}
    const swipeLeft=(e)=>{if(!covid_report){ setactiveTab(prev=>(prev!=='2'?(parseInt(prev)+1).toString():'2'))}}
    
    return(
        <Element name={panelData.icons} className="panelElement">
            <div className={" panelWrapper panelWrapper_"+panelInsights.risk} >
                <Swipe onSwipeLeft={swipeLeft} onSwipeRight={swipeRight} allowMouseEvents={true} tolerance={70}>
                    <div className="panelInfoDetails" >
                        {!covid_report && <Tabs defaultActiveKey='0' activeKey={activeTab} onSelect={(k)=>setactiveTab(k)}  > 
                            <Tab eventKey="0" title="Summary">
                            {<div className='wrap' >
                                <PanelHead data={panelData} insights={panelInsights ? panelInsights : {}}>
                                </PanelHead>
                                <PanelSummary panelData={panelData} data={Object.keys(panelInsights).length>0 ? panelInsights : {insight:''}}></PanelSummary>
                            </div>}
                                
                            </Tab>
                            <Tab eventKey="1" title="Parameters">
                                <div className='wrap'>
                                    <PanelHead data={panelData} insights={panelInsights?panelInsights:{}}></PanelHead>
                                    <PanelMarker biomarkersObj={panelData.details.normal_range} biomarkersArr={panelData.biomarkers?panelData.biomarkers:null}></PanelMarker>
                                
                                </div>
                            </Tab>
                            <Tab eventKey="2" title="Trends">
                                <div className='wrap'>
                                <PanelHead data={panelData} insights={panelInsights?panelInsights:{}}></PanelHead>
                                <PanelHistoricTrends biomarkersObj={panelData.details.normal_range} biomarkersArr={panelData.biomarkers?panelData.biomarkers:null}></PanelHistoricTrends>
                                </div>
                        
                            </Tab>
                            
                        </Tabs>}
                        {covid_report && <CovidReport></CovidReport>}
                        
                        
                        
                    </div>
                </Swipe> 
            </div>
        </Element>
    )
}
export default PanelInfo;