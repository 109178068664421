import React, { useEffect, useRef} from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
// ANIMATIONS
import { gsap } from "gsap";            
import {ScrollTrigger} from "gsap/ScrollTrigger";
// components
import Testimonials from '../Usables/Testimonials';
import { ImageUrl } from '../../../../components/HelperFunctions';
import AppTour2 from './AppTour2';
import Patterns from '../../../../components/Patterns';
import './HomePage.css';

// registers gsaps plugin
gsap.registerPlugin(ScrollTrigger)
// const lottieOptions = {
//     loop:false,
//     autoPlay:true,
//     animationData:arrowLottie,
//     rendererSettings: {
//         preserveAspectRatio: "xMidYMid slice"
//       }

// }


function HomePage() {
//    this ref will contain all sections to be used for triggers in scroll triggers
    const section_heroRef = useRef(null);  
    // const section_stepRef = useRef([]);
    const section_parameterRef = useRef(null);
    const section_hiw = useRef(null);
    const section_benefits = useRef();
   
    //    hero section
   useEffect(() => {
        let t1=gsap.timeline()
        t1.fromTo(section_heroRef.current, {opacity:0}, {duration: 1, opacity:1});
        t1.fromTo(section_heroRef.current.querySelector('h1'),{opacity:0,y:50},{opacity:1,y:0},"-=0.5")
    },[section_heroRef])

  

//    wellnesscard
   useEffect(()=>{
    
       let t1 = gsap.timeline({scrollTrigger:{
            trigger:section_hiw.current,
            start:'top-=50% top',
     
            end:()=>window.innerHeight*2,
            pin:true,
            pinSpacing:false,
            scrub:2,
            toggleActions:'play complete reverse reset',
            fastScrollEnd:true,
            preventOverlaps:true,
        
        }})
       
        t1.fromTo(section_hiw.current.querySelector('#wellness-card-container'),
        {
            trigger:section_heroRef.current,
            rotate:'-30deg',
            // scale:5,
            overflow:'visible',
            yPercent:window.innerWidth>500?-60:-80,
            xPercent: window.innerWidth>500?-40:-30            
        },
        {   
            yPercent:30,
            xPercent:0,
            rotate:'0deg',
            duration:1.5
            // scale:1,   
        })
        t1.to(section_heroRef.current.querySelector('img'),{opacity:0},'<')
        t1.to(section_hiw.current,{yPercent:-50},'<')
        
        
        t1.to(section_hiw.current,{backgroundColor:'#000324',duration:0.5})
        
        t1.fromTo(section_hiw.current.querySelector('#dip-text'), {opacity:0}, {duration: 1, opacity:1},'<')
        
        
        t1.fromTo(section_hiw.current.querySelector('#urine-cup'),
        {
            opacity:0,
            yPercent:20
        },
        {   opacity:1,
            yPercent:-40,
            duration:1
           
        },'<')
        t1.to(section_hiw.current.querySelector('#urine-cup'),
        {
            opacity:0
        },'+=0.5')

        t1.to(section_hiw.current.querySelector('#dip-text'), { opacity:0},'<')
        // t1.to(section_hiw.current,{backgroundColor:'#2157a3'})
        t1.fromTo(section_hiw.current.querySelector('#scan-text'), {opacity:0}, {duration: 1, opacity:1,},'<0.5')
        
        
        t1.fromTo(section_hiw.current.querySelector('#phone-scan'),
        {
            opacity:0
        },
        {
            
            yPercent:-103,
            opacity:1,
            duration:1.5,
        },'<')
        t1.to(section_hiw.current.querySelector('#scan-text'), { opacity:0},'>0.2')
        t1.to(section_hiw.current.querySelector('#wellness-card-container'),{
            opacity:0
        },'<0.5')
        t1.to(section_hiw.current.querySelector('#phone-scan'),
        {
            id:'phone',
            yPercent:-90,
         
        },'<')
        t1.to(section_hiw.current.querySelector('#phone-scan'),
        {
            opacity:0
         
        },'<')
        // t1.to(section_hiw.current,{backgroundColor:'#3080f0'})
        t1.fromTo(section_hiw.current.querySelector('#control-text'), {opacity:0}, { opacity:1,scale:1.2,color:'white'},'<0.5')
        
    
        t1.fromTo(section_hiw.current.querySelector('#take-control'),
            {
                opacity:0
            },
            {
                yPercent:-204,
                opacity:1,
                duration: 1.5
                
                
            },'<')

        
   },[section_hiw])
//    parameters
    useEffect(()=>{
        let parameterArray = [...section_parameterRef.current.querySelectorAll('.each-parameter')]
        let parameters_timeline = gsap.timeline({scrollTrigger:{
            id:'params',
            start:'top-=50% top',
            end:'bottom top',
            trigger:section_parameterRef.current,
            toggleActions:'play none none reverse',
        
            
            
        }})
        parameters_timeline.to(section_hiw.current.querySelector('#take-control'),{opacity:0})
        // parameters_timeline.to(section_hiw.current,{backgroundColor:'white'},'<')
        
        // parameters_timeline.fromTo(section_parameterRef.current,{backgroundColor:'#000324'},{backgroundColor:'white'},'<')
        
        parameters_timeline.fromTo(section_parameterRef.current.querySelectorAll('h1'),{opacity:0},{opacity:1},'<')
        parameters_timeline.fromTo(section_parameterRef.current.querySelectorAll('svg'),{opacity:0},
        {opacity:0.6},'<')
        parameters_timeline.fromTo(section_parameterRef.current.querySelectorAll('h2'),{opacity:0},{opacity:1})
        parameterArray.forEach(param=>parameters_timeline.fromTo(param,{opacity:0},{opacity:1,duration:0.1}),'<')
        
        
        
    },[section_parameterRef])
    // useEffect(()=>{
    //     section_stepRef.current.forEach((step,idx)=>{
    //         let t1 = gsap.timeline({scrollTrigger:{
    //             trigger:step,
             
    //             id:`step-sec${idx}`,
                
    //             start:'top 50%',
    //             toggleActions:'play none none reverse',
                
                
    //         }})
    //         t1.fromTo(step.querySelector('.step-circle'),{
    //             scale:0,
    //             opacity:0
    //         },
    //         {
    //             opacity:1,
    //             scale:1
    //         })
    //         t1.fromTo(step.querySelector('.step-path'),
    //             {
    //                 height:0
    //             }, 
    //             {   duration: 0.5, 
    //                 height:'100%',
    //                 ease:'none'
    //             })

    //     })
    // })
    
  
    // const addToStepRef = (el) =>{
    //     if(el && !section_stepRef.current.includes(el)){
    //         section_stepRef.current.push(el);
    //     }
    // }

    // benefits
    useEffect(()=>{
        let benefitTimeline = gsap.timeline({scrollTrigger:{
            trigger:section_benefits.current,
            start:'top top+=70%',
            end:()=>window.innerHeight*1,
            toggleActions:'play complete reverse reset'
        }})
        let benefitsArray = [...section_benefits.current.querySelectorAll('.one-benefit')]

        benefitTimeline.fromTo(section_benefits.current.querySelector('.big-header-h1'),{opacity:0,y:30},{opacity:1,y:0});
        benefitTimeline.fromTo(section_benefits.current.querySelector('.neodocs-logo'),{opacity:0},{opacity:1});
        benefitsArray.forEach(benefit => benefitTimeline.fromTo(benefit,{opacity:0},{opacity:1,duration:'0.3'}))
        
    },[section_benefits])
    
    
    return (
        <div className='homePage' >
            <Header pos={10}></Header>
           
                    
                        <section ref={section_heroRef} id="hero" style={{backgroundImage:`url(${ImageUrl('poct/mock-hero.png')})`,backgroundSize:'cover'}}>
                           
                            <div className='illus'>
                                {/* <img alt="neodocs app" src={ImageUrl('poct/app-tour/Wellness.png')}></img> */}
                                <img alt="neodocs app" src={ImageUrl('poct/resultscreen_mockup_1.png')}></img>
                            </div>
                           
                           <div>
                               {/* <img src={ImageUrl('poct/HERO-PATTERN.svg')}></img> */}
                               <h1 className='big-header-h1' >Transform your health one parameter at a time</h1>
                               {/* <h2 className='subheader'>Neodocs is an all-round wellness tracker in the palm of your hand. It comprises of various smart-phone based instant health tests that are painless, hassle-free & accurate.</h2> */}
                               <div className='all-links'>
                                    <NavLink  to={{pathname:'/pricing',state:{personalisation:false,personalised_data:{}}}} className="action-btn__primary" > Get Started 🚀 </NavLink>
                                    <div className='get-app-links'>
                                        <a href="https://play.google.com/store/apps/details?id=com.app.neodocs" className="appstore get_app_btn">
                                            <div >
                                                <i className="bx bxl-play-store"></i>
                                               
                                            </div>
                                            
                                        </a>
                                        |
                                        <a href="https://apps.apple.com/in/app/neodocs/id1579332354" className="playstore get_app_btn">
                                            <div >
                                                <i className="bx bxl-apple"></i>
                                               
                                            </div>
                                            
                                        </a>
                                    </div>
                               </div>
                               
                               
                              

                           </div>
                           
                        </section>
                        <section className='wellness-card-intro' style={{position:'relative',height:'100vh'}} ref={section_hiw}>
                            <div style={{position:'absolute',top:0,bottom:0,left:"1rem",right:"1rem",textAlign:'center'}}>
                            {/* <h1 className='big-header-h1'>Unlock The Gift of Health With Three Simple Steps
 <span style={{color:'var(--primary-color-green)'}}>...</span></h1> */}
                            </div>
                            
                            <div className='texts'>
                                <div id="dip-text" className='text-para big-header-h1'>Dip the card in urine</div>
                                <div id="scan-text" className='text-para big-header-h1'>Scan it </div>
                                <div id="control-text" className='text-para big-header-h1'>Take <span style={{color:'var(--primary-color-green)'}}>Control</span> </div>

                            </div>
                            <div>
                                <img id='wellness-card-container'  src={ImageUrl('poct/hero/wellness-card.svg')} style={{objectFit:'contain'}} alt="Neodocs wellness card"></img>
                                <img id="urine-cup" style={{objectFit:'contain'}} src={ImageUrl('poct/app-tour/cup_dip3.png')} alt="Neodocs how it works"></img>
                                <img id="phone-scan" src={ImageUrl('poct/app-tour/phone_scan.png')} style={{objectFit:'contain'}}  alt="Neodocs how it works"></img>
                                <img id="take-control" src={ImageUrl('poct/resultscreen_mockup_1.png')} style={{objectFit:'contain'}}  alt="Neodocs how it works"></img>
                            </div>

                            
                        
                        </section>
                  
                        
                        <section  ref={section_parameterRef} id="parameters" >
                                {/* <Patterns type="HOURGLASS"></Patterns> */}
                                <h1 className='big-header-h1'> Conquer the obstacles <br></br>in your fitness journey 🧗🏻‍♀️
</h1>
                                {/* <h2 className='sub-header'>Keep tabs across several panels</h2> */}
                                <div className='parameters-container'>
                                <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/Kidney.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>Kidney health</div>
                                    </div>
                                    <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/Liver.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>Liver health</div>
                                    </div>
                                    <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/Hydration.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>Hydration</div>
                                    </div>
                                    {/* <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/Digestion.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>Digestion</div>
                                    </div> */}
                                    <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/Ketones.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>Ketones</div>
                                    </div>                
                                    {/* <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/Focus.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>Focus</div>
                                    </div>
                                    <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/Energy.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>Energy</div>
                                    </div>
                                    <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/Skin.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>Skin</div>
                                    </div> */}
                                    
                                    
                                    <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/UTI.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>UTI</div>
                                    </div>
                                    <div className='each-parameter'>
                                        <div className='each-parameter__icon'>
                                            <img alt="neodocs parameters health" src={ImageUrl('poct/paramIcons/base.svg')}></img>
                                        </div>
                                        <div className='each-parameter__name'>pH</div>
                                    </div>

                                </div>
                               
                                <NavLink style={{margin:'2rem auto'}}  to={{pathname:'/pricing',state:{personalisation:false,personalised_data:{}}}} className="action-btn__secondary" > Start my fitness journey today 💪🏻</NavLink>  
                                
                                
                            
                        </section>
                        {/* <section id='care-kits'>
                            <h1 className='big-header-h1'>Our specialized care kits</h1>
                            <div className='all-care-kits-wrapper'>
                                <div className='one-care-kit'>
                                    <div>
                                        <div className='one-carekit__name'>Wellness test kit</div>
                                        <div className='one-carekit__desc'>10 parameters</div>
                                    </div>
                                    
                                    <div style={{background:'#000749'}} className='one-carekit__image' >
                                        <img alt="neodocs care kits" src={ImageUrl('poct/care-kits/wellness-card.png')}></img>
                                    </div>
                                </div>
                                <div className='one-care-kit'>
                                    <div>
                                        <div className='one-carekit__name'>UTI test kit</div>
                                        <div className='one-carekit__desc'>4 parameters to detect Urinary Tract Infections</div>
                                    </div>
                                    <div style={{background:'#5F2E59'}} className='one-carekit__image' >
                                        <img alt="neodocs care kits" src={ImageUrl('poct/care-kits/uti-card.png')}></img>
                                    </div>
                                </div>
                                <div className='one-care-kit'>
                                    <div>
                                        <div className='one-carekit__name'>Elderly Care Kit</div>
                                        <div className='one-carekit__desc'>10 parameters</div>
                                    </div>
                                    <div style={{background:'#426C9C'}} className='one-carekit__image' >
                                        <img alt="neodocs care kits" src={ImageUrl('poct/care-kits/elderly-card.png')}></img>
                                    </div>
                                </div>
                                <div className='one-care-kit'>
                                    <div>
                                        <div className='one-carekit__name'>Pregnancy Care Kit</div>
                                        <div className='one-carekit__desc'>7 parameters</div>
                                    </div>
                                    <div style={{background:'#61468D'}} className='one-carekit__image' >
                                        <img alt="neodocs care kits" src={ImageUrl('poct/care-kits/pregnancy-card.png')}></img>
                                    </div>
                                </div>
                                <div className='one-care-kit'>
                                    <div>
                                        <div className='one-carekit__name'>Kidney Care Kit</div>
                                        <div className='one-carekit__desc'>4 parameters</div>
                                    </div>                              
                                    <div style={{background:'#d36450'}} className='one-carekit__image' >
                                        <img alt="neodocs care kits" src={ImageUrl('poct/care-kits/kidney-card.png')}></img>
                                    </div>
                                </div>
                            
                            </div>
                        </section> */}
                        
                        
                    
                    
                    
                    
                        <section id="benefits" ref={section_benefits} >
                            <h1 className='big-header-h1' style={{textAlign:'center'}}>Have <span style={{color:'var(--primary-color-green)'}}>futuristic healthcare diagnostics</span> back you every step of the way. 🏃🏻</h1>
                            <div className='all-benefits-container'>
                                <div className='neodocs-logo'>
                                    <img alt="neodocs" src={ImageUrl('poct/logo/neodocs-small-logo.svg')}></img>
                                </div>
                                <div className='one-benefit' style={{backgroundColor:'var(--secondary-color-purple)'}}>
                                    <img alt="neodocs" src={ImageUrl('poct/benefits/instant.svg')}></img>
                                    <p className='one-benefit__title'>Instant</p>
                                    <p className='one-benefit__desc'></p>
                                </div>
                                <div className='one-benefit' style={{backgroundColor:'var(--secondary-color-blue__light)'}}>
                                    <img alt="neodocs" src={ImageUrl('poct/benefits/convinience.svg')}></img>
                                    <p className='one-benefit__title'>Convenient</p>
                                    <p className='one-benefit__desc'></p>
                                </div>
                                <div className='one-benefit' style={{backgroundColor:'var(--secondary-color-purple__dark)'}}>
                                    <img alt="neodocs" src={ImageUrl('poct/benefits/low-cost.svg')}></img>
                                    <p className='one-benefit__title'>Low Cost</p>
                                    <p className='one-benefit__desc'></p>
                                </div>
                                <div className='one-benefit' style={{backgroundColor:'var(--secondary-color-blue__dark)'}}>
                                    <img alt="neodocs" src={ImageUrl('poct/benefits/personalised.svg')}></img>
                                    <p className='one-benefit__title'>Personalised</p>
                                    <p className='one-benefit__desc'></p>
                                </div>

                                
                            </div>
                            <NavLink style={{margin:'1rem auto'}}  to={{pathname:'/pricing',state:{personalisation:false,personalised_data:{}}}} className="action-btn__primary" > I want to start right away! 🚀</NavLink>
                        </section>
                        
                        
                        <AppTour2></AppTour2>
                        

                    
                       
                    
                    <section  id='get_app'>
                            
                       
                            <div className='heading'>
                                <h1 className='big-header-h1'>One app that takes care of it all.</h1>
                                <p className='sub-header'>Download the Neodocs app and turn your smartphone into your personal wellness tracker today!</p>
                               
                                <div className="app_btns" >
                                        <a href='https://play.google.com/store/apps/details?id=com.app.neodocs&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                        </a>
                                        
                                        <a href="https://apps.apple.com/in/app/neodocs/id1579332354?itsct=apps_box_badge&amp;itscg=30200" >
                                            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1628553600&h=9fe675414fa4d50ce298fc80da620207" alt="Download on the App Store" />
                                        </a>
                                    </div>
                            </div>
                            <div>
                                <img src={ImageUrl('poct/new-mockups/wellness_card_app.png')} alt="Neodocs app" className='app-card-img'></img>
                            </div>
                            
                            
                        </section>
                    
                        <Testimonials></Testimonials>
                        
                    
                        <Footer></Footer>
                    
        </div>
    )
}

export default HomePage