import React, { useEffect } from 'react';
import {useSelector} from 'react-redux';
import {Chart,registerables} from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
Chart.register(...registerables);
Chart.register(annotationPlugin)
Chart.register(ChartDataLabels)


const colors = {
    // red: 'rgb(246,94,91)',
    red: '#FF6961',
    green: '#23e5af',
    orange: 'rgb(254,199,96)',
    greenBg: 'rgba(35,229,175,0.2)',
    redBg: 'rgba(246,94,91,0.2)'
}
 
 


// get the annotation line
const annotationLine = (from, to, color) => ({
    drawTime: "beforeDatasetsDraw",
    type: "box",
    xScaleID: "x-axis-0",
    yScaleID: "y-axis-0",
    xMin: 0,
    xMax: 0,
    yMin: from,
    yMax: to,
    backgroundColor: color,
    borderColor: color,
    borderWidth: 10,
    borderRadius:10
});     
    
 
export default function LineGraph({biomarkerDetail})  {

    let chartRef = React.createRef();
    const test_taken_date = useSelector(state=>state.analyzer.report_details.test_taken_date)
    let currDate=new Date(test_taken_date);
    currDate=moment(currDate).format('DD-MM-YY');

    console.log(biomarkerDetail.value_history.length)
   useEffect(()=>{
       if(biomarkerDetail.value_history.length>0 && biomarkerDetail.is_categorical!==1){
            const xbarDates = biomarkerDetail['value_history'].map(history=>moment(history.date).format('DD-MM-YY'))
            const ybarValues=biomarkerDetail['value_history'].map(history=>history.value)
            const {min_value,max_value,user_value,lb,ub,lb_ideal,ub_ideal,user_value_flag}=biomarkerDetail
            const MIN_VALUE = min_value * 0.75
            const MAX_VALUE = max_value * 1.45
            const MAX_TICKS_LIMIT = 5;
            const STEP_SIZE = (MAX_VALUE - MIN_VALUE) / (MAX_TICKS_LIMIT+2); 
            const singleChartPercent = (MAX_VALUE - MIN_VALUE);
            const userValuePercent = ((user_value - MIN_VALUE) / singleChartPercent) * 100;

            const myChartRef = chartRef.current.getContext("2d");
            const lineChart = new Chart(myChartRef, {
                type: 'line',
                data: {
                    labels: xbarDates,
                    datasets: [{
                        data: ybarValues,
                        lineTension: 0.2,
                        fill: false,
                        
                        borderWidth:2,
                        backgroundColor:"#6c73cd",
                        borderColor:"#6c73cd",
                        pointRadius: 4.5,
                        pointBorderWidth: 1,
                        pointBorderColor:function(context) {
                            var index = context.dataIndex;
                            var value = index === context.dataset.data.length-1 ? user_value_flag : biomarkerDetail['value_history'][index] ? biomarkerDetail['value_history'][index]['user_value_flag'] : '';
                            return (value === 0) ? colors.green :  
                                colors.red
                        },
                        pointBackgroundColor:  function(context) {
                            var index = context.dataIndex;
                            var value = index === context.dataset.data.length-1 ? user_value_flag : biomarkerDetail['value_history'][index] ? biomarkerDetail['value_history'][index]['user_value_flag'] : '';
                            return (value === 0) ? colors.green :  
                                colors.red
                        },
                        
                    }]
                },
                options:{
                    responsive:true,
                    
                    scales: {
                        x: {
                            
                            ticks: {
                                beginAtZero:false,
                                fontSize:12
                            },
                            grid:{
                                display:false,
                                
                            }
                        },
                        y: {
                            grid:{
                                display:false
                            
                            },
                            ticks: {
                                maxTicksLimit: MAX_TICKS_LIMIT,
                                stepSize: STEP_SIZE,
                                fontColor: '#707070',
                                max: MAX_VALUE,
                                min: MIN_VALUE,
                                fontSize:10,
                                autoSkip:true,
                                callback: (value) => `${Math.round(value * 10) / 10}`
                            }
                        }
                    },
                       
                    
                    
                    plugins: {
                        legend:{
                            display:false
                        },
                        datalabels: {
                            anchor: userValuePercent < 85 ? 'start' : 'end',
                            align: userValuePercent < 85 ? 'top' : 'bottom',
                            offset: 10,
                            // display: (context)=>(context.dataIndex === context.dataset.data.length-1 ? "Current Value: " : false),
                            display:'true',
                            borderRadius: 4,
                            borderWidth:2,
                            backgroundColor:'white',
                            color: function(context) {
                                var index = context.dataIndex;
                                var value = index === context.dataset.data.length-1 ? user_value_flag : biomarkerDetail['value_history'][index] ? biomarkerDetail['value_history'][index]['user_value_flag'] : '';
                                return (value === 0) ? colors.green :  
                                    colors.red
                            },
                            borderColor: function(context) {
                                var index = context.dataIndex;
                                var value = index === context.dataset.data.length-1 ? user_value_flag : biomarkerDetail['value_history'][index] ? biomarkerDetail['value_history'][index]['user_value_flag'] : '';
                                return (value === 0) ? colors.green :  
                                    colors.red
                            },
                            font: {
                                weight: 'bold',
                                size: 12
                            },
                        },
                        title:{
                            display:true,
                            text:biomarkerDetail.biomarker_name,
                            fontColor:'#707070'
                        },
                        annotation: {
                            events: ["click"],
                            annotations: {
                                redBox:{
                                    drawTime: "beforeDraw",
                                    type: "box",
                                    xMin: 0,
                                    xMax: "",
                                    yMin: ub_ideal,
                                    yMax: MAX_VALUE,
                                    backgroundColor: colors.redBg,
                                    borderWidth:0,
                                    
                                },
                                redBox1:{
                                    drawTime: "beforeDraw",
                                    type: "box",
                                    xMin: 0,
                                    xMax: "",
                                    yMin: 0,
                                    yMax: lb_ideal,
                                    backgroundColor: colors.redBg,
                                    borderWidth:0,
                                    
                                },
                                
                                greenBox:{
                                    drawTime: "beforeDraw",
                                    type: "box",
                                    xMin: 0,
                                    xMax: "",
                                    yMin: lb_ideal,
                                    yMax: ub_ideal,
                                    backgroundColor: colors.greenBg,
                                    borderWidth:0,
                                    
                                }
                            }
                        }
                    }
                }
                
        });
    }
    },[])
        
    
    if(biomarkerDetail.value_history.length>0 && biomarkerDetail.is_categorical!==1){
        return (
            <div className="chartContainer">
                <canvas
                    style={{width:'295px'}}
                    id="myChart"
                    ref={chartRef}
                />
            </div>
        )

    }else{
        return null
    }
       
    
 
}
