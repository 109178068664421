import React from 'react'
import { useSelector } from 'react-redux'

function CovidReport() {

    const covidPositiveStyle = {
        backgroundColor:'rgb(246 176 176)',
        borderRadius:'12px',
        padding:'1rem',
        color:'#670808',
        marginTop:'1rem',
        fontSize:'20px',
        display:'flex',
        alignItems:'center'  
    }
    const covidNegativeStyle = {
        backgroundColor:'rgb(172 236 172)',
        borderRadius:'12px',
        padding:'1rem',
        color:'#104b10',
        marginTop:'1rem',
        fontSize:'20px',
        display:'flex',
        alignItems:'center' 
    } 
    const panel_details = useSelector(state=>state.analyzer.report_details.panel_details.covid.details.normal_range.sars_cov_2_rtpcr)
    return (
        <div className='covid_report_container' style={{padding:'1rem',background:'white'}}>
            <div className='panelHead' style={{justifyContent:'unset'}}>
                <img src={`${process.env.PUBLIC_URL}/report_assets/img/panels/covid_icon.svg`} className="panelIcon" alt="neodocs covid smart report" style={{height:'2rem',width:'2rem'}}></img>
                <h2 style={{color:'#66A4FB',fontWeight:'800'}}>Covid Report</h2>
                
                
            </div>
            <div className='status' style={panel_details.value?covidPositiveStyle:covidNegativeStyle}>
                <div className={panel_details.value}>{panel_details.value?<i class='bx bx-error-circle' ></i>:<i className='bx bx-check-circle' style={{color:'#104b10'}}></i>}{panel_details.value? <p style={{fontSize:'20px!important'}}>Please consult your doctor as you <strong>are infected with the Corona Virus.</strong> <br></br> You must self-isolate straight away to avoid spreading the infection to other people</p>:<p style={{fontSize:'20px!important'}}>Your report indicates you are <strong>not infected with the Corona Virus. </strong><br></br> <span style={{color:'black',fontSize:'smaller'}}>Please keep taking necessary precautions.</span> </p>}</div>
            </div>
            <img src={`${process.env.PUBLIC_URL}/report_assets/img/covid_illus.svg`} alt="neodocs covid smart report" style={{position:'relative',bottom:'-1.9rem',width:'100%',pointerEvents:'none'}}></img>
            <div></div>
           
        </div>
    )
}

export default CovidReport
