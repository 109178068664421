
import React,{useEffect, useState} from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {ImageUrl} from '../HelperFunctions'

import Option from './inputs/Option'
    
function Question(props){
    let Qarr=[];

    const [values,setvalues] = useState(props.question['choices'].reduce((a, key,i) => Object.assign(a, { [key['choice_name']]: key['inputtype']==='checkbox'?false:null }), {}));
    const putValue=(val)=>{
        setvalues(prev=>({...prev,...val}))
        
    }
    useEffect(()=>{
        
        
        if(values[props.question.choices[0].choice_name]){
        console.log(values)
        props.gotoNext(values)
        }
    },[values])
    Qarr=props.question['choices'].map(choice=>(<Option choice={choice} question={props.question} putValue={(val)=>putValue(val)}></Option>))
    
   
   return(
        <div className="questionContainer" style={{display:props.displayId===props.id?'block':'none'}}>
            <img src={ImageUrl(props.question.question_image)} alt=''></img>
           <h2 className='question-title big-header-h1'>{props.question.question_title}</h2>
           {Qarr}
           <div className="questionButtons">
                   {props.question.questionId>0 && <div className="prevBtn" onClick={props.id===0?undefined:props.gotoPrev}>Prev</div>}
                   
                 
            </div>
       </div>
   
   )
}

    
        
    

function QuestionSlider(props) {
   
    const {QuestionData} = props
    const [questionNo,setquestionNo] = useState(0)
    const [value,setvalue] = useState([])
    
   
   const nextPage=(data)=>{
       let valuesArr = value;
            valuesArr[questionNo] = data;
            setvalue(valuesArr)
       if(questionNo!==QuestionData.questions.length-1){
            
            setquestionNo(prev=>prev+1)
        }

        if(questionNo===QuestionData.questions.length-1 && value.length===QuestionData.questions.length){
            // last question -- submit
            console.log(value)
            
            props.onSubmitQuestionnaire(value)

        }
       
   }
   
   const prevPage=()=>{
    setquestionNo(prev=>prev-1)
}
    


    
    

    
    return (
        
             
             <div className="questionnaire-main-slider">
                <div className="questionaire-header">
                    <p className='questionslide_title'>{QuestionData.title}</p>
                    
                    <div style={{display:'flex',alignItems:'center'}}>
                        <div style={{fontColor:'white',fontSize:'small'}}>{questionNo+1} of {QuestionData.questions.length}</div>
                        <div style={{height:'3px',backgroundColor:'#96969659',flexGrow:'1',marginLeft:'1rem'}}>
                            <div style={{width:((questionNo+1)/QuestionData.questions.length)*100+'%',backgroundColor:'#61f2da',height:'100%',transition:'all 0.3s ease-in-out'}}></div>
                        </div>
                    </div>
                    
                </div>
            

        
                <div>
                    {QuestionData.questions.map((question,i,arr)=><Question fullLength={arr.length} question={question} displayId={questionNo} id={i} gotoNext={(data)=>nextPage(data)} gotoPrev={prevPage} ></Question>)}

                </div>
                
            </div>

   
            
           
            
          
        
    )
}

export default QuestionSlider