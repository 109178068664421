import React, { useEffect, useState } from 'react';
import '../Blogs/Blogs.css';
import '../../../../node_modules/font-awesome/css/font-awesome.min.css';

import { NavLink } from 'react-router-dom';
import { ImageUrl } from '../../../components/HelperFunctions';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';

import Loader from '../../../components/Loader';
import { Helmet } from 'react-helmet';
import BlogCard from '../BlogCards/BlogCard';
import { db } from '../../../firebase-config';
import {collection,    getDocs, orderBy, limit, query, startAt, doc, getDoc} from 'firebase/firestore'

import moment from 'moment';


function Blogs(props) {
  const blogMetaCollection = collection(db, "blog_meta")
  // get blogs
  const [latest_blog,setlatest_blog]=useState({});
  const [popular_blogs,setpopular_blogs]=useState([]);
//   const [imageLoaded , setImageLoaded] = useState(false);

  useEffect(()=>{
    const getBlogs =() =>{
      
      const data = query(blogMetaCollection, orderBy('updated_on'), startAt(new Date(moment.unix(1661500868))),  limit(15));
      getDocs(data)
      .then(blogs=>{
        blogs.docs.reverse().forEach((blog,i)=>{
          
          if(i===0){
            console.log(`blog_meta/${blog.id}/blog_desc/desc_${blog.id}`)
            let sections = doc(db, `blog_meta/${blog.id}/blog_desc/desc_${blog.id}`)
            getDoc(sections).then(sectionres=>{
              console.log(sectionres.data())
              setlatest_blog({...blog.data(),intro:sectionres.data().section[0].section_content.section_desc})})
              
          }else{
            setpopular_blogs(prev=>[...prev,blog.data()])
          }
        })
        
      }).catch(err=>console.log(err))  
    }
    getBlogs()
    return ()=>{
      setlatest_blog({});
      setpopular_blogs([])
    }
  },[])

  
    return (
        
        <>
        <Header/>
          <Helmet>
            
            <title>Neodocs | Blogs</title>
            <meta name="description" content="Neodocs blogs for health, wellness, UTI(Urinary Tract Infection), women's health, focus, energy" />
            
          </Helmet>
          
           {Object.keys(latest_blog).length>0 ? <div id="blog-domain-container">
               {/* <h1 className='big-header-h1 blog-header'>Blogs..</h1> */}
             <section className='general-container all-categories' style={{backgroundColor:'#cfe9f9'}}>
              
                <div className="category-bar">
                    
                    <div className="categories-container">
                    <NavLink to="blogs/general" className="category" >
                        <h3>General</h3>
                    </NavLink>
                    <NavLink to="blogs/health" className="category" >
                  
                        <h3>Health</h3>
                        
                    </NavLink>
                    <NavLink to="blogs/wellness" className="category" >
                  
                        <h3>Wellness</h3>
                        
                    </NavLink>
                    <NavLink to="blogs/uti" className="category" >
                  
                        <h3>UTI</h3>
                        
                    </NavLink>
                    
                   
                    <NavLink to="blogs/diet" className="category" >
                    
                        <h3>Diet</h3>
                        
                    </NavLink>
                    
                    
                    
                    
                    {/* <NavLink to="blogs/self_care" className="category" >
                     
                        <h3>Self Care</h3>
                        
                    </NavLink> */}
                    <NavLink to="blogs/womens_health" className="category" >
                  
                        <h3>Women's health</h3>
                        
                    </NavLink>
                    
                    
                    
                  </div>
                    

                </div>
             </section>
            
            
              
             
            <section className='latest-blog-container' style={{paddingTop:'1rem',paddingBottom:'1rem'}}>
            <div style={{paddingTop:'1rem',paddingBottom:'0rem'}}><h5 className='big-header-h2' style={{fontSize:'1rem',textTransform:'uppercase',letterSpacing:'1px'}}><span>Latest</span> in this month</h5></div>
              <BlogCard blog={latest_blog} key="latest-blog-card" type='latest-blog-card'></BlogCard>
                    
            </section>  
                

                
            
            <section className='general-container' style={{paddingTop:'2rem'}}>
            
              <section className="popular-blogs">
                <div className="blogs-container">
                  {popular_blogs.map((blog,i)=>(
                    <BlogCard blog={blog} key={"blog-card-"+i} type={'vertical-blog-card'}></BlogCard>
                  ))}

                    
                   
                     
                    
                </div>
            </section>
            </section>
            {/* <div className="recipe-ad-conatiner general-container">
                <div className="blog_data">
                   
                     
                        <h2 className="big-header-h1 blog-header" style={{order:'-1'}}>Find the perfect balance between taste and nutrition.</h2>
                        <NavLink to='/blogs/recipes' style={{color:'white'}}>Checkout our recipes ⟶</NavLink>

                    </div>
                    <div className="blog_image recipe-hero-img">
                        <img src={ImageUrl('blogs/categories/recipes.jpg')} alt="Neodocs blog recipes" ></img>
                    </div>
            </div> */}
            
            
            </div> : <Loader></Loader>}
        <Footer/>
        </>
    )
}

export default Blogs
