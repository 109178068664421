import React, { useState } from 'react'

function AddBlogForm() {
    const [sections,set_sections] = useState([]);
  return (
    <div id="addBlogForm">
        <div className='sectionsWrapper'>
            <div className='oneBlogSection'>
                <textarea rows="10"></textarea>
            </div>
        </div>
    </div>
  )
}


export default AddBlogForm

