import React from 'react';
import { Route , Switch} from 'react-router-dom';
import FullReport from '../domains/pdfAnalyzer/SmartReport/components/FullReport';
import AllRecommendations from '../domains/pdfAnalyzer/SmartReport/components/AllRecommendations';
import Practise from '../domains/pdfAnalyzer/SmartReport/components/Practise';
import SmartReportContainer from '../domains/pdfAnalyzer/SmartReport/components/SmartReportContainer';





export default [ 
                <Route  path='/wb/smart_report/:rid' exact component={FullReport}></Route>,
                <Route  path='/wb/smart_report/:rid/recommendations' exact component={AllRecommendations}></Route>,
                <Route  path='/wb/smart_report/:rid/recommendations/:type/:name' exact component={Practise}></Route>,
                <Route  path='/smart-report/:id' exact component={FullReport}></Route>,
                <Route  path='/smart-report/:id/recommendations' exact component={AllRecommendations}></Route>,
                <Route  path='/smart-report/:id/recommendations/:type/:name' exact component={Practise}></Route>]





