import React, { useState,useEffect } from 'react'

const choiceCategory={
    single:['radio','text'],
    multiple:['checkbox']
}

// returns a radio or checkbox 
function Option(props) {
    const [active, setActive] = useState(false)
    const [value,setvalue]=useState(props.choice.inputtype==='checkbox' ? false : props.choice.inputtype==='radio' ? '' : '')

    const checkValue=(e)=>{
        if(props.choice.inputtype==='checkbox'){
            setvalue(e.target.checked)
        }else{
            console.log(e.target)
            setActive(true)
            props.putValue({[props.choice.choice_name]:e.target.value})
        }
       
       
    }
    useEffect(() => {
        console.log(value)
        if(value && props.choice.inputtype==='checkbox'){
            console.log({[props.choice.choice_name]:value})
            props.putValue({[props.choice.choice_name]:value})
        }
    }, [value])

    return (
        <div className={`optionContainer ${active?'checked':'unchecked'}`  }>
            <label>
                <input type={props.choice.inputtype} onClick={(e)=>checkValue(e)} value={props.choice.val} placeholder={props.choice.inputtype==='text'?'Enter Value':''} name={props.choice.choice_name}></input>
                {props.choice.inputtype!=='text' && <p className='opt_name'>{props.choice.choice_title}</p>}
            </label>
        </div>
    )
}

export default Option