import React from 'react';
import Blogs from '../domains/blogs/Blogs/Blogs'

import { Route , Switch} from 'react-router-dom';
import Error404 from '../components/ErrorPages/Error404'
import SingleBlog from '../domains/blogs/SingleBlog/SingleBlog';
import Category from '../domains/blogs/Category/Category';
import RecipeBlog from '../domains/blogs/SingleBlog/RecipeBlog';
import AddBlogForm from '../domains/blogs/AddBlog/AddBlogForm';

export default [<Route path="/blogs" exact component={Blogs}></Route>,
            <Route path="/blogs/blog/:blog_title" exact  component={SingleBlog}></Route>,
            <Route path="/blogs/recipe-blog/:blog-id" exact component={RecipeBlog}></Route>,
            <Route path="/blogs/:blog_type" exact component={Category}></Route> ,
            <Route path='/addBlog' exact component={AddBlogForm}></Route>   
       ];




