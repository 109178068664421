import React, { useEffect, useState } from 'react';
import { useSpring, animated , config} from 'react-spring';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import { ImageUrl } from '../../../../components/HelperFunctions';
import Testimonials from '../Usables/Testimonials';
import { NavLink } from 'react-router-dom';
import './Faq.css'

function OneFaq({ques,ans,number}){

    const [open,setopen] = useState(false) 
    const active = useSpring({
        opacity: open? 1:0,
        display: open? 'block':'none',
        y:open? 0:10,
        config:config.wobbly

    })
    return (
        <div className="onefaq">
            <div className={`onefaq_question ${open && 'open'}`} onClick={()=>setopen(prev=>!prev)}><span className='number'></span> {ques}</div>
            <animated.div style={active} className="onefaq_answer" dangerouslySetInnerHTML={{__html:ans}}></animated.div>
        </div>
    )
}

function Faq() {
    return (
        <div >
            <Header></Header>

            <div className='faq-container'>
                <section className='faqHead'>
                    
                    <h1 className='big-header-h1'>FAQs</h1>
                </section>
                
                <section className='allFaqs'>
               
                    {[
                        {ques:'How do I order the product?',ans:'Simply click on the Get Started button at top of the screen, choose your preferred plan, and place your orde'},
                        {
                            ques:'When should I take the test?',ans:'The test can be taken at any time of the day; just ensure you follow the directions provided with the test'
                        },
                        {
                            ques:'How frequently should I do my test?',ans:'To track and witness the improvement of your wellbeing, we recommend you to test once a week. If you want, you can use it more often to track specific parameters. But this might vary from person to person, and also basis your previous result.'
                        },
                        {
                            ques:'The wellness card that was shipped to me was damaged, what is the process to replace it?',ans:"Oops, we're extremely sorry about this! Please get in touch with us at care@neodocs.in or +91 9833694081 and we would be happy to send you a replacement wellness card"
                        },
                        {
                            ques:'What time should the test be conducted? Should it be conducted after a period of fasting?',ans:'The test can be taken at any time of the day; just ensure you follow the directions provided with the test'
                        },
                        {
                            ques:'What is included in my personal Neodocs box?',ans:'Your Neodocs box consists of wellness card, cup for sample collection, a user manual and unlimited usage of Neodocs app'
                        },
                        {
                            ques:'How much does each kit cost?',ans:'Please click <a href="/pricing">here</a> to know more about our pricing.'
                        },
                        {
                            ques:'Are there any shipping charges?',ans:'No. We ship all over India, free of cost.'
                        }
                    ].map((el,i)=>(<OneFaq ques={el.ques} ans={el.ans} number={i+1}></OneFaq>))}
                </section>
            </div>
            <Testimonials></Testimonials>
            <Footer></Footer>
        </div>
    )
}

export default Faq
