import React from 'react';
import {useDispatch} from 'react-redux';
import { setAlert } from '../../domains/mainWebsite/WebsiteSlice';
import Lottie from 'react-lottie';
import  confettiAnimation from '../../lotties/confetti.json';
const defaultOptions = {
    loop:false,
    autoplay:true,
    animationData:confettiAnimation,

}

function FullPagePopUp(props) {
    const dispatch = useDispatch()
    const closePopUp = () =>{
        dispatch(setAlert(''))
    }

    const copytoClipboard = () =>{
        navigator.clipboard.writeText("98765421")
    }
    return (
        <div className='pop-up-container' style={{position:'fixed',top:0,bottom:0,left:0,right:0,background:'rgba(0,0,0,0.9)',zIndex:'999999'}}>
            <div className='pop-up'>
                <svg style={{position:'absolute',top:0,left:0,pointerEvents:'none',opacity:'0.7',height:'100%',width:'100%'}}>
                    <circle  cx="15" cy="35" r="68" stroke="#6ba6ec" fill="#6ba6ec" stroke-width="5"></circle>
                    <rect x="0" y="60%" width="2%" height="40%" stroke="#ece36b" fill="#ece36b" stroke-width="5"></rect>
                    <circle  cx="100%" cy="90%" r="150" stroke="lavender" fill="lavender" stroke-width="5"></circle>
                </svg>
                <div className='close cursor' style={{position:'relative',zIndex:'9999999'}} onClick={closePopUp}>X</div>
                <div style={{fontSize:'1.5rem',fontWeight:'900',textAlign:'center',paddingTop:'2rem',    color: '#181837'}}>Congratulations!!!!</div>
                <div style={{textAlign:'center',fontSize:'smaller'}}>You found the easter egg</div>
                <div>
                    <p style={{textAlign:'center'}}>You get <span style={{fontSize:'4rem',fontWeight:'900',color:'#e4ac04'}}>15%</span> off on your next purchase</p>
                </div>
                <div className='coupon-code-container' style={{backgroundColor:'#c3c3c340',paddingLeft:'1rem',borderRadius:'8px',display:'flex',justifyContent:'space-between',height:'3rem',alignItems:'center',marginTop:'1rem'}}>
                    <div >CW9876</div>
                    <div style={{background:'#ec6b81',color:'white',height:'100%',width:'5rem',display:'flex',justifyContent:'center',alignItems:'center',borderTopRightRadius:'8px',borderBottomRightRadius:'8px'}} onClick={copytoClipboard}>Copy</div>
                </div>
                <Lottie className="lottie-file" options={defaultOptions} style={{position:'absolute',bottom:'0',pointerEvents:'none'}}></Lottie>
            </div>
            
        </div>
    )
}

export default FullPagePopUp
