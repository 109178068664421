export const creds = {
  "apiKey": "AIzaSyBA5gJ97EAbfySjX9nGOjcX9n7Kq9XJ3xg",
  "authDomain": "neodocs-8d6cd.firebaseapp.com",
  "projectId": "neodocs-8d6cd",
  "storageBucket": "neodocs-8d6cd.appspot.com",
  "messagingSenderId": "473696881217",
  "appId": "1:473696881217:web:aa88f4a433bf476bdd14c0",
  "measurementId": "G-NRPDV8CW5S"
}

export const creds2 = {
  "type": "service_account",
  "project_id": "neodocs-8d6cd",
  "private_key_id": "fb06c25ff26bfc604f9777740b16419461b8699c",
  "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCQKyXr490Ccazf\nPrYXrCBgL9QrxjcJc8H5/2OQBOU7KTrQsKqFx/TnPpXIgKmt31bYf8XIS8hsd4j9\nObkKG56544NJGfUf/YPDPQgOtS/Xq/IuNT5v65VKUHOk2GDdmQ8tZhQttOwIWCYn\nEUtfbtLi5nstAmfxSJO/AbnJ05ZBZddqNPVGMFyQjLkAQ8VxEBuiij98vIh/+bvr\naeTyOsyxD0OP1VhIIaI67YDwfOhZIVqwJ0jdb8SK9U5xjn/FE1+I6BSu4aEX0EY7\nMjZ5iJXhjbjSWILEwbSTVdoKH8QNdw7/sgYPXDZKZQsOzD+8X3EY/zhptLxskLc/\nDxp+ko/XAgMBAAECggEAC7vXTEmxdT93SHw8KdUSlYGPDTkGxbgsM4K7wb8EQTpr\n1Qxr9JN/tUp8umLubH5e/SP5HwI7/8gC2ElYbCAvXQXNa6e3MbFahgtzmm7Yw/70\nqRogSM+tyOyfXO1XhPGuMI28WrP8+fjisg9QSSYl9922bMVjXFoB3GvxiSG1ewEF\nrMOv4yIyTGjG0PFPcW1q5TGEHbkAZJwxRP2nuwDR/ErmJCdtLWYyMt6W6MdwgDEg\nyDs6nnOuLcH0XHnj25RvGlvaVVQfTlnmOm2RDvh8WXmS/54zi4BXpbwF5qPHRcay\nkewBpvL80oPBlBg3LrIpVhg3L6AmQUDim7MFAGAusQKBgQDFWy++yHKLx1i+ySCR\nwt02Pu9moVND5GBTcZ43T8rmF620ryX8iTfhZsSbKCUTO+xUbz7VS6Lyn8cOA7QG\nwm2VpEUS/snduLlpU6PcDv/8dQUMPuTu6HUJiVWOrqC6qmsv3wEnj+bhn7yVQ0EV\nC+tP3jp5qllhb7pQRr74i3BcVQKBgQC7Af8iY+qrUlF7nuT7rx4NGUpYp1UCTHof\nLhzZeC0LsW5+5KQo7bB3uxGt2hWKEbTVbShvQ/B/TMtIR4rJYLOslCcmG0ogXHCV\neUaULNXOaUYhuFlpvG/XMJqpGxqKm65ERPJad/DIQpRMVd9JdSofGwSclViT11jz\n2jDOkLBnewKBgAM5pUMZ5X1RSar8gHLBW3oadzEXK9QtNEHI1owS2r793hHY1ucU\nmvwtAMOEWW7CCO+sTbuQm94ZLFiXSObfYIkUgSh5T3U3IpiNDF15RUer5eQ4Vuds\nlxUL0IEfa3MBe/TxUQSDfoNrZ9qMmdCtPDA28n+wf/tupv3O2ioXpUNtAoGAW6gB\n3WAtb2S8TSO2xWiyCOeBWexBrQvAuzGFsEzHfziqmIUFtI2y9Sv54UTHU9GT2RtU\nOxyIbkw8QiPWcRb1WTuP6Ixti2jjQU+ceRl0fWN5nSNWp41VH5pR41pGavMcuZc1\nVYP9vplrR/K6qq1DRNWgMAK+1E8y6OnjDAs8eqsCgYBm5wlemL9N892eyIKb+oXT\nBgHZHVP0ayrWRbi1oMLEMgVoEo6hia4iI93kwDC6M+OH4wJf43+2B1V9AOj/4FHi\nCvYNq0+rCzW7s7oRZc92O4PQETYLEvflTFD+QsnmnC4ABGTLACdQ8N+O1fNGQfGO\npSJ6fxuaFx36K8ZYM8/veQ==\n-----END PRIVATE KEY-----\n",
  "client_email": "firebase-adminsdk-fbtzv@neodocs-8d6cd.iam.gserviceaccount.com",
  "client_id": "107016908868383986205",
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://oauth2.googleapis.com/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-fbtzv%40neodocs-8d6cd.iam.gserviceaccount.com"
}
