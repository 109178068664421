import React, { Fragment, useEffect, useRef } from 'react'
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import './UpcomingProduct.css';
import {UpcomingProductContent} from './UpcomingProductContent';
import lottie from 'lottie-web';
import { ImageUrl } from '../../../../components/HelperFunctions';
// ANIMATIONS
import { gsap } from "gsap";            
import {ScrollTrigger} from "gsap/ScrollTrigger";
import bloodAnimation from './bloodAnimationLottie.json'
import { useLocation, useParams } from 'react-router';
gsap.registerPlugin(ScrollTrigger)

function LottieScrollTrigger(vars) {
	let playhead = {frame: 0},
	target = gsap.utils.toArray(vars.target)[0],
	st = {trigger: target, pin: true, start: "top-=10% top", end: "end+=500", scrub: 2},
	animation = lottie.loadAnimation({
			container: target,
			renderer: vars.renderer || "svg",
			loop: false,
			autoplay: false,
			animationData: vars.animationData
		});
	for (let p in vars) { // let users override the ScrollTrigger defaults
		st[p] = vars[p];
	}
	animation.addEventListener("DOMLoaded", function() {
		gsap.to(playhead, {
            duration: vars.duration || 0.5,
            delay: vars.delay || 0,
			frame: animation.totalFrames - 1,
			ease: vars.ease || "none",
			onUpdate: () => animation.goToAndStop(playhead.frame, true),
			scrollTrigger: st
		});
    // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
    ScrollTrigger.sort();
    ScrollTrigger.refresh(); 
	});
  return animation;
}


function UpcomingProductsPage() {
    const productName = useParams().pname;
    console.log(productName)
    const CONTENT = UpcomingProductContent[productName]
    const upHeroRef = useRef(null)
    const hiwRef = useRef(null)
    useEffect(()=>{
        gsap.fromTo(upHeroRef.current,{height:0},{height:'100vh',duration:0.5})
        let heroAnimationTimeline = gsap.timeline()
        let allHeadersArr = [...upHeroRef.current.querySelectorAll('.big-header-h1')]
        allHeadersArr.forEach((el,i)=>{
            heroAnimationTimeline.fromTo(el,{yPercent:10,opacity:0,display:'none'},{opacity:1,display:'block'})
            heroAnimationTimeline.to(el,{opacity:0,display:'none'},'+=1.5')
            heroAnimationTimeline.to(el,{opacity:0,display:'none'},'+=1.5')
        })
        heroAnimationTimeline.fromTo(upHeroRef.current.querySelector('.big-header-h2'),{opacity:0,display:'none'},{opacity:1,display:'block'})
        heroAnimationTimeline.to(upHeroRef.current,{backgroundColor:'#000324'})
        heroAnimationTimeline.to(upHeroRef.current,{height:'30vh',duration:0.6},'<')
        
        
       
    },[upHeroRef])
  return (
    <Fragment>
        <Header></Header>
        <section className='up-hero' ref={upHeroRef}>
            {CONTENT.did_you_know.map((el,i)=>(<h1 className='big-header-h1'>{el}</h1>))}
            <h1 className='big-header-h2' >Introducing...</h1>
        </section>
        <section className='up-introdesc'>
            <div>
            <h1 className='big-header-h1'>{CONTENT.display_name}</h1>
                {CONTENT.short_desc.map((el,i)=>(<p className='up-short-desc sub-header' dangerouslySetInnerHTML={{__html:el}}></p>))}
                <div className='action-btn__primary' style={{marginTop:'2rem', minWidth:'15rem'}}>Order Now</div>
            </div>
                
                <img src={ImageUrl(`poct/testkit-imgs/${CONTENT.testkit_image_link}`)} alt="vitamin D test kit neodocs"></img>
        </section>
        <section className='up-steps' ref={hiwRef}>
            
            <h1 className='big-header-h1'>How to take the test</h1>
            <div className='up-steps-container'>
            <div className='up-steps-desc'>
                    {CONTENT.hiw_steps.map((el,i)=><div className='one-step'>
                        <p className='step-no'>{i+1}</p>
                        <div className='up-steps-illus'>
                            <img style={{height:'15rem'}} alt="neodocs home testing kit steps" src={ImageUrl(`poct/up-steps/${el.image}`)}></img>
                        </div>
                        <p className='step-desc'>{el.content}</p>
                        
                        </div>)}
                </div>
                
                
            </div>
            
        </section>
        <section className='up-testkit'>
            
            <h1 className='big-header-h1'>What's in the kit?</h1>
            <img src={ImageUrl(`poct/${CONTENT.kit_content_image_link}`)} alt="neodocs vitamin test"></img>
            
            
        </section>
        <section className='up-meta-data'>
                <div>
                    <div className='up-meta-data__title'>Parameter Detected</div>
                    <div className='up-meta-data__desc'>{CONTENT.parameters_detected}</div>
                </div>
                <div>

                    <div className='up-meta-data__title'>Useful For</div>
                    <div className='up-meta-data__desc'>{CONTENT.useful_for}</div>
                </div>
                
        </section>
        <section style={{backgroundImage:`url(${ImageUrl('poct/diagnostics-future.jpg')})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'40vh',color:'#d5d5d5',backgroundPosition:'cennter'}}>
                        <div >
                            <h1 className="big-header-h1" >This is the future of Diagnostics<span style={{color:'var(--primary-color-green)'}}>.</span> </h1>    
                        </div>
                        
                        {/* <img style={{marginBottom:0}} id="hero_img" src="/docassets/img/poct/our-tech/hero-1.jpg" alt="" className="translate" /> */}
                        
                </section>
        
        <Footer></Footer>
    </Fragment>
  )
}

export default UpcomingProductsPage