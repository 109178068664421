import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import PatientInfo from './PatientInfo'
import PanelList from './PanelList'
import {  animateScroll as scroll,  scroller } from 'react-scroll';
import '../css/style.css';


function FullReport(){
    const filter = useLocation().filter

    useEffect(() => {
        if(filter===undefined ||filter.panelName==="all" ){scroll.scrollToTop({duration:0,delay:0})}
        else{scroller.scrollTo(filter.icon,{delay:0,duration:0})}
    }, [filter])  
    
    
    return(<div className='fullReportContainer' style={{background:'#e6f0fa'}}>
                <PatientInfo ></PatientInfo>
                <div className="panelContainer">
                    <PanelList></PanelList>
                </div>
            </div>)
    
}
export default FullReport;