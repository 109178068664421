import React from 'react'
import { NavLink } from 'react-router-dom';
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import { ImageUrl } from '../../../../components/HelperFunctions';
import Patterns from '../../../../components/Patterns'
import './AboutUs.css'

function AboutUs() {
    return (
        <div>
            <Header></Header>
            <div>
                <section className="hero navColor" >
                    <div className="texts">
                        
                        <h1 className='big-header-h1'><i class='bx bxs-quote-alt-left' style={{color:'var(--primary-color-green)'}}></i>The catalyst in your journey towards self improvement.<i class='bx bxs-quote-alt-right' style={{color:'var(--primary-color-green)'}} ></i></h1>
                    </div>
                    {/* <div className="images">
                        <img src={ImageUrl("/poct/Wall_Sign_Logo_Mockup.png")} alt="neodocs" style={{height: '100%',width: '100%',objectFit: 'cover'}}/>
                    </div> */}
                </section>
                <section className='ourStory' >
                        <div>
                            <h1 className='big-header-h1'>Our Story...</h1></div>
                        <p className='sub-header'>We started Neodocs during the pandemic, since we’re extremely optimistic about the future of health. Most of our healthcare ecosystem is focused towards diseases and sickness, but what about proactive measures to make us feel better? <br/> <br/>
                        Neodocs has created a revolutionary approach to help people understand and optimize their health through at-home assessment, personalized actions, and ongoing progress monitoring. And through this, we’re unlocking a world where everyone can feel, perform, and live better.<br/> <br/>After all, if we can identify what’s holding us back, there’s no limit to what we can achieve.</p>
                </section>
                <section id="our-focus">
                <div id="our-focus__text-wrap">
                        <div id="text-arrow"><i className='bx bx-right-arrow-alt' ></i><i className='bx bx-up-arrow-alt' ></i></div>
                        <h1 className='big-header-h1'>Our focus</h1>
                    </div>
                    <img id="our-focus__web" src={ImageUrl('poct/about/stacked-cards-web.svg')} alt="neodocs focus"></img>
                    <img id="our-focus__mobile" src={ImageUrl('poct/about/stacked-cards-mobile.svg')} alt="neodocs focus"></img>
                    <img id="our-focus__people" src={ImageUrl('poct/about/people-havinf-fun-while-wearing-clothes-color-year-2022__1_-removebg-preview.png')} alt="neodocs focus"></img>
                   
                </section>
                <section className="investors">
                    <h1 style={{textAlign:'center'}}>Backed by </h1>
                    <div className="investors_container">
                        <div className="investor">
                            <img src={ImageUrl("/investors/ycombinator_logo.png")} alt=""/>
                        </div>
                        <div className="investor">
                            <img src={ImageUrl("/investors/somacapital-logo.png")} alt=""/>
                        </div>
                        <div className="investor">
                            <img src={ImageUrl("/investors/titancapital-logo.png")} alt=""/>
                        </div>
                        <div className="investor">
                            <img src={ImageUrl("/investors/gembacapital-logo.png")} alt=""/>
                        </div>
                        <div className="investor">
                            <img src={ImageUrl("/investors/vsq-logo.png")} alt=""/>
                        </div>
                        <div className="investor">
                            <img src={ImageUrl("/investors/9unicorns-logo.png")} alt=""/>
                        </div>
                    </div>
                </section>
                
 
                <section className="team">
                    <Patterns type="HEALTH"></Patterns>
                    <h2 style={{fontWeight: 500}} className="big-header-h1">Meet  <div  style={{color: 'var(--primary-color-green)',fontWeight: 700,display: 'inline'}}>Our Team</div></h2>
                    <p className="sub-header">A team of IITians and doctors, democratizing healthcare for India</p>
                    <div className='team-members-container'>
                        <div className='one-team-member'>
                            <div className='one-team-member__image'><img src={ImageUrl('team/pratik.jpg')} alt='neodocs team'></img></div>
                            <div className='one-team-member__meta'>
                                <div className='one-team-member__meta__name'>Pratik Lodha</div>
                                <div className='one-team-member__meta__role'>Co-foundr & CTO</div>
                            </div>   
                        </div>
                        <div className='one-team-member'>
                            <div className='one-team-member__image'><img src={ImageUrl('team/anurag_meena.jpg')} alt='neodocs team'></img></div>
                            <div className='one-team-member__meta'>
                                <div className='one-team-member__meta__name'>Anurag Meena</div>
                                <div className='one-team-member__meta__role'>Co-founder & Director</div>
                            </div>   
                        </div>
                        <div className='one-team-member'>
                            <div className='one-team-member__image'><img src={ImageUrl('team/nikunj.jpg')} alt='neodocs team'></img></div>
                            <div className='one-team-member__meta'>
                                <div className='one-team-member__meta__name'>Nikunj Malpani</div>
                                <div className='one-team-member__meta__role'>Co-founder & CEO</div>
                            </div>   
                        </div>
                    </div>
                   
                   
                </section>
                
                
                <section className="contactUs" >
                    <div>
                        <h2 className='big-header-h1'>Keep up with latest from us about health and wellness.</h2>
                        <p className='sub-header'></p>
                    </div>
                    
                    <div className='socials'>
                        
                      
                            <a  href="https://twitter.com/neodocs" className="twitter"><i className="bx bxl-twitter"></i></a>
                            <a  href="https://www.facebook.com/neodocs" className="facebook"><i className="bx bxl-facebook"></i></a>
                            <a href="https://www.instagram.com/neodocs" className="instagram"><i
                                    className="bx bxl-instagram"></i></a>
                            <a href="https://www.youtube.com/channel/UCtWYtzpyCbx111FHVvjMRFw" className="youtube"><i className="bx bxl-youtube"></i></a>
                            <a href="https://www.linkedin.com/company/neodocs" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                    
                        
                    </div>

                   
                </section>
     <section className="ourBlogs">
     <div className="allBlogsWrapper">
            <div className="allBlogs">
                <NavLink className="blogs" to='/blogs/blog/7-things-neodocs-wellness-cards-tell-you-about-your-body--why-you-need-to-know-about-them__IvrXyo-fCsAf' >
                    <div className="blog_img">
                        <img src="https://neodocs-blogs.s3.ap-south-1.amazonaws.com/blogs_renamed/IvrXyo-fCsAf/thumbnail.png" alt="7 Things Neodocs Wellness Cards Tell You About Your Body, and Why You Need To Know About Them"/>
                    </div>
                    <div className="blog_title">7 Things Neodocs Wellness Cards Tell You About Your Body, and Why You Need To Know About Them <i class='bx bx-right-arrow-alt'></i></div>
                    
                </NavLink>
                
                
                <NavLink className="blogs " to='/blogs/blog/everything-you-need-to-know-about-urinary-tract-infections__3kanAeJ-x2j8'>
                    <div className="blog_img"><img src="https://neodocs-blogs.s3.ap-south-1.amazonaws.com/blogs_renamed/3kanAeJ-x2j8/thumbnail.jpg" alt="Everything You Need To Know About Urinary Tract Infections "/></div>
                    <div className="blog_title">Everything You Need To Know About Urinary Tract Infections <i class='bx bx-right-arrow-alt'></i></div>

                </NavLink>
                <NavLink className="blogs " to="/blogs/blog/the-misconception-around-self-care__V2ZD47vtuZSo" >
                    <div className="blog_img">
                        <img src="https://neodocs-blogs.s3.ap-south-1.amazonaws.com/blogs_renamed/V2ZD47vtuZSo/thumbnail.jpg" alt="The misconception around self care "/></div>
                    <div className="blog_title">The misconception around self care <i class='bx bx-right-arrow-alt'></i></div>
                    
                </NavLink>
                
                
               
            </div>

        </div>
        <h2 className='big-header-h1' style={{position:'relative'}}>
            <Patterns type="LOTUS"></Patterns>
            Read about health, wellness, diet and more...</h2>
     
        
        <NavLink className="action-btn__primary" to='/blogs' style={{width:'fit-content'}}>More blogs</NavLink>
        
    </section> 
            </div>
            <Footer></Footer>
        </div>
    )
}

export default AboutUs
