import React, { useContext} from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {panelContext, patientContext, recommendationContext} from './Context';
import PanelInfo from './PanelInfo';
import { useRouteMatch } from 'react-router';


function PanelList(){
    const report_details = useSelector(state=>state.analyzer.report_details);
    const panelDetails = report_details.panel_details;
    const {url} = useRouteMatch();
    
    const sortPanelDetails = (panelKeys) => {
        /** ip: panelKeys 
        op:array of panelKeys sorted acc to insight */
        let i=panelKeys.indexOf('Other biomarkers')
        if(i!==-1){
            panelKeys.splice(i,1)
        }
        // add all risk biomarkers keys
        let allRiskKeys=Object.keys(report_details.insights).filter(key=>report_details.insights[key].risk==='risk')
        // all borderline biomarker keys
        let allBorderlineKeys=Object.keys(report_details.insights).filter(key=>report_details.insights[key].risk==='borderline')
        // risk -> border -> normal -> other
        let sortedArr=[...allRiskKeys,...allBorderlineKeys,...panelKeys]
        // test for duplicates 
        let set = new Set(sortedArr)
        if(i!==-1){
            set.add('Other biomarkers')
        } 
        return Array.from(set)
    }

    const panelArr = sortPanelDetails(Object.keys(panelDetails)).map((key,i) => (<PanelInfo  key={i} panelNameKey={key} ></PanelInfo>))
  
    
    return(<div className='tab_container'>
    
        {panelArr.length > 0 ? panelArr :  "No panels fetched in this report"}
        <div style={{height:'10rem'}}></div>
        <NavLink className="getAllRecms" to={{pathname:`${url}/recommendations`,activeState:'diet',filter:{panelName:'all',icon:undefined}}}>Get recommendations</NavLink>
       
    </div>)
}
export default PanelList;