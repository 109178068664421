import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux';
import store from './store'
import {BrowserRouter as Router} from 'react-router-dom';
import axios from 'axios';
import 'dotenv/config'




axios.defaults.baseURL = 'https://smartreports.neodocs.in/';
// axios.defaults.baseURL = 'http://localhost:5000/';
ReactDOM.render(
 

  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

