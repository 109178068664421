import React, { Fragment, useState, useEffect } from 'react';
import '../SingleBlog/SingleBlog.css';

import Loader from '../../../components/Loader';
import { NavLink } from 'react-router-dom';
import { getString } from '../../../components/HelperFunctions';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import {  useHistory, useParams } from 'react-router';
import moment from 'moment';
// SEO
import {Helmet} from "react-helmet";
import CommentBlog from '../CommentBlog/CommentBlog';
import { doc, getDoc, getDocs, collection} from 'firebase/firestore'
import { db } from '../../../firebase-config';
import { BLOG_AUTHORS } from '../../../components/Constants/BlogAuthors';

// BlogsSidebar
const SingleBlog = () => {
  const [blog_details, setblog] = useState({});
  const [blog_section, setblog_sections] = useState({});
  const [blog_comments, setblog_comments] = useState([]);
  const history = useHistory();
  const { blog_title } = useParams();
  const blogId= blog_title.split('__')[1]

  

  useEffect(() => {
    let blog = doc(db, `blog_meta/${blogId}`)
    getDoc(blog)
    .then(res=>{
      if(res.data()){
        setblog(res.data())
        let sections = doc(db, `blog_meta/${blogId}/blog_desc/desc_${blogId}`)
        getDoc(sections).then(sectionres=>{setblog_sections(sectionres.data())}).catch(err=>"section error"+err)
      }  else{
        history.push('/blogs')
      }
    }).catch(err=>"error"+err)
    return ()=>{
     
      history.push('/blogs')
    }
  }, []);

  

  useEffect(()=>{
    getDocs(collection(db, `blog_meta/${blogId}/blog_comments`))
    .then(res=>{
   res.forEach(comment=>setblog_comments(prev=>[...prev,comment.data()]));
      
    })
    return ()=>{setblog_comments([])}

  },[])
  return (
    <Fragment>
      <Header></Header>
      
      {(Object.keys(blog_details).length > 0 && Object.keys(blog_section).length > 0) ? <section id="singleBlogContainer" >
        <Helmet>
          
          <title>{blog_details.title}</title>
          <meta name="description" content={blog_details.title} data-react-helmet="true" />
          <meta name="description" content={blog_details.tags.join(' ')} data-react-helmet="true" />
          <meta name="description" content={blog_section.section[0].section_content.section_desc} data-react-helmet="true" />
          <meta content={`https://neodocs-blogs.s3.ap-south-1.amazonaws.com/blogs_compressed/${blog_details.id}/${blog_details.thumbnail}`} property="og:image" data-react-helmet="true"/>
          <meta content={`https://neodocs-blogs.s3.ap-south-1.amazonaws.com/blogs_compressed/${blog_details.id}/${blog_details.thumbnail}`} property="twitter:image" data-react-helmet="true"/> 
        
          <link rel="canonical" href={`https://neodocs.in/blogs/blog/${getString(blog_details.title).replaceAll(' ','-').replaceAll(/[^-a-zA-Z0-9]/g,'').toLowerCase()}__${blog_details.id}`}></link>
        </Helmet>
        <div className="blogCover">
            <div className='blogImageContainer'>
              <img src={`https://neodocs-blogs.s3.ap-south-1.amazonaws.com/blogs_compressed/${blog_details.id}/${blog_details.thumbnail}`} alt="ne"></img>
            </div>
            
              <div className="blog_desc">
                {blog_details.categories.map(category=><NavLink className="blog-type" to={`/blogs/${category}`}><p>{category.replaceAll('_',' ')}</p></NavLink>)}
                <h1 className="big-header-h1 blog_title">{blog_details.title}</h1>
            
                <div className="blog_date">
                  {moment.unix(blog_details.updated_on.seconds).format("LL") } <span className="author_name">by {BLOG_AUTHORS[blog_details.author].display_name}</span>
                </div>
                <div className='blog_share'>
                  <p>Share via</p>
                  <div className='share-via__apps-container'>
                    <a href={`whatsapp://send?text=Read this insightful blog on ${blog_details.title}%0ahttps://neodocs.in/blogs/blog/${getString(blog_details.title).replaceAll(' ','-').replaceAll(/[^-a-zA-Z0-9]/g,'').toLowerCase()}__${blog_details.id}`} data-action="share/whatsapp/share" className='share-via__one-app'><i className='bx bxl-whatsapp'></i></a>

                    <a href={`http://twitter.com/share?text=Came across this insightful blog. A must read!&url=https://neodocs.in/blogs/blog/${getString(blog_details.title).replaceAll(' ','-').replaceAll(/[^-a-zA-Z0-9]/g,'').toLowerCase()}__${blog_details.id}`}><i class='bx bxl-twitter' ></i></a>

                    <a href={`https://www.facebook.com/sharer/sharer.php?u=https://neodocs.in/blogs/blog/${getString(blog_details.title).replaceAll(' ','-').replaceAll(/[^-a-zA-Z0-9]/g,'').toLowerCase()}__${blog_details.id}`}><i class='bx bxl-facebook' ></i></a>
                  </div>
                </div>
              </div>
            
          </div>
        
        <div className="blog-center">
          
          
          <div className="section_hrefs">
            {blog_section.section_hrefs && blog_section.section_hrefs.map(href => (
                <a href={`/blogs/blog/${blog_title}#${href}`}  className="hrefs"> {href.replace(',', ' ').replaceAll('_',' ')} </a>
              ))}
          </div>
          {blog_details.subtitle && <h1 className="blog_subtitle">{blog_details.subtitle}</h1>}

          {
            blog_section.section.map(sec =>
              <div id={sec.section_id}>

                <h2 className='sectionHead' dangerouslySetInnerHTML={{ __html: sec.section_title }}></h2>
                <div className="blog_content">
                  {sec.section_content.section_img.url.length > 0 ? <img className='sectionImg' src={`https://neodocs-blogs.s3.ap-south-1.amazonaws.com/blogs_compressed/${blog_details.id}/${sec.section_content.section_img.url}`} alt={sec.section_content.section_img.alt} ></img> : ''}

                  <div className='section_desc' dangerouslySetInnerHTML={{ __html: sec.section_content.section_desc }}></div>
                </div>
              </div>

            )
          }


          <CommentBlog commentsArray={blog_comments} blog_id={blog_details.id}></CommentBlog>
        </div>
        

      </section> : <Loader></Loader>}


      <Footer></Footer>
    </Fragment>
  )
}

export default SingleBlog