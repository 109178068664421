export const BLOG_AUTHORS = {
    AradhyaGiri:{
        display_name:"Aradhya Giri",
        role:"Final Year Dental Student",
        imageUrl:"AradhyaGiri.jpeg",
        emailId:"",
        shortDescription:""
    },
    DrMeghnaChandrachood:{
        display_name:"Dr Meghna Chandrachood MDS (OMFS)",
        role:"Consultant Oral and Maxillofacial Surgeon",
        imageUrl:"DrMeghnaChandrachood.jpeg",
        emailId:"",
        shortDescription:""
    }
}
