import React, { Fragment } from 'react'
import { useParams } from 'react-router';
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header';
import './CareerDescription.css';
import { CareerStructure } from './CareerStructure';
import { ImageUrl } from '../../../../components/HelperFunctions';


function CareerDescription() {
    const {id, type} = useParams();
    const selectedCareer = CareerStructure[type][id]
    console.log(selectedCareer)
    return (
        <Fragment>
            <Header></Header>
                <div className='one-career-page-main-container'>
                    <img src={ImageUrl('/poct/career-team.svg')} alt='Neodocs Career' className='career-illus'/>
                    <h1 style={{textAlign:'center'}} className='big-header-h1' >{selectedCareer.role_heading}</h1>
                    <div className='job-desc__one-block' style={{textAlign:'center'}}>
                        <p className='one-career__small-header'>Location</p>
                        <p>{selectedCareer.location}</p>
                    </div>
                    <div className='job-desc__one-block' style={{textAlign:'center'}}>
                        <p className='one-career__small-header'>Role Type</p>
                        <p>{selectedCareer.role_type}</p>
                    </div>
                    <div className='job-desc__one-block' style={{margin:'0 auto'}}>
                        <a href={selectedCareer.redirect_link} className='action-btn__primary' style={{width:'12rem'}}>Apply Now</a>
                    </div>
                    <div>
                        <p className='big-header-h1'>Job Introduction</p>
                        <p>{selectedCareer.introduction}</p>
                    </div>
                    <div className='job-desc__one-block'>
                        <p className='big-header-h1'>Your Mission</p>
                        <p>{selectedCareer.mission.map(mission=><li>{mission}</li>)}</p>
                    </div>
                    <div className='job-desc__one-block'>
                        <p className='big-header-h1'>What we need</p>
                        <p>{selectedCareer.requirements.map(requirements=><li>{requirements}</li>)}</p>
                    </div>
                    <div className='job-desc__one-block' style={{margin:'0 auto'}}>
                    <a href={selectedCareer.redirect_link} className='action-btn__primary' style={{width:'12rem'}}>Apply Now</a>
                    </div>
                </div>
            <Footer></Footer>
        </Fragment>
    )
}

export default CareerDescription