import {configureStore } from '@reduxjs/toolkit';
import AuthReducer from './domains/auth/AuthSlice';
import BlogReducer from './domains/blogs/BlogSlice';
import WebsiteReducer from './domains/mainWebsite/WebsiteSlice';
import AnalyzerReducer from './domains/pdfAnalyzer/AnalyzerSlice';

export default configureStore({
  reducer: {
    auth:AuthReducer,
    blogs:BlogReducer,
    website:WebsiteReducer,
    analyzer:AnalyzerReducer
  }
})