export const UpcomingProductContent = {
    vitamind:{
        order_link:"",
        kit_content_image_link:"uptestkit_contents.png",
        testkit_image_link:"vitamind_testkit.png",
        did_you_know:[
            'Vitamin D deficiency is now recognised as a global epidemic.','The health risks associated with Vitamin D deficiency are far more severe than previously thought.','Vitamin deficiency has been linked to various serious diseases like Diabetes, Strokes, Depression, Flu, Obesity etc.'
        ],
        display_name:'Vitamin D Rapid Test Cassette',
        short_desc:['An <strong>at-home</strong> testing kit that’ll help one track  an essential nutrient like Vitamin D, following <strong>three simple steps</strong>, in a matter of minutes.','Semi-quantitative detection of <strong>25-hydroxyvitamin D</strong> in human fingerstick Whole Blood. For self-testing in vitro diagnostic use.','This assay provides a preliminary diagnostic test result and can be used for screening for Vitamin D deficiency.'],
        
        parameters_detected:['Vitamin D [in the form of 25-hydroxyvitamin D (25 (OH) D)]'],
        hiw_steps:[{
            content:"Draw a blood sample using the sterile lancet provided",
            image:"blood/steps_1.png"
        },{
            content:"Collect the sample indicated by the black line, using the capillary pipette provided",
            image:"blood/steps_2.png"
        },{
            content:"Deposit the collected sample inside the well (S) indicated on the cassette.",
            image:"blood/steps_3.png"
        },{
            content:"Add 2 drops of diluent and wait 10 minutes before reading the result, comparing the lines obtained with the card provided.",
            image:"blood/steps_4.png"
        }],
        
        useful_for:['To screen, diagnose or monitor a Vitamin D related deficiency or disorder.'],
        accuracy:''
    },
    hba1c:{
        order_link:"",
        kit_content_image_link:"uptestkit_contents.png",
        testkit_image_link:"hba1c_testkit.png",
        did_you_know:[
            'Over the years, Diabetes has attained the honor (note the sarcasm) of being “the silent pandemic” amongst healthcare practitioners.','With its victims on-the-rise, a little precautionary care & regular monitoring for those affected might just be the solution.','We aim to bring HbA1c self-test kits to peoples doorsteps, over the next few years.'
        ],
        display_name:'HbA1c Self-Test Kit (Monitoring Blood Sugar)',
        short_desc:['An <strong>at-home</strong> testing kit that’ll help one track  an essential parameter like Hba1c, following <strong>four simple steps</strong>, in a matter of minutes.'],
        
        parameters_detected:[''],
        hiw_steps:[{
            content:"Draw a blood sample using the sterile lancet provided",
            image:"blood/steps_1.png"
        },{
            content:"Collect the sample indicated by the black line, using the capillary pipette provided",
            image:"blood/steps_2.png"
        },{
            content:"Deposit the collected sample inside the well (S) indicated on the cassette.",
            image:"blood/steps_3.png"
        },{
            content:"Add 2 drops of diluent and wait 10 minutes before reading the result, comparing the lines obtained with the card provided.",
            image:"blood/steps_4.png"
        }],
        
        useful_for:[''],
        accuracy:'The results are accurate as far as the instructions are carefully respected. A consultation with the doctor is always recommended for such tests based on immunological principles.'
    },
    ferritin:{
        order_link:"",
        kit_content_image_link:"uptestkit_contents.png",
        testkit_image_link:"ferritin_testkit.png",
        did_you_know:[
            'In a developing country like our own, nutritional deficiencies happen to be something medical professionals witness very commonly.',"Iron deficiency affects about 600 million people.","Our on-going research in developing a Ferritin Test aims to bring aid to that."
        ],
        display_name:'Ferritin Testing (for Monitoring Iron Levels in the Body)',
        short_desc:['An <strong>at-home</strong> testing kit that’ll help one track  an essential nutrient like Iron, following <strong>four simple steps</strong>, in a matter of minutes.'],
        
        parameters_detected:['Ferritin (Blood Protein that contains Iron)'],
        hiw_steps:[{
            content:"Draw a blood sample using the sterile lancet provided",
            image:"blood/steps_1.png"
        },{
            content:"Collect the sample indicated by the black line, using the capillary pipette provided",
            image:"blood/steps_2.png"
        },{
            content:"Deposit the collected sample inside the well (S) indicated on the cassette.",
            image:"blood/steps_3.png"
        },{
            content:"Add 2 drops of diluent and wait 10 minutes before reading the result, comparing the lines obtained with the card provided.",
            image:"blood/steps_4.png"
        }],
        
        useful_for:['To screen, diagnose & monitor Iron-related deficiencies & disorders.        '],
        accuracy:''
    },
    
    tsh:{
        order_link:"",
        kit_content_image_link:"uptestkit_contents.png",
        testkit_image_link:"tsh_testkit.png",
        did_you_know:[
            'About 42 million people in India suffer from thyroid diseases.','A rapid testing kit for monitoring TSH (the hormone that stimulates the thyroid gland), would be an asset in the advancement of healthcare.'
        ],
        display_name:'Rapid Testing for Thyroid Stimulating Hormone (TSH)',
        short_desc:['An <strong>at-home</strong> testing kit that’ll help one track  an essential parameter like TSH, following <strong>four simple steps</strong>, in a matter of minutes.'],
        
        parameters_detected:['Thyroid Stimulating Hormone (TSH)'],
        hiw_steps:[{
            content:"Draw a blood sample using the sterile lancet provided",
            image:"blood/steps_1.png"
        },{
            content:"Collect the sample indicated by the black line, using the capillary pipette provided",
            image:"blood/steps_2.png"
        },{
            content:"Deposit the collected sample inside the well (S) indicated on the cassette.",
            image:"blood/steps_3.png"
        },{
            content:"Add 2 drops of diluent and wait 10 minutes before reading the result, comparing the lines obtained with the card provided.",
            image:"blood/steps_4.png"
        }],
        
        useful_for:['To screen, diagnose & monitor Thyroid Hormone related deficiencies & disorders. (for example; Hypothyroidism, Hyperthyroidism, etc.)'],
        accuracy:''
    },

}