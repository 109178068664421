import React from 'react'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import Patterns from '../../../../components/Patterns'
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
    return (
        <div>
            <Header />
               
                    <section className="privacy-heading">
                            <h1 className='big-header-h1'>Privacy Policy</h1>
                          
                            <p>NeoDocs Privacy Policy</p>
                            <p>Last Updated: March 25th,2021</p>
                            <Patterns type="PATTERN_LOCK"></Patterns>
                    
                    </section>
                    <section className="privacy-container">
                    
                    
                    <div className="introduction" style={{width:'100%'}}>
                        <h2 className="part-heading">Introduction</h2>
                        <p>Neodocs (“Neodocs” or “We”) respects your privacy and is committed to protecting it through our compliance with this policy. Except as explicitly stated herein, this privacy policy (“Privacy Policy”) describes: (i) the types of information we may collect from you when you interact with us or use our services (“Services”), (ii) our practices for storing, safeguarding, and using that information, and (iii) circumstances under which we may share that information with third parties. This Privacy Policy does not apply to situations in which Neodocs processes data as a service provider solely on behalf of another organization or entity.  Please read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, please do not use our Website or our App and please don’t purchase our products or Services. By accessing or using our Website and/or App (or purchasing our products and/or Services), you agree to the terms in this Privacy Policy. This policy may change from time to time (see Changes to Our Privacy Policy in Section 13 below). Your continued use of our Website, App and/or products/Services after we make changes is deemed to be acceptance of those changes, so please check this Privacy Policy periodically for updates.</p>
                    </div>
                    <div className="information">
                        <h2 className="part-heading">Information We Collect About You</h2>
                        <p  style={{paddingTop:'1rem'}}>A. This policy applies to all information we collect about you, including:</p>
                        <ul style={{paddingTop:'1rem'}} className="information-ul" >
                            <li style={{display:'list-item'}}>via <a href="http://www.neodocs.in">www.neodocs.in</a> (our &ldquo;Website&rdquo;) and the Neodocs mobile application (our &ldquo;App&rdquo;);</li>
                            <li style={{display:'list-item'}}>via email, text, and other electronic messages between you and Us (or our Website and App);</li>
                            <li style={{display:'list-item'}}>when you interact with our advertising and applications on third-party websites and services; and/or</li>
                            <li style={{display:'list-item'}}>from healthcare providers or other third parties who send us information about you (e.g., related to testing results).</li>
                        </ul>
                        <p style={{paddingTop:'2rem'}}>B. The specific types of information we collect depend upon the products and services you use, how you use them, and the information you choose to provide via our Website, the App, or otherwise. The types of data we collect from or about you (&ldquo;Personal Data&rdquo;) may include the following:</p>
                        <ul style={{ display: 'flex', flexDirection: 'column',paddingTop:'1rem'}} className="information-ul">
                            <li style={{display:'list-item'}}>Name, address, telephone number, date of birth, email address, nickname, preferred pronouns, and/or photos you send us or upload to our App;</li>
                            <li style={{display:'list-item'}}>Log-in credentials, if you create an account;</li>
                            <li style={{display:'list-item'}}>Billing information, such as shipping address, credit or debit card number, verification number, expiration date, and identity verification information, collected by our payment processors on our behalf;</li>
                            <li style={{display:'list-item'}}>Information about purchases or other transactions with us;</li>
                            <li style={{display:'list-item'}}>Information about your medical history and your test results when you use one of our diagnostic tests;</li>
                            <li style={{display:'list-item'}}>Information about your customer service and maintenance interactions with us;</li>
                            <li style={{display:'list-item'}}>Demographic information such as your gender, age, race, legal sex, and ethnicity; and</li>
                            <li style={{display:'list-item'}}>Any other information you choose to directly provide to us in connection with your use of the Services, via surveys or otherwise.</li>
                        </ul>
                        <p  style={{paddingTop:'2rem'}}>C. Information We Collect Through Automated Means &zwj; In addition to the above information, we may collect certain information about your use of the Services and the devices you use to access the Services, as described in this Section. As discussed further below, we and third party companies that work on our behalf may use a variety of technologies, including cookies and similar tools, to assist in collecting this information.</p>
                        <ol style={{paddingTop:'1rem',paddingLeft:'0rem'}}>
                            <li style={{display:'list-item'}}><b>Our Website.</b> When you use our Website, we collect and analyze information such as your IP address, browser type, browser language, operating system, the state or country from which you accessed the Services, software and hardware attributes (including device IDs), referring and exit pages and URLs, platform type, the number of clicks, files you download, domain names, landing pages, pages viewed and the order of those pages, the amount of time spent on particular pages, the terms you use in searches on our sites, the date and time you use the Services, error logs, and other similar information.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}><b>Our App.</b> When you use our App, we automatically receive certain information about the mobile phone, tablet, or computer used to access the App, including a mobile device identifier, IP address, operating system, version, Internet service provider, browser type, domain name and other similar information, whether and when you update the App, date and time of use, and how you use the App, including time spent in different portions of the App.</li>
                            <li style={{display:'list-item',paddingBottom:'1rem'}}><b>Location Information.</b> When you use the Services, we and our service providers may automatically collect general location information (e.g., IP address, city/state, and or postal code associated with an IP address) from your computer or mobile device. This information allows us to enable access to content that varies based on a user&rsquo;s general location (e.g., to provide you with accurate sales tax information and to deliver content customized to your location). We will ask your permission before collecting your precise GPS location information. If you no longer wish for us and our service providers to collect and use GPS location information, you may disable the location features on your device by going to your device manufacturer settings.</li>
                            <li style={{display:'list-item'}}><b>Our Use of Cookies and Similar Online Tools.</b>To collect the information discussed in this Section, we and our service providers use web server logs, cookies, tags, SDKs, tracking pixels, and other similar tracking technologies. We use these technologies to offer you a more tailored online experience.
                            <ul style={{ display: 'flex', flexDirection: 'column',paddingTop:'1rem'}} className="information-ul">
                                <li style={{display:'list-item'}}>A web server log is a file where website activity is stored.</li>
                                <li style={{display:'list-item',padding:'1rem 0rem'}}>An SDK is a set of tools and/or code that we embed in our App and software to allow third parties to collect information about how users interact with the Services.</li>
                                <li style={{display:'list-item'}}>A cookie is a small text file that is placed on your computer or mobile device when you visit a site, that enables us to: (i) recognize your computer/device; (ii) store your preferences and settings; (iii) understand the parts of the Services you have visited and used; (iv), enhance your user experience by delivering and measuring the effectiveness of content and advertising tailored to your interests; (v) perform searches and analytics, and (vi) assist with security and administrative functions.</li>
                                <li style={{display:'list-item',padding:'1rem 0rem'}}>Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny electronic tags with a unique identifier embedded in websites, online ads, and/or email that are designed to (1) collect usage information like ad impressions or clicks and email open rates; (2) measure the popularity of the Services and associated advertising; and (3) access user cookies.</li>
                                <li style={{display:'list-item'}}>Pages of our Website, screens of our App, and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Neodocs, for example, to count users who have visited those pages, used those screens, or opened an email and for other related Website and App statistics (for example, recording the popularity of certain Website or App content and verifying system and server integrity).</li>
                                <li style={{display:'list-item',padding:'1rem 0rem'}}>As we adopt additional technologies, we may also gather information through other methods. Please note that you can change your settings to notify you when a cookie is being set or updated, or to block cookies altogether. Please note that by blocking, disabling, or managing any or all cookies, you may not have access to certain features or offerings of the Services.</li>
                            </ul>
                            </li>
                            <li style={{display:'list-item'}}>We may use Hotjar in order to better understand our users&rsquo; needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users&rsquo; experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don&rsquo;t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users&rsquo; behavior and their devices. This includes a device&apos;s IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf. Through Hotjar, we may collect on real-time basis information on how you use and navigate the Website. This may include mouse movements and how you scroll through the Website, but we do not collect keystroke data. For further details, please see the &lsquo;about Hotjar&rsquo; section of <a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar">Hotjar&rsquo;s support site.</a></li>
                            <li style={{display:'list-item',padding:'1rem 0rem'}}><strong>Information We Collect From Social Media and Other Content Platforms.</strong> When you &ldquo;like&rdquo; or &ldquo;follow&rdquo; us on Facebook, Instagram, Twitter, Linked In, or other social media sites, we may collect some information from (or about) you including your name, email address, and any comments or content you post relevant to us. We also collect your information if you sign up for one of our promotions or submit information to us through social media sites.</li>
                            <li style={{display:'list-item'}}><strong>Information We Receive From Other Sources.</strong> We work closely with third parties (including, for example, third-party intermediaries, such as the physicians, medical professionals, and pharmacies with whom we partner to provide you with the Services and their health care services, sub-contractors in technical, advertising networks, analytics providers, and search information providers). Such third parties will sometimes provide us with additional information about you.</li>
                            <li style={{display:'list-item',padding:'1rem 0rem'}}>The information we collect automatically may include Personal Data or we may maintain it or associate it with Personal Data we collect in other ways or receive from third parties. This information helps us to improve our Website and App and to deliver a better and more personalized service by, for example, enabling us to:<ul className="information-ul" style={{ display: 'flex', flexDirection: 'column',paddingTop:'1rem'}}>
                                <li style={{display:'list-item'}}>estimate our audience size and usage patterns;</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>verify your location to ensure we can provide you with our Services;</li>
                                <li style={{display:'list-item'}}>store information about your preferences, allowing us to customize our Website and our App according to your individual interests; or</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>recognize you when you return to our Website and our App.</li>
                            </ul>
                            </li>
                        </ol>
                    </div>
                    <div className="usage">
                    <h2 className="part-heading">How We Use Your Information</h2>
                        <ol style={{paddingTop:'1rem',paddingLeft:'0rem'}}>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> We use information that we collect about you or that you provide to us, including your Personal Data:</li>
                            <ul  style={{ display: 'flex', flexDirection: 'column',paddingTop:'1rem'}} className="information-ul">
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to carry out, improve, and manage the Services (including presenting our Website and App to you and sharing your diagnostic test results with you);</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to facilitate the provision of health care services to you by providing physicians or other health care providers, including telehealth providers, with your data;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to provide diagnostic test results obtained as part of the Services to your health care provider(s) and/or to federal, state, and local public health authorities and/or other governmental authorities (if required by law) and/or research institutions (in the case of provider-initiated tests in the context of research studies);</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to provide you with information, products, and/or Services that you request from us or that we think might be of interest to you;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to engage in research and data analysis to understand the effectiveness of our products and Services, to improve our products and Services, and to better understand our customer base (in some cases we may share your data with service providers such as Mixpanel in order to facilitate this analysis);</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to communicate with you about our products and/or Services, your use of our products and/or Services, or your inquiries related to products and/or Services and send you communications on behalf of physicians or other health care providers in connection with our Services;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to communicate with you by email, postal mail, or phone about surveys, promotions, special events or our products and Services and those of our subsidiaries, affiliates, and parent companies and any of their related businesses and those of our third-party partners;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to provide you with technical support and customer service;</li>
                                <li style={{paddingBottom:'1rem'}}>to verify your identity and administer your account, including processing your payments and fulfilling your orders;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to measure or understand the effectiveness of advertising and content we serve to you and others, and to deliver and customize relevant advertising and content to you.</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to help us better understand your interests and needs, such as by engaging in analysis and research regarding use of our products and/or Services;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to comply with applicable laws, regulations, court orders, subpoenas and/or governmental requests, where we believe it is necessary to do so;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to protect the rights, property, or safety of Neodocs, our customers, or others;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to establish, exercise, or defend our legal rights;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to fulfill any other purpose for which you provide it;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection purposes;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>to notify you about changes to our Website, our App, or our products/Services;</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>in any other way we may describe when you provide the information; and</li>
                                <li style={{paddingBottom:'1rem',display:'list-item'}}>for any other purpose with your consent.</li>
                                
                            </ul>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> <b>Aggregate/De-Identified Data.</b> We may aggregate and/or de-identify any information collected about you so that such information can no longer be linked to you or your device (“Aggregate/De-Identified Information”). We may use Aggregate/De-Identified Information for any purpose, including for research and marketing purposes, and may also share such data with any third parties, including advertisers, promotional partners, and sponsors.</li>
                        </ol>
                    </div>
                    <div className="share">
                        <h2 className="part-heading">How We Share Your Information</h2>
                        <ol style={{paddingTop:'1rem',paddingLeft:'0rem'}}>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> Affiliates and Other Third Parties. We may share your information with our affiliates as well as with third parties who provide certain services to us related to billing, sales, marketing, advertising, analytics, research, customer service, shipping and fulfillment, data storage, IT and security, fraud prevention, payment processing, and auditing and legal services.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> Business Transfers. In connection with any possible business transaction or corporate restructuring, we may transfer customer data to a buyer (or potential buyer) or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Neodocs about our customers is among the assets transferred.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> Public Forums. Certain features of our Services make it possible for you to share comments publicly with other users. Any information that you submit through such features is not confidential, and we may use it for any purpose (including in testimonials or other marketing materials). For example, if you submit a product review via our Website or App, we may display your review (along with the name provided, if any) on our online properties as well as on third-party websites. Any information you post openly in these ways will be available to the public at large and potentially accessible through third-party search engines. Accordingly, please take care when using these features.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> Legal Rights. We may share your information when we believe in good faith that doing so is necessary to protect, enforce, or defend the legal rights, privacy, safety, or property of Neodocs, our affiliates, employees, agents, licensors, users, or any third party, or to comply with applicable law or legal process, including responding to requests from public and government authorities.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> Consent/At Your Direction. We may share your information with your consent or at your direction, such as if you direct us to disclose your information to a healthcare provider.</li>
                            

                        </ol>
                    </div>
                    <div className="online-analytics">
                        <h2 className="part-heading">Online Analytics And Tailored Advertising</h2>
                        <ol style={{paddingTop:'1rem',paddingLeft:'0rem'}}>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> We and our third-party vendors may use device identifiers and other automated technologies (including cookie identifiers on our Website), along with other collected information, to deliver content or tailor ads when you are on other devices, apps or websites. These ads may be based, for instance, on the types of websites that you visit over time, the types of apps you have on your device, or de-identified information about you and your likely interests, based on your activities off of our Service. Sometimes, we (or our service providers) may use the information we collect—for instance, IP addresses and unique mobile device identifiers—to locate or try to locate the same unique users across multiple browsers or devices (such as smartphones, tablets, or computers), or work with providers that do this, to help identify visitors to the Website and serve advertising. If you wish to opt out of cross device tracking for purposes of interest-based advertising, you may do so through your device settings. Partners that we or others work with to do the above may track your activities over time (including across different apps or websites) by collecting information through automated means, and they may use this information, and other information they receive from us or other sources, to deliver advertisements to you.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> Our Website and App use Facebook ad network and Google Adwords to provide you with advertising based on your use of our Website and App. You can learn how to opt-out of such use by Facebook at <a href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a>   <a href="https://www.facebook.com/policies/cookies/">https://www.facebook.com/policies/cookies/</a>and by Google at <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>   <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>. You can learn more generally about interest-based advertising on the web, or opt out of receiving those ads, by visiting (i) the [Network Advertising Initiative’s Consumer Opt-Out]<a href="https://optout.networkadvertising.org/?c=1">https://optout.networkadvertising.org/?c=1</a> or (ii) the [Digital Advertising Alliance’s Consumer Opt-Out]<a href="https://optout.aboutads.info/?c=2&lang=EN">https://optout.aboutads.info/?c=2&lang=EN</a>to opt-out of receiving tailored advertising from companies that participate in those programs. To opt out of Google Analytics for display advertising or customize Google display network ads, you can visit the [Google Ads Settings]<a href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a> page. As to cross-app advertising, each operating system (e.g., iOS for Apple phones, Android for Android devices, and Windows for Microsoft devices) provides its own instructions on how to prevent the delivery of tailored in-application advertisements. You should view your device or system “Settings” to determine how you can opt out of use of your device identifier for“ cross-app” personalized advertising.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> Please note that to the extent advertising technology is integrated into our Services, you may still receive advertisements even if you opt-out. In that case, the advertising will not be tailored to your interests. Also, we do not control any of the above opt-out links or whether any particular company chooses to participate in these opt-out programs.</li>
                            
                        </ol>
                    </div>
                    <div className="choices">
                        <h2 className="part-heading">Choices About How We Use and Disclose Your Information</h2>
                        <ol style={{paddingTop:'1rem',paddingLeft:'0rem'}}>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> We do not control the collection and use of your information collected by third parties described above in How We Share Your Information. When possible, these organizations are under contractual obligations to use this data only for providing the services to us and to maintain this information as strictly confidential. These third parties may, however, aggregate the information they collect with information from their other customers for their own purposes.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> In addition, we strive to provide you with choices regarding the Personal Data you provide to us. We have created the following mechanisms to provide you with control over your Personal Data:
                            <ul style={{ display: 'flex', flexDirection: 'column',paddingTop:'1rem'}} className="information-ul">
                                <li style={{padding:'1rem 0rem',display:'list-item'}}><b>Tracking Technologies and Advertising.</b> You can set your browser settings to refuse all or some cookies, or to alert you when cookies are being used. If you disable or refuse cookies, please note that some parts of our Website or App may then be inaccessible or not function properly.</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}><b>Promotional Offers from Neodocs.</b> If you do not wish to have your email address used by Neodocs to promote our own products and Services, you can opt-out at any time by clicking the unsubscribe link at the bottom of any email or other marketing communications you receive from us. This opt-out does not apply to information provided to Neodocs as a result of a product purchase or your use of our Services.</li>
                            </ul>
                            </li>
                        </ol>
                    </div>
                    <div className="your-rights">
                        <h2 className="part-heading">Your Rights Regarding Your Information and Accessing and Correcting Your Information</h2>
                        <p>You can review and change most portions of your Personal Data by logging into our App and visiting your account profile screen. You may also notify us through the email address provided in Section 14 below of any changes or errors in any Personal Data we have about you to ensure that it is complete, accurate, and as current as possible or to delete your account. We cannot delete your Personal Data except by also deleting your account. We may also not be able to accommodate your request if we believe it would violate any law or legal requirement or cause the information to be incorrect.</p>
                    </div>
                    <div className="track">
                        <h2 className="part-heading">Do Not Track Signals</h2>
                        <p>We may use automated data collection technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). Some web browsers permit you to broadcast a signal to websites and online services indicating a preference that they “do not track” your online activities. At this time, we do not honor such signals and we do not modify what information we collect or how we use that information based upon whether such a signal is broadcast or received by us.</p>
                    </div>
                    <div className="data-security">
                        <h2 className="part-heading">Data Security</h2>
                        <p>We have implemented measures designed to secure your Personal Data from accidental loss and from unauthorized access, use, alteration, and disclosure. We use encryption technology for information sent and received by us. ‍ The safety and security of your information also depends on you. Where you have chosen a password for the use of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. ‍ Unfortunately, the transmission of information via the internet is not always secure. Although we do our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted to our Website or on or through our App. Any transmission of Personal Data is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website, in your operating system, or in the App.</p>
                        <ol style={{paddingTop:'1rem',paddingLeft:'0rem'}}>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> <b>Categories of personal information we collect and disclose.</b> Throughout this Privacy Policy, we discuss in detail the specific pieces of personal information we collect from and about our users.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> As described in our Privacy Policy above, in the preceding twelve months, we or our service providers may have collected the below categories of personal information about you for business or commercial purposes:
                            <ul style={{ display: 'flex', flexDirection: 'column',paddingTop:'1rem'}} className="information-ul">
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>Identifiers and contact information (e.g., name, address, email address, account names)</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>Commercial and transactional information (e.g., information about your purchases)</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>Financial information (e.g., credit card info collected by our payment processors)</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>Internet or other network or device activity (e.g., IP address, browsing history, app usage)</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>Geolocation information (e.g., general location and precise location, with your permission)</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>Demographic and statistical data (e.g., your gender, interests based on products and services you use)</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>Physical characteristics (e.g., photos of you)</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>User-generated content (e.g., information you choose to post in our online forums)</li>
                                <li style={{padding:'1rem 0rem',display:'list-item'}}>Customer service data (e.g., information you provide through a chat or call with Neodocs’s Support Team)</li>
                            </ul>
                            </li>
                        </ol>
                        <ol style={{paddingTop:'1rem',paddingLeft:'0rem'}}>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> We collect the categories of personal information identified above from the following sources: (1) directly from you; (2) through your use of the Services; (3) affiliates; and/or (4) third parties such as social networks and third-party intermediaries such as physicians, medical professionals, and pharmacies.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> The business purposes for which we use the personal information we collect are: to manage, facilitate, and improve the Services; for research, marketing, and analytics; to communicate with users; for technical support and customer service; for security and fraud prevention; and for legal compliance and defense.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> In the previous twelve months, we may have shared certain categories of personal information with vendors and third parties for business purposes. We may share your personal information with our service providers, our affiliates, and with other entities for legal and fraud prevention. We may also share certain personal information with health care providers and other third parties at your direction or with your consent, such as (1) identifiers and contact information; (2) commercial and transactional information; and (3) customer service data.</li>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> For more detailed information about how we use and share your personal information, please see the “How We Use Your Information” section of this Privacy Policy above.</li>
                        </ol>
                        <ol style={{paddingTop:'1rem',paddingLeft:'0rem'}}>
                            <li style={{padding:'1rem 0rem',display:'list-item'}}> <b>Sale of Personal Information.</b> Neodocs is not in the business of selling your personal information such as your name, email address, phone number, or postal address. Like many companies with an internet presence, we do work with third parties that provide marketing and advertising services to us, including interest-based advertising services, and these third parties may place tags, pixels, cookies, beacons, and other similar tracking mechanisms on our Website and App and collect your online identifiers. While we do not believe use of these vendors constitutes a “sale,” we do provide mechanisms for you to opt out of such tracking. To opt out of sharing your information with participating third parties for interest-based advertising, please visit the Network Advertising Initiative’s Consumer Opt-Out link, the Digital Advertising Alliance’s Consumer Opt-Out link, and Your Online Choices provided above in Section 5. Please note that these opt-outs are device and browser based, so you will need to opt out on each of your devices and browsers.</li>
                        </ol>
                    </div>
                    <div className="third-party">
                        <h2 className="part-heading">Third Party Privacy Practices</h2>
                        <p>This Privacy Policy does not address, and Neodocs is not responsible for, the privacy, information, or other practices, including data privacy and security process and standards of any third parties, including physicians and other health care providers using the Services (or otherwise affiliated with Neodocs), the manufacturer of your mobile device and other IT hardware and software, and any other third party mobile application, website, or service to which our Services may contain a link. These third parties may at times gather information from or about you. We have no control over the privacy practices of these third parties. The collection, use, and disclosure of your information will be subject to the privacy policies of the third-party websites or services, and not this Privacy Policy. We urge you to read the privacy and security policies of these third parties.</p>
                    </div>
                    <div className="changes">
                        <h2 className="part-heading">Changes To Our Privacy Policy</h2>
                        <p>We may change this Privacy Policy at any time. It is our policy to post any changes we make to our Privacy Policy on this page with a notice that the Privacy Policy has been updated on our Website’s home page. If we make material changes to how we treat our users’ Personal Data, we will notify you by email to the email address specified in your account and/or through a notice on the Website’s home page. The date this Privacy Policy was last revised is identified at the top of this policy. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and reviewing this Privacy Policy to check for any changes.</p>
                    </div>
                    </section>
             
            <Footer />
        </div>

       

    )
}

export default PrivacyPolicy
