import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import './CareerPage.css';
import { CareerStructure } from './CareerStructure';

function CareerPage() {
  console.log(Object.keys(CareerStructure).filter(types=>Object.keys(CareerStructure[types]).length>0))
  const careers = Object.keys(CareerStructure).filter(types=>Object.keys(CareerStructure[types]).length>0).map(type=>(
      
        <div className='career-category'>
        <div className='career-category__heading'>{type}</div>
        <div className='career-category__roles'>
          {Object.keys(CareerStructure[type]).filter(id=>!CareerStructure[type][id].is_closed).map(id=>(<NavLink className='career-roles__one' to={`/career/${type}/${id}`}>
                <div className='career-roles__one__heading'>{CareerStructure[type][id].role_heading}</div>
                <div className='career-roles__one__categories'>
                  <div>{CareerStructure[type][id].role_type}</div>
                  <div>{CareerStructure[type][id].location}</div>
                </div>
                <div className='career-roles__one__desc'>{CareerStructure[type][id].small_introduction}</div> 
              </NavLink>))}
              
        </div>
        </div>
        
  ))
  
  return (
    <Fragment>
        <Header></Header>
        <div className='career-page-main-container'>
          <div className='header-container'>
            <h1 className='big-header-h1'>Careers</h1>
          </div>
          <div className='about-neodocs-culture'>
                <h1 className='big-header-h1' style={{color:'var(--primary-color-blue)'}}>Let's change the future of healthcare, together!</h1>
                <div className='sub-header'>Neodocs has created a revolutionary approach to help people understand and optimize their health through at-home assessment, personalized actions, and ongoing progress monitoring. And through this, we’re unlocking a world where everyone can feel, perform, and live better.</div>
          </div>
          {/* search bar functionality in future */}
          <div>{careers}</div> 
        </div>
        <Footer></Footer>
    </Fragment>
    
  )
}

export default CareerPage