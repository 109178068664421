import React, { useEffect, useState } from 'react';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import './Pricing.css';
import {useLocation, NavLink} from 'react-router-dom';
import { setAlert } from '../../WebsiteSlice';
import { useDispatch } from 'react-redux';
import Testimonials from '../Usables/Testimonials'
import Patterns from '../../../../components/Patterns';
import { ImageUrl } from '../../../../components/HelperFunctions';

// const dummy={package_name:'atg',why:'kjh',old_price:'78',new_price:'976',frequency:'23',features:['asd','asd'],key_parameters:['lkjhg','jhgf']}
function Pricing(props) {
    const a = useLocation().state;
    const dispatch = useDispatch()
    const personalisation = a ? a.personalisation : false
    const personalised_data= a ? a.personalised_data : {}
    if(personalisation===undefined){
        personalisation=false;
        personalised_data={}
    }
     
    const openPopUp = () =>{
        dispatch(setAlert('pop'))
        
    }
   
   
    return (
        <div>
            <Header></Header>
        
            {/* <section className="common-plans"> */}
               
                {/* {personalisation && <div className="plan_card">
                    <p className='bestseller' style={{fontWeight:'800',color:'var(--primary-color-green)'}}>Package, just for you</p>
                               
                                <div className="planHead">
                                    <p className="plantitle">{personalised_data.package_name} Package</p>
                                    <p className="small_note">{personalised_data.why}</p>
                                    
                                </div>
                                
                                <p className="price"><i className="bx bx-rupee"></i><span style={{textDecoration: 'line-through',fontSize: '18px',fontWeight: 300,}}>{personalised_data.old_price}</span><span>{personalised_data.new_price}</span></p>
                                <div className="features">
                                    <p style={{fontSize:'small',marginBottom:'0.5rem'}}><i class='bx bx-time'></i> {personalised_data.frequency}</p>
                                    <p style={{fontSize:'small',marginBottom:'0.5rem'}}><i class='bx bx-test-tube' ></i> {personalised_data.key_parameters.join(', ')}</p>
                                

                                    {personalised_data.features.map(feature=>(<p className="featurelistitem">{feature}</p>))}
                                    
                                    
                                </div>
                                
                                <NavLink to='order/15' className="action-btn__primary__bordered" >Request this kit</NavLink>
                </div>}
                <h2 className='sub-header'>Our common plans</h2>
               
                <div className="all-plans-container">
                    
                    <div className="plan_card">
                        <div className="planHead">
                            <div>
                                <p className="plantitle">One time purchase</p>
                                <p className="small_note">Box of 3 cards</p>
                            </div>
                            <div>
                                <p className="price mb-0">
                                    <i className="bx bx-rupee"></i>
                                    <span style={{textDecoration: 'line-through',fontSize: '18px',fontWeight: 300,}} className="cancelled-price">749</span>
                                    <span>499</span>
                                    <span className="small-note popup-notify">(Beta)</span>
                                    <p>for 3 cards</p>
                                </p>
                            </div>
                            
                        
                            

                        </div>
                        
                        <div className="features">
                            <p className="featurelistitem"><i class='bx bx-group' ></i><p>Share with family</p></p>
                            <p className="featurelistitem"><i class='bx bxs-truck'></i><p>Free Shipping</p></p>
                        </div>
                       
                        <NavLink to='/order/15' className="action-btn__primary__bordered" style={{pointerEvents:'none'}}>Request this kit</NavLink>
                    </div>
                    <div className="plan_card">
                        <div className="planHead">
                            <div>
                                <p className='bestseller' style={{fontWeight:'800',color:'var(--primary-color-green)'}}>Our Bestseller</p>
                                <p className="plantitle">12 month purchase</p>
                                <p className="small_note">Ships 3 cards Monthly</p>
                            </div>
                            <div>
                                <p className="price"><i className="bx bx-rupee"></i><span style={{textDecoration: 'line-through',fontSize: '18px',fontWeight: 300,}} className="cancelled-price">4,999</span><span>2,999</span><span className="small-note popup-notify">(Beta)</span></p>
                                <p>for 36 cards</p>
                            </div>
                            
                            
                        </div>
                        
                        
                    
                        <div className="features">
                            <p className="featurelistitem"><i class='bx bx-group' ></i><p>Share with family</p></p>
                            <p className="featurelistitem"><i class='bx bxs-truck'></i><p>Free Shipping</p></p>
                        </div>
                      
                        <NavLink to='/order/18' className="action-btn__primary__bordered"  >Request this kit</NavLink>
                    </div>
                    
                    <div className="plan_card">
                        <div className="planHead">
                            <div>
                                <p className="plantitle">3 month purchase</p>
                                <p className="small_note">Ships 3 cards Monthly</p>
                            </div>
                            <div>
                                <p className="price"><i className="bx bx-rupee"></i><span style={{textDecoration: 'line-through',fontSize: '18px',fontWeight: 300,}} className="cancelled-price">1,999</span><span>999</span><span className="small-note popup-notify">(Beta)</span></p>
                                <p>for 9 cards</p>
                            </div>
                            
                            
                        </div>
                        
                        
                    
                        <div className="features">
                            <p className="featurelistitem"><i class='bx bx-group' ></i><p>Share with family</p></p>
                            <p className="featurelistitem"><i class='bx bxs-truck'></i><p>Free Shipping</p></p>
                        </div>
                    
                        <NavLink to='/order/16' className="action-btn__primary__bordered" >Request this kit</NavLink>
                    </div>
                </div> */}
               
                
            {/* </section> */}
            <section id="products-pricing">
                <Patterns type="LOTUS"></Patterns>
                <div style={{textAlign:'center'}}>
                    <h1 className='big-header-h1' >You're one step closer to better health<span style={{color:'var(--secondary-color-purple)'}}>.</span></h1>
                
                
                    <h2 className='sub-header' style={{paddingBottom:'1rem'}}>Buy now, and take control.</h2>
                </div>
                <div className='all-benefits'>
              
                    <div >
                        <div className='one-included'>
                            <img src={ImageUrl('/poct/care-kits/track.svg')} alt="Care Kit" ></img>
                            <p>Track & understand upto 10 health parameters</p>
                        </div>
                        <div className='one-included'>
                            <img src={ImageUrl('/poct/care-kits/superfood.svg')} alt="Care Kit" ></img>
                            <p>Get superfood suggestions to improve your levels</p>
                        </div>
                        <div className='one-included'>
                            <img src={ImageUrl('/poct/care-kits/personalised-plan.svg')} alt="Care Kit" ></img>
                            <p>Maintain your health with personalized plans</p>
                        </div>
                        <div className='one-included'>
                            <img src={ImageUrl('/poct/care-kits/share-family.svg')} alt="Care Kit" ></img>
                            <p>Share with family and grow together</p>
                        </div>
                    </div>
                </div>
                
                <div className='all-products-container'>
                    {/* <div className='each-product' style={{backgroundColor:'#d36450'}}>
                        <img src={ImageUrl('/poct/services/ckd/ckd-kit.png')} alt="Care Kit" style={{padding:window.innerWidth>500? '2rem 4.5rem':'1rem 3rem'}} className='each-product__img'></img>
                        <div>
                            <div className='each-product__desc'>Kidney Care Kit</div>
                            <NavLink to='/products/kidney' className='each-product__read-more'>Read More</NavLink>
                            <a className='each-product__buynow' href='https://www.amazon.in/dp/B09X15V4FR'>Buy Now <i class='bx bxs-chevron-right'></i></a>
                        </div>
                        
                    </div> */}
                    <div className='each-product' style={{background:'linear-gradient(90deg, rgba(44, 51, 114, 0.8) 1.21%, rgba(0, 7, 73, 0.8) 100%)'}}>
                        <img src={ImageUrl('/poct/care-kits/wellness.svg')} alt="Care Kit" className='each-product__img'></img>
                        <div>
                            <div className='each-product__desc'>
                                Wellness Care Kit<br></br>
                                
                            </div>
                            <div className='buynow-links'>
                                <NavLink to='/' className='each-product__read-more'>Read More</NavLink>
                                <a className='each-product__buynow' href='https://www.amazon.in/NeoDocs-Instant-At-Home-Glucose-Hydration/dp/B0B6RRNYN7/ref=sr_1_5?keywords=neodocs+pregnancy+care&qid=1658506701&s=hpc&sprefix=neodocs+pregnan%2Chpc%2C172&sr=1-5'>Buy Now <i class='bx bxs-chevron-right'></i></a>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                    <div className='each-product' style={{background:'linear-gradient(90deg, rgba(95, 46, 89, 0.8) 1.21%, rgba(95, 46, 89, 0.8) 100%)'}}>
                        <img src={ImageUrl('/poct/care-kits/uti.svg')} alt="Care Kit" className='each-product__img'></img>
                        <div>
                        <div className='each-product__desc'>UTI Care Kit</div>
                        <div className='buynow-links'>
                            <NavLink to='/products/uti' className='each-product__read-more'>Read More</NavLink>
                            <a className='each-product__buynow' href='https://www.amazon.in/Urinary-Infection-Detection-At-Home-Instant/dp/B0B6PWKXKD/ref=sr_1_2?crid=1FLZE4D7IG2SQ&keywords=neodocs+uti&qid=1658506648&s=hpc&sprefix=neodocs+uti%2Chpc%2C174&sr=1-2'>Buy Now <i class='bx bxs-chevron-right'></i></a>
                        </div>
                        </div>
                        
                    </div>
                    <div className='each-product' style={{background:'linear-gradient(90deg, rgba(66, 108, 156, 0.8) 1.21%, rgba(66, 108, 156, 0.8) 100%)'}}>
                        <img src={ImageUrl('/poct/care-kits/elderly.svg')}  alt="Care Kit" className='each-product__img'></img>
                        <div>
                            <div className='each-product__desc'>Elderly Care kit</div>
                            <div className='buynow-links'>
                                <NavLink to='/products/elderly-care' className='each-product__read-more'>Read More</NavLink>
                                <a className='each-product__buynow' href='https://www.amazon.in/Elderly-Screening-Instant-Parameters-Hydration/dp/B0B6RQT6HY/ref=sr_1_4?crid=3N69WCLCY3UL3&keywords=neodocs+elderly&qid=1658506679&s=hpc&sprefix=neodocs+elderl%2Chpc%2C170&sr=1-4'>Buy Now <i class='bx bxs-chevron-right'></i></a>
                            </div>
                            
                        </div>
                    </div>
                    <div className='each-product' style={{background:'linear-gradient(90deg, rgba(97, 70, 141, 0.8) 1.21%, rgba(97, 70, 141, 0.8) 100%)'}}>
                        <img src={ImageUrl('/poct/care-kits/pregnancy.svg')} alt="Care Kit" className='each-product__img'></img>
                        <div>
                            <div className='each-product__desc'>Pregnancy Care Kit</div>
                            <div className='buynow-links'>
                                <NavLink to='/products/pregnancy' className='each-product__read-more'>Read More</NavLink>
                                <a className='each-product__buynow' href='https://www.amazon.in/Pregnancy-wellness-Instant-Leukocystes-Nitrites/dp/B0B6RRGKT4/ref=sr_1_4?keywords=neodocs+pregnancy+care&qid=1658506701&s=hpc&sprefix=neodocs+pregnan%2Chpc%2C172&sr=1-4'>Buy Now <i class='bx bxs-chevron-right'></i></a>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                
            </section>
            
            {/* <section className="personalised_pricing"  style={{padding:0,height:'80vh'}}>
                {(personalisation) ?'': 
                    <div className='take-quiz-container'>
                        <div>
                            
                            <img src={process.env.PUBLIC_URL+"/docassets/img/poct/pricing/take_test.svg"} id="pricing_img" style={{height:'80vh',width:'100%',objectFit:'cover'}} alt='Neodocs kit pricing'/>
                        </div>
                        <div>
                        
                            <h1 className='big-header-h1'>One size <br></br>doesn't fit all</h1>
                            <h2 className='sub-header'>Take the quiz and <br></br>get personalized plans that fit you the best</h2>
                            <a href="/personalization-quiz" className="action-btn__secondary">Take the quiz</a>
                                
                        </div>
                        
                    </div>}
                
            </section> */}
            <Testimonials></Testimonials>
            <Footer></Footer>
        </div>
           
        
        
    )
}

export default Pricing
