import React, { useEffect, useRef } from 'react';
import './FuturePlan.css';
// animations
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger"

// components
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';

gsap.registerPlugin(ScrollTrigger)

const colors = ['#CCCCFF','#F7C9C9','#bce8bc','#B9E7D6','#c7c777']
const plans = [
    
    {
        planName:"At home blood tests",
        planDesc:"lorem lorem",
        
    },
    {
        planName:"At home stress tests",
        planDesc:"lorem lorem",
        
    },
    {
        planName:"At home sugar tests",
        planDesc:"lorem lorem",
        
    },
    {
        planName:"At home stress test",
        planDesc:"lorem lorem",
        
    }
]

function FuturePlan() {
    const pathBox = useRef([]);
    const planBox = useRef([]);

    const addToBoxRef = (el) => {
        if(el && !pathBox.current.includes(el)){
            pathBox.current.push(el);
        }
    }
    

    useEffect(()=>{
        planBox.current.forEach((plan,idx)=>{
            
            gsap.fromTo(plan,{opacity:0.4,border:'1px dashed gray'},{
                opacity:1,
                border:'1px solid white',
                scrollTrigger:{
                 
                    trigger:plan,
                    id:'planbox-'+idx,
                    start:'top 50%',
                    end:'bottom 20%',
                    toggleActions:'play none none reverse'
                }
            })
        })
    },[planBox])
    useEffect(()=>{
        // let box_length = pathBox.current[0].querySelector('svg').height.animVal.value
        // pathBox.current[0].querySelector('svg').style.strokeDasharray = box_length;
        // pathBox.current[0].querySelector('svg').style.strokeDashoffset = box_length;
     
        pathBox.current.forEach((path,idx)=>{
            if(idx!==pathBox.current.length-1){
            gsap.fromTo(path.querySelector('.white-cover'),
                {
                    yPercent:0
                },{
                    
                scrollTrigger:{
                    trigger:path,
                    // markers:true,
                    id:'pathbox-'+idx,
                    start:'top 50%',
                    end:'bottom 40%',
                    toggleActions:'play none none reverse',
                    
                    
                },
                
                yPercent:100,
            })}

        })
    },[pathBox])


    return (
        <>
            <Header></Header>
            <div className='future-plans-conatiner-full'>
                <h1 className='big-header-h1' >Future of Neodocs</h1>
                <h2 className='sub-header' style={{marginBottom:'3rem'}}>Here is our pipeline</h2>
                <div className='plan-container'>
                    {plans.map((plan,idx)=>(<>
                        <div ref={el => planBox.current[idx] = el} className={`one-plan-box ${idx%2===0?'plan-right-box':'plan-left-box'}`} style={{background:colors[idx%plans.length]}}>
                        
                        <div className='plan-name big-header-h1'>{plan.planName}</div>
                            <div className='plan-desc'>{plan.planDesc}</div>
                        </div>
                        {idx%2===0?<div ref={el=> pathBox.current[idx] = el} className='one-path-box path-right-box'>
                        
                        <div className='white-cover'></div>
                        <svg width="20rem"  height="20rem" viewBox="0 0 539 862" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M533.515 0.996094C562.663 333.391 417.754 427.211 273.767 427.211C129.781 427.211 1.35938 544.769 1.35938 861.765" stroke={colors[idx%plans.length+1]} stroke-width="12"/>
                        </svg>
                        
                        </div>:<div className='one-path-box path-left-box' ref={el=> pathBox.current[idx] = el}>
                      
                        <div className='white-cover'></div>
                        <svg width="20rem" height="20rem" viewBox="0 0 539 862" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M533.507 861.766C562.656 529.371 439.976 432.501 295.99 432.501C152.003 432.501 1.35156 317.993 1.35156 0.996332" stroke={colors[idx%plans.length+1]} stroke-width="12"/>
                        </svg>
                        </div>}
                    </>))}
                    
                    
                    
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default FuturePlan
