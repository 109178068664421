import React,{ useState, useEffect} from 'react';
import './Register.css';
import { ReportImageUrl } from '../../../../components/HelperFunctions';
import { useHistory, useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../AuthSlice';
import { collection, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import axios from 'axios';

const KEY_MAP = {
    fname:'First Name',
    lname:'Last Name',
    date_of_birth:'Date of Birth',
    gender:'Gender'
} 

const DisabledButtonStyle = {
    cursor:'none',
    pointerEvent:'none',
    opacity:0.3,
    filter:'grayscale(0.3)'
}

function Register() {
    // /?mobile_
    const history = useHistory()
    const auth = useSelector(state=>state.auth)
    const [user_address,setuser_address] = useState({
        street_address:"",
        pincode:"",
        apartment:""
    })
    const phone_no = useLocation().search.split('&&')[0].replace('?mobile_no=','') 
    const country_code = useLocation().search.split('&&')[1].replace('country_code=','+') 
   
    const dispatch = useDispatch()
    const [ButtonStatus, setButtonState] = useState(1);
    const [required_details,setrequired_details] = useState(false)
    const [pageStatus,setpageStatus] = useState(201);
    const [autoredirecttimer, setautoredirecttimer] = useState(3);
    const [user_details,setuser_details] = useState({phoneNumber:'+91'+phone_no,firstName:'',lastName:'',gender:'',dateOfBirth:''});

    useEffect(()=>{
        console.log(phone_no)
        axios.post('https://asia-south1-neodocs-8d6cd.cloudfunctions.net/isUserRegistered',{phoneNumber:`${country_code}${phone_no}`})
        .then(res=>{
            console.log(res.data)
            if(res.data.statusCode===200){
                if(res.data.body.isUserRegistered){
                    setpageStatus(202)
                    window.location.href= "https://wa.me/+917400281971"
                }else{
                    
                    setpageStatus(201)
                }
            }
        })
    },[])
    
    const autoredirectToWhatsapp = () => {
        setTimeout(()=>{
            window.location.href= "https://wa.me/+917400281971"
        },3000)

        let int_id =setInterval(()=>{
            if(autoredirecttimer!==0){
                setautoredirecttimer(prev=>prev-1)
            }else{
            clearInterval(int_id)
            }
        },1000)

        
    }
     
    const submitRegistration = () =>{
        setButtonState(0)
        console.log(user_details)
        
        if(Object.keys(user_details).some(key=>(!user_details[key]))){
            setButtonState(1)
            setrequired_details(true)
        }else{
            axios.post("https://asia-south1-neodocs-8d6cd.cloudfunctions.net/registerUser",{...user_details},{headers:{"password":"(e^v=[5hJKB.N{L*"}})
            .then(res=>{
                console.log(res.data)
                if(res.data.statusCode===200){
                    setpageStatus(200)
                }
                else{
                    setpageStatus(500)
                }
            })
        }
    }
    
   useEffect(()=>{
    if(pageStatus===200){autoredirectToWhatsapp()}
   },[pageStatus])

    
    if((Object.keys(auth.user_details).length===0) || (auth.user_details.uId && !auth.user_details.firstName) || auth.statusCode===201){
    return (
        <div className='registeration-flow-container'>
            {/* registration form */}
            {(pageStatus===201 || pageStatus===500) && <div className='registeration-form'>
                <div className='registeration-form-illus'>
                    {/* <img src={ReportImageUrl('register.svg')}></img> */}
                    
                    <h3 className='big-header-h1' style={{color:'#e4e4e4'}}>One account for good health.</h3>
                    <h1 className='sub-header' style={{color:'#e4e4e4'}}>Create your free account now!</h1>
                </div>
                <div className='registeration-form-wrapper'>   

                    
                    {required_details && <div style={{color:'red'}}>*Please fill all the fields {required_details}</div>}
                    {pageStatus===500 && <div style={{color:'red'}}>*Please try again {required_details}</div>}
                    <input type='number' name='phoneNumber' value={phone_no} disabled ></input>

                    <div style={{display: 'grid',gridTemplateColumns: '1fr 1fr',gap: '1rem'}}>
                        <div>
                            <label>First Name*</label>
                            <input type='text' name='firstName'  onChange={(e)=>setuser_details(prev=>({...prev,[e.target.name]:e.target.value}))} required></input>
                        </div>
                        <div>
                            <label>Last Name*</label>
                            <input type='text' name='lastName'  onChange={(e)=>setuser_details(prev=>({...prev,[e.target.name]:e.target.value}))} required></input>
                        </div>
                        
                        
                    </div>
                    {/* <div>
                        <input type='text' name='email' placeholder='Email ID' onChange={(e)=>setuser_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                        
                    </div> */}
                    <div>
                        <label>Email ID*</label><br></br>
                        <input type='email' name='email'  onChange={(e)=>setuser_details(prev=>({...prev,[e.target.name]:e.target.value}))}required></input>
                        <label>Date of Birth*</label><br></br>
                        <input type='date' name='dateOfBirth'  onChange={(e)=>setuser_details(prev=>({...prev,[e.target.name]:new Date(e.target.value)}))}required></input>
                        <label>Gender</label>
                        <select id="gender" name="gender" onChange={(e)=>setuser_details(prev=>({...prev,[e.target.name]:e.target.value}))} required>
                            <option defaultChecked={true}></option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="non_binary">Non-binary</option>
                        </select>
                    
                    </div>
                    {/* <div className='address-space'>
                        <label>Your Address</label>
                      
                            <input type='text' name="streetAddress" placeholder='Street Address' onChange={(e)=>setuser_address(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                      
                        
                        <input type='text' name="apartmentNo" placeholder='Apartment No./ Unit' onChange={(e)=>setuser_address(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                        <input type='text' name="pincode" placeholder='Pincode' onChange={(e)=>setuser_address(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                        
                    </div> */}
                    <div>
                        <div className='register-btn' style={ButtonStatus ? {} : DisabledButtonStyle} onClick={submitRegistration}>Register</div>
                    </div>

                </div>

                
            </div>}
            {/* registration successful */}
            {pageStatus===200 && 
                    <div className='successPage'>
                        <h1 className='registeration-form-title'>Account Created</h1>
                       
                        <img src={ReportImageUrl('success.svg')} alt='register'></img>
                        <p style={{textAlign:'center'}}>Auto redirecting you to whatsapp in {autoredirecttimer} seconds.</p>

                    </div>
            }
            {/* already registered */}
            {pageStatus===202 && <div className='successPage'>
                        <h1 className='registeration-form-title' style={{textAlign:'center'}}>You're already a registered user. </h1>
                        <p style={{textAlign:'center'}}>Upload your reports on Whatsapp to get smart reports!</p>

            </div>}
        </div>
    )}else if(auth.user_details.uId && auth.user_details.firstName && auth.statusCode===200){
        return(<>{history.push('/profile')}</>)
    }
    else {return(<>{history.push('/login')}</>)}
}

export default Register
