import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import { ORDER_DETAILS, CREATE_ORDER_BIOLERPLATE } from '../../../../components/Constants/PricingDetails';
import axios from 'axios';
import './OrderFlow.css';
import Button from '../../../../components/Button/Button';


function OrderFlow() {
    const [pageStatus, setpageStatus] = useState('not-paid');
    const {orderid} = useParams();
    const order_details = ORDER_DETAILS[orderid];
    // shipment inputs
    const [shipment_details,set_shipment_details] = useState(CREATE_ORDER_BIOLERPLATE.billing);
    // total pricing calculations
    const [total_price, set_total_price] = useState(order_details.price)
    // coupons
    const [coupon_added, set_coupon_added] = useState([]);
    const [coupon_discount, set_coupon_discount] = useState(0);
    const [coupon_code_input,set_coupon_code_input] = useState('');
    // errors
    const [coupon_error,setcoupon_error] = useState('');
    const [shipping_details_error,setshipping_details_error] = useState('')
    // button states 0: inactive 1:active 2:proceesing
    const [coupon_apply_btn,setcoupon_apply_btn] = useState(1)
    const [shipment_checkout_btn,setshipment_checkout_btn] = useState(1)
    let paymentWindow = "";
    async function checkPrice(order_id){
        let status = '';
        let count = 0;
        let intervalId = setInterval(()=>{
            axios.get('https://wellnesscards.in/wp-json/wc/v3/orders/'+order_id,{headers:{'Authorization':process.env.REACT_APP_WOOCOMMERCE_KEY}}).then(res=>{status=res.data.status});
            count++;
           
            if(status === "processing" && count <= 10){
                paymentWindow.close("")
                clearInterval(intervalId);
                setpageStatus('paid');
            }if(count > 50){
                paymentWindow.close("");
                setshipping_details_error("Payment Failed.")
                setshipment_checkout_btn(1)
            }

        },4000)
        
    }

    const removeCoupon = () =>{ 
        set_coupon_added([]);
        set_coupon_discount(0);
        set_coupon_code_input('');
        setcoupon_apply_btn(1);
    }

    const calculateDiscount = (price, coupon_data) => {
        
        let {amount=0, discount_type='percent'} = coupon_data;

        if(discount_type==="percent"){
            console.log(price-((parseFloat(amount)*price)/100));
            set_total_price(parseFloat(price-((parseFloat(amount)*price)/100)).toFixed(2));
            set_coupon_discount((amount*price)/100);
        }else if(discount_type==="fixed_cart" || discount_type==="fixed_product" ){
            console.log(price-(parseFloat(amount)));
            set_total_price(parseFloat(price-(parseFloat(amount))).toFixed(2));
            set_coupon_discount(amount);
        }
    }

    

    const applyCoupon = () =>{
        setcoupon_apply_btn(2)
        axios.get('https://wellnesscards.in/wp-json/wc/v3/coupons/?code='+coupon_code_input,{headers:{'Authorization':process.env.REACT_APP_WOOCOMMERCE_KEY}})
        .then(res=> {
            console.log(res.data)
            set_coupon_code_input('')
            if(res.data.length!==1 || res.data===null){
                setcoupon_error("Invalid coupon code");
                setcoupon_apply_btn(1);
            }else{
                let coupon_data = res.data[0];
                if(coupon_data.date_expires && (new Date(coupon_data.date_expires) >= new Date())){
                    setcoupon_error("Sorry, this coupon has expired")
                    setcoupon_apply_btn(1)
                }else{
                    setcoupon_error('')
                    set_coupon_added(res.data)
                    setcoupon_apply_btn(0)
                }
            }
        })
        
    }
    useEffect(()=>{
        calculateDiscount(order_details.price, coupon_added.length>0?coupon_added[0]:{})
    },[coupon_added])

    // shipping detail form Validation
    const handleValidation = () => {
        let fields = Object.keys(shipment_details);
        let requiredFields = ['first_name','last_name','email','phone','address_1','city','postcode','country','state']
        let validationRegexHash = { 
            email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            phone: /^[0]?[789]\d{9}$/
        }
        return fields.every(field=> {
            console.log(field)
            console.log(shipment_details[field]!=='' && (field in validationRegexHash ? validationRegexHash[field].test(shipment_details[field]) : true))
            return((requiredFields.indexOf(field)===-1?true:shipment_details[field]!=='') && (field in validationRegexHash ? validationRegexHash[field].test(shipment_details[field]) : true) )})
    }

    const createOrder = () => {
        if(shipment_checkout_btn===1){
            setshipment_checkout_btn(2);
            if(handleValidation()){
                setshipping_details_error(false)
                let result = {...CREATE_ORDER_BIOLERPLATE};
                console.log(result)
                result.line_items = [{
                    product_id:orderid,
                    quantity:1
                }];
                result['billing'] = shipment_details;
                Object.keys(result['shipping']).forEach(key=>{
                    result.shipping[key] = shipment_details[key];
                })
                if(coupon_added.length>0){
                    result.coupon_lines = [{
                        code:coupon_added[0].code
                    }];
                }
                
                axios.post('https://wellnesscards.in/wp-json/wc/v3/orders',result, {headers:{'Authorization':process.env.REACT_APP_WOOCOMMERCE_KEY}})
                .then(res=>{
                    console.log(res);
                    if(res.status===201 || res.status===200){
                        checkPrice(res.data.id);
                        paymentWindow = window.open(`https://wellnesscards.in/checkout/order-pay/${res.data.id}/?key=${res.data.order_key}`,"razorpayPayment_self","")
                    }
                    else{setshipping_details_error('Something went wrong, please try again.')
                        setshipment_checkout_btn(1)
                    }})
                .catch(err => {
                        setshipping_details_error('Something went wrong, please try again.')
                        setshipment_checkout_btn(1)
                })
               
            }else{
                setshipment_checkout_btn(1)
                setshipping_details_error("Invalid Details.")
                
            }
        }else{
            return;
        }

    }
  return (
    <div>
        <Header></Header>
        {pageStatus==='not-paid' ? <section id="order-details-container">
            <div className='shipment-details white-box'>
                <h1 className='big-header-h1'>Add shipment details</h1>
                
                <div className='shipment-details__form'>
                    <input type='text' placeholder='First Name' name='first_name' value={shipment_details.first_name} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                    <input type='text' placeholder='Last Name' name='last_name' value={shipment_details.last_name} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                    <label className='input-container'>
                        <span>+91</span>
                        <input type='number' placeholder='Mobile No.' name='phone' value={shipment_details.phone} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                    </label>
                    <input type='email' placeholder='Email ID' name='email' value={shipment_details.email} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                    
                    
                    <input placeholder='Address' name='address_1' value={shipment_details.address_1} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>

                    <input type='text' placeholder='City' name='city' value={shipment_details.city} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                    <input type='text' placeholder='State' name='state' value={shipment_details.state} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                    <input type='number' placeholder='Pincode' name='postcode' value={shipment_details.postcode} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                    <input type='text' placeholder='Country' name='country' value={shipment_details.country} onChange={(e)=>set_shipment_details(prev=>({...prev,[e.target.name]:e.target.value}))}></input>
                </div>
            </div>
            <div className='package-details  white-box'>
                <div className='order-details__summary'>
                    <h1 className='big-header-h1'>Your Order Summary</h1>
                    <div className='order-meta'>{order_details.name} <span className='quantity'>Qty: 1</span></div>
                    <div className='order-price'>
                        <p className='old-price'><i className='bx bx-rupee'></i>{order_details.regular_price}</p>
                        <p className='new-price'><i className='bx bx-rupee'></i>{order_details.price}</p>
                        
                    </div>
                    
                </div>
                <div className='total-price-calculations'>
                    <div id="total-price-calculations__table">
                        <p className='MRP'>Total MRP: <span><i className='bx bx-rupee'></i>{order_details.price}</span></p>
                        
                        <p className='shipping-price'>Delivery Charges: <span><i className='bx bx-rupee'></i>0</span> </p>
                        <p className="coupon-added-details">Coupon discount: <span>-<i className='bx bx-rupee'></i>{coupon_discount}</span></p>
                        <p className='total-price'>To pay: <span><i className='bx bx-rupee'></i>{total_price}</span></p>
                    </div>
                    
                     <div id='order-coupons' >
                        {coupon_added.length===0 && <><p><i class='bx bxs-discount'></i> Have a coupon code?</p>
                        {coupon_error!=='' && <div className='error-msg'>{coupon_error}</div>}
                         <div id="coupon-addition-form">
                            <input type='text' placeholder='Coupon Code' name='coupon_code_input' value={coupon_code_input} onChange={(e)=>set_coupon_code_input(e.target.value)}></input>
                            {/* <div className="action-btn__secondary" onClick={coupon_apply_btn===1?()=>applyCoupon():''}>
                                {coupon_apply_btn===1 && 'Apply'}
                                {coupon_apply_btn===2 && <i className='bx bx-loader-circle bx-spin' ></i>}
                                {coupon_apply_btn===0 && 'Apply'}
                            </div> */}
                            <Button className='action-btn__secondary' onClick={()=>applyCoupon()} status={coupon_apply_btn} statusMessages={{0:'Apply',1:'Apply',2:""}}></Button>
                        </div></>}
                        {coupon_added.length!==0 && 
                        <>
                         <strong>Applied Coupon Code:</strong> {coupon_added[0].code}
                        <div style={{color:'var(--secondary-color-purple)',cursor:'pointer'}} onClick={()=>removeCoupon()}>Remove coupon code</div></>}
                    </div>
                </div>
                
            </div>
            <div>
                <div className='error-msg'>{shipping_details_error}</div>
                <Button className='action-btn__primary' onClick={()=>createOrder()} status={shipment_checkout_btn} statusMessages={{0:'Proceed Checkout',1:'Proceed Checkout',2:" Processing Request"}}>
                                {/* {shipment_checkout_btn===1 && 'Proceed Checkout'}
                                {shipment_checkout_btn===2 && <i className='bx bx-loader-circle bx-spin' ></i>}
                                {shipment_checkout_btn===0 && 'Proceed Checkout'} */}
                </Button>

            </div>
            
        </section> : <section>
                                
            <lottie-player src="https://assets2.lottiefiles.com/private_files/lf30_yo2zavgg.json"  background="transparent"  speed="1"  style={{height:'30vh'}}    autoplay></lottie-player>
            <h1 className='big-header-h1' style={{color:'var(--primary-color-blue)',textAlign:'center'}}>Yay!</h1>
            <h2 className='sub-header' style={{color:'var(--primary-color-blue)',textAlign:'center'}}> You're now one step closer to good health!</h2>
        </section>}
        <Footer></Footer>
    </div>
  )
}

export default OrderFlow