import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import Header from '../Header/Header'
import { ImageUrl } from '../HelperFunctions'
import './ErrorPages.css'

function Error404() {
    return (
        <Fragment>
        <Header></Header>
        <div className="error" >
            
            <div className="infographic">
                <a href="https://storyset.com/internet" style={{display:'none'}}>Internet illustrations by Storyset</a>
                <img src={ImageUrl('/poct/Error404.png')} alt="404 error"></img>
            </div>
            <div className="textContainer">
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#e1b799" fill-opacity="0.5" d="M0,160L80,154.7C160,149,320,139,480,154.7C640,171,800,213,960,202.7C1120,192,1280,128,1360,96L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
            </svg> */}
                
                <h2 className='big-header-h1'>We were unable to find the page you're looking for.</h2>
                <p>Here are some helpful links:</p>
                <ul className="linksContainer">
                    <NavLink to='/'><i className='bx bx-home'></i> Home</NavLink>
                    <NavLink to='/faq'><i className='bx bx-help-circle' ></i> FAQs</NavLink>
                    <NavLink to='/about'><i className='bx bx-book-content' ></i> About Us</NavLink>
                    
                </ul>
            </div>
        </div>
      
        </Fragment>
    )
}

export default Error404
