import React from 'react';
import { NavLink } from 'react-router-dom';
import { ImageUrl } from '../HelperFunctions';
import './Footer.css'

function Footer(props) {
    let {style}=props
    return (
        <div id="website-footer" style={style}>
            <div className='footer-section'>
                <div  id="footer-company-name"> <img src={ImageUrl('poct/neodocs-new-logo.svg')} alt="neodocs_logo"></img></div>
                <div id="footer-company-address">9, New Vora House,<br/>Goregaon West,<br/>Mumbai - 400104,<br/>Maharashtra, India.</div>
             
            </div>
          
            <div className='footer-section footer-company'>
                <div className="footer-section-title">Company</div>
                <div className='footer-section-list'>
                    <NavLink to="/about">About</NavLink>
                    <NavLink to="/career">Careers</NavLink>
                    <NavLink to="/our-technology">Our Science</NavLink>
                    <NavLink to="/data-privacy">Data Privacy</NavLink>
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                    <NavLink to="/accuracy">Labs & Accuracy</NavLink>
                    <NavLink to="/terms-of-service">Terms of Service</NavLink>
                </div>
            </div>
            <div className='footer-section footer-services'>
                <div className="footer-section-title" >Products</div>
                <div className='footer-section-list'>
                    <NavLink to="/products/uti">UTI care kit</NavLink>
                    <NavLink to="/products/kidney">Kidney care kit</NavLink>
                    <NavLink to="/products/elderly-care">Elderly Care kit</NavLink>
                    <NavLink to="/products/pregnancy">Pregnancy care kit</NavLink>
                    <NavLink to="/pdf-analyzer">Smart Report Generation</NavLink>
                    
                </div>
            </div>
            <div className='footer-section footer-seocials'>
                <div className="footer-section-title" >Socials</div>
                <div className='social-list '>
                        <a  style={{fontSize:'2rem',paddingRight:'1rem'}}  href="https://twitter.com/neodocs" className="twitter"><i className="bx bxl-twitter"></i></a>
                        <a  style={{fontSize:'2rem',paddingRight:'1rem'}} href="https://www.facebook.com/neodocs" className="facebook"><i className="bx bxl-facebook"></i></a>
                        <a style={{fontSize:'2rem',paddingRight:'1rem'}}  href="https://www.instagram.com/neodocs" className="instagram"><i
                                    className="bx bxl-instagram"></i></a>
                        <a style={{fontSize:'2rem',paddingRight:'1rem'}}  href="https://www.youtube.com/channel/UCtWYtzpyCbx111FHVvjMRFw" className="youtube"><i className="bx bxl-youtube"></i></a>
                        <a style={{fontSize:'2rem',paddingRight:'1rem'}}  href="https://www.linkedin.com/company/neodocs" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
            </div>

            
            
            <div id='copyright-para'>
                <p  style={{textAlign:'center',fontSize:'small !important',letterSpacing:'1px'}}>© Copyright NeoDocs. All Rights Reserved.</p>
            </div>
        </div>
    )
}

export default Footer
