import React,{Fragment, useContext} from 'react'
import { useSelector } from 'react-redux';
import { NavLink ,Redirect} from 'react-router-dom';
import {  animateScroll as scroll } from 'react-scroll';
import { patientContext } from '../Context';
import { useParams } from 'react-router';
function Step({stepNo,step}){
    return(
        <div className='step'>
            <div className='line'></div>
            <div className='stepNo'>
                <div className='pseudostepNo'>{stepNo}</div>
            </div>
            <div className="stepDesc">{step}</div>

        </div>
    )
}

function Yoga({recmDetail,name,allRecms,type,filter}) {
   
    const rid=useParams().rid
    scroll.scrollToTop({delay:0,duration:0})
    if(recmDetail){
        const benefits=recmDetail.benefits.map((benefit,i)=>(<li>{benefit}</li>))
        const precautions=recmDetail.contraindications.map((el,i)=>(<li>{el}</li>))
        const steps=recmDetail.steps.map((step,i)=>(<Step stepNo={step.number} step={step.desc} key={`step_${i}`}></Step>))
    
    
    return (
        <Fragment>
            <div className="navbar">
                
                <NavLink to={{pathname:`/wb/smart_report/${rid}/recommendations`,recommendations:allRecms,activeState:'yoga',filter:filter}}><i className='bx bx-left-arrow-alt'></i></NavLink>
                <p>{name.replace('_',' ')}</p>
                
            </div>
          
            <div className="recmImage">
                <img src={`${process.env.PUBLIC_URL}/report_assets/img/${type}/${recmDetail.display_name.toLowerCase().replaceAll(' ','_')}.jpg`} ></img>
            </div>
        <div className="recmDescription">
           
            <h4 className="recmTitle">{name.replace('_',' ')}</h4> 
            <div className='pills'>
                <div className="duration"><i className='bx bx-time' ></i>{recmDetail.duration}</div>
                <div className={`difficulty`}>{recmDetail.difficulty}</div>
            </div>
             
            <hr/>
            <div className="eachInstructionWrapper benefits">
                <div className='eachInstructionContainer benefits'>
                    <p className="sectionTitle">
                    <i class='bx bx-donate-heart' ></i> Benefits for you
                    </p>
                    <ul className="sectionList">
                        {benefits}
                    </ul>
                </div>
                <div className='eachInstructionContainer precautions'>
                    <p className="sectionTitle">
                    <i class='bx bx-info-square' ></i>  Precautions And Contraindications
                    </p>
                    <ul className="sectionList">
                        {precautions}
                    </ul>
                </div>
                <div className="eachInstructionContainer stepsWrapper">
                    <div className='sectionTitle'>How to perform</div>
                    <div>{steps}</div>

                </div>
            </div>        
        </div>

        </Fragment>
        
    )}
    else{
        return(<Redirect to='/'></Redirect>)
    }
}

export default Yoga
