import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import Loader from '../../../components/Loader';
import './UploadPortal.css'
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import gsap from 'gsap/all';


function UploadPortal() {
    const [btnState,setBtnState] = useState('upload');
    const [inputFiles,setInputFiles] = useState([]);
    const [report_status,setreport_status]=useState(0);
    const uploadbtnref = useRef(null)
    const user=useSelector(state=>state.auth);
    
    const history=useHistory()
    

    let intervals=[]
    async function checkReportStatus(rid){
        
        let res=await axios.post('/v1/report_status',{report_id:rid},{headers: {'Authorization':`Bearer ${user.token}`}})
        console.log(res.data)
            if(res.data.statusCode===200){
                setreport_status(200)
                intervals.forEach(id=>clearInterval(id)) 
                history.push({
                    pathname:'/smart-report/'+res.data.body.result_id
                    
                })
                return 200
                //    window.location.href=`https://smartreports.neodocs.in/?${res.data.body.report_id}`
            }else if(res.data.statusCode===204){
                setreport_status(204) 
                let id=setInterval(()=>checkReportStatus(rid),30000)
                intervals.push(id)
            }else{
                console.log('error')
                return 201
            }
        
    }

    const uploadFile=(e)=>{
        let file=e.target.files[0]
        var form = new FormData();
        form.append("file", file);
        setInputFiles(prev=>[...prev,form])
        setBtnState('uploading')
        setTimeout(()=>{
            setBtnState('uploaded')
        },1000)
        
    }
    const submitReport = () =>{
        console.log(inputFiles[0])
        setBtnState('processing')
        axios.post('/v1/pdf_analyzer/upload_pdf',inputFiles[0], {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization':`Bearer ${user.token}`
            }
        }).then(res=>{
            
            if(res.data.statusCode===200){
                
                let status = checkReportStatus(res.data.body.report_id);
                if (status===200){
                //    redirect
                   return 1;
                }else{
                
                   return 0;
                }}
            })

    }
    useEffect(()=>{
        if(btnState==='uploaded'){
            uploadbtnref.current.addEventListener('click',submitReport)
            uploadbtnref.current.querySelector('input').disabled=true
            
        }
        if(btnState==='uploading'){

        }
    },[btnState])
   
    if(user.user_details.uId){
        if(report_status===204){
            return (<Loader></Loader>)
        }else{
            
        return (
            <>
                <Header></Header>
                <div className="uploadProfile" style={{minHeight:'100vh'}}>
                    <h1 style={{textAlign:'start'}}>PDF analyzer</h1>
                    <p style={{textAlign:'start'}}>Understand your report in just a few minutes</p>
                    
                    <label className='uploadReportBtn center' ref={uploadbtnref}>
                        {btnState==='upload' ? <p> <i class='bx bx-upload'></i> Upload Report</p> : btnState==='uploaded' ? <p> <i class='bx bx-upload'></i>Submit</p> : btnState==='uploading' ? <p><i class='bx bx-loader-circle bx-spin' style={{color:'#ffffff'}}  ></i> Uploading</p> : <p><i class='bx bx-loader-circle bx-spin' style={{color:'#ffffff'}}  ></i> Processing</p> }
                        <input  style={{height:'0px',width:'0px'}} type='file' accept='.pdf' onChange={(e)=>uploadFile(e)}></input>
                    </label>
                    {btnState==='uploaded' && <p>Uploaded</p>}
                
                </div>
                <Footer></Footer>
            </>
            
            
        )}
    }else{
        return(<Redirect to='/login'></Redirect>)
    }
}

export default UploadPortal
