import React,{useState,useEffect, Fragment,useRef} from 'react'
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Footer';
import '../Category/Category.css';
import '../../../../node_modules/font-awesome/css/font-awesome.min.css';
import Loader from '../../../components/Loader';
import BlogCard from '../BlogCards/BlogCard';
import { collection, getDocs, orderBy, query, where, getDoc, doc  } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import { Helmet } from 'react-helmet';
import PaginationContainer from '../Pagination/PaginationContainer';


const category_desc={
    diet:"It's not a short-term diet, it's a longterm lifestyle.",
    health:'The groundwork of all happiness is health.',
    womens_health:"A woman's health is her capital",
    wellness:"Self care isn't selfish",
    recipes:"Nourish your soul with good food",
    uti:"UTI is the most common bacterial infection occurring in the kidney transplant recipient.",
    general:"Health is the first step to wealth."
}

const Category = () => {
    const [initialCategoryBlog , setInitialCategoryBlog] = useState([]);
    const [latest_blog,setLatestBlog] = useState({});
    const totalPagesRef = useRef(0)
    const category = useParams().blog_type;
    const onePageCapacity = 4;
    

    useEffect(()=>{
      const BlogRef = collection(db, "blog_meta");
      
      const allBlogs = query(BlogRef, where("categories", "array-contains", category), orderBy('updated_on', 'desc'));
      getDocs(allBlogs).then(blogs=>{
        
        totalPagesRef.current = Math.ceil((blogs.docs.length-1)/onePageCapacity)
        
        let sections = doc(db, `blog_meta/${blogs.docs[0].data().id}/blog_desc/desc_${blogs.docs[0].data().id}`)
            getDoc(sections).then(sectionres=>{
              setLatestBlog({...blogs.docs[0].data(),intro:sectionres.data().section[0].section_content.section_desc})})
        blogs.docs.slice(1,onePageCapacity+1).forEach((blog,i)=>{
          
            setInitialCategoryBlog(prev=>[...prev,blog.data()])
          
        })

      })
      // const categoryBlogs = query(BlogRef, where("categories", "array-contains", category), orderBy('updated_on', 'desc'), startAt(start_point), limit(onePageCapacity+1));

      // getDocs(categoryBlogs)
      // .then(res=> {
        
        
      // }); 

      return () => setInitialCategoryBlog([])
      },[]);
  
    return (
        <div>
          <Helmet>
            <title>Neodocs | {category} Blogs</title>
            <meta name="description" content={`Neodocs blogs for ${category}`} />
          </Helmet>
        <Header/>
        <>
        <div className="category-title-container" >
            <NavLink to="/blogs" ><i className='bx bx-arrow-back' style={{color:'white',zIndex:'9999',fontSize:'2rem'}}></i></NavLink>
            <div className="topic">
              <h1 className='big-header-h1 header-quote' ><i class='bx bxs-quote-left'></i>{category_desc[category]}<i class='bx bxs-quote-right'></i></h1>
             
              
            </div>
             
        </div>
        <section className="all-category-blogs">
           {Object.keys(latest_blog).length>0 ?<>
            <div className='category-recent-blog'>
              <BlogCard blog={latest_blog} type={"latest-blog-card"}></BlogCard>
            </div>
            {<PaginationContainer initialBlogs={initialCategoryBlog} onePageCapacity={onePageCapacity} totalPages={totalPagesRef.current} category={category} ></PaginationContainer>}
          </>: <Loader></Loader>}
        </section> </> 
        <Footer/>
        </div>
    )
}

export default Category
