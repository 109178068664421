import React, { useEffect, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { ImageUrl } from '../../../../components/HelperFunctions';
import { NavLink } from 'react-router-dom';
import './Fertility.css'

    function Fertility() {
       
        return (
            <section>
                <div className="first-section">
                <h1 className="first-section__heading">TRUSTED BY 20,000+ COUPLES</h1>
                <h3 className="first-section__subheading">Track your fertility hormones at home, in 10 minutes</h3>
                <img className="first-section__image" src="/neodocs_fertility.png" alt="Neodocs Fertility Monitor & Hormone Tracker for Women" />
            </div>
            </section>        
        )
    }

    export default Fertility
