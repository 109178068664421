import React, { useEffect, useRef } from 'react';
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { ImageUrl } from '../../../../components/HelperFunctions';
import './AppTour.css'
import Patterns from '../../../../components/Patterns';
gsap.registerPlugin(ScrollTrigger)

function AppTour2() {
    const tourWrapperRef = useRef(null);
    const oneTourRef = useRef(null);
    const oneTourRefImage = useRef([]);
    oneTourRef.current = [];
    
    const addToOneTour = (el, ref=oneTourRef) =>{
        if(el && !ref.current.includes(el)){
            ref.current.push(el)
        }
    }
    const addToOneTourImage = (el, ref=oneTourRefImage) =>{
        if(el && !ref.current.includes(el)){
            ref.current.push(el)
        }
    }
    useEffect(() => {
        let t1= gsap.timeline({
            scrollTrigger:{
                id:'app-tour',
                trigger:tourWrapperRef.current,
                start:'top 0%',
                toggleActions:'play complete reverse reset',
                pin:true,
                scrub:true,
               
            }
        })
        
        t1.fromTo(oneTourRef.current[0],{opacity:1},{opacity:0})
        t1.fromTo(oneTourRefImage.current[0],{opacity:1},{opacity:0})

        
        t1.fromTo(oneTourRefImage.current[1],{opacity:0},{opacity:1},'<')
        t1.fromTo(oneTourRef.current[1],{y:10,opacity:0},{y:0,opacity:1},'<')
        t1.fromTo(oneTourRef.current[1],{opacity:1},{opacity:0})
        t1.fromTo(oneTourRefImage.current[1],{opacity:1},{opacity:0})


        t1.fromTo(oneTourRefImage.current[2],{opacity:0},{opacity:1},'<')
        t1.fromTo(oneTourRef.current[2],{y:20,opacity:0},{y:0,opacity:1},'<')
       

        // t1.fromTo(oneTourRefImage.current[3],{opacity:0},{opacity:1},'<')
        // t1.fromTo(oneTourRef.current[3],{opacity:0},{opacity:1})
        // t1.fromTo(oneTourRef.current[3],{opacity:1},{opacity:0})
        // t1.fromTo(oneTourRefImage.current[3],{opacity:1},{opacity:0})
    }, [oneTourRef])

    
    
    
    return (
        <section id="app-tour-container" ref={tourWrapperRef}  >
            <Patterns type="DROP_PATTERN"></Patterns>
            
            <div className='app-tour__illustration'>
                <div className='one-tour__image' ref={addToOneTourImage}>
                      <img src={ImageUrl('poct/resultscreen_mockup_1.png')} alt='neodocs app'></img>
                </div>
                <div className='one-tour__image' ref={addToOneTourImage}>
                    <img src={ImageUrl('poct/new-mockups/measure-graph.png')} alt='neodocs app'></img>
                </div>
                <div className='one-tour__image' ref={addToOneTourImage}>
                    <img src={ImageUrl('poct/new-mockups/wellness_super_foods.png')} alt='neodocs app'></img>
                </div>
                {/* <div className='one-tour__image' ref={addToOneTourImage}>
                    <img src={ImageUrl('poct/app-tour/MyPlan.png')} alt='neodocs app'></img>
                </div> */}
                
            </div>
           
             
            <div className='app-tour__desc' >
                
                <div className='one-tour' ref={addToOneTour}>
                    
                    <div className='one-tour__details'>
                        <h1 className='big-header-h1'>Results for 10 parameters across 7 panels</h1>
                        <p className='sub-header one-tour__details__desc'>Easy to understand digital results and actionable insights for the panels  put you in control and and help you take the next steps.</p></div>
                </div>
                <div className='one-tour' ref={addToOneTour}>
                    
                    <div className='one-tour__details'>
                        <h1 className='big-header-h1'>Monitor trends to track improvement</h1>
                        <p className='sub-header one-tour__details__desc'>By testing regularly you can monitor trends to notice any improvement or deterioration in the health and take the required next steps</p></div>
                </div>
                <div className='one-tour' ref={addToOneTour}>
                    
                    <div className='one-tour__details'>
                        <h1 className='big-header-h1'>Tailor-made recommendations</h1>
                        <p className='sub-header one-tour__details__desc'>Everyone is unique based on their biomarker. We personalised the next steps for each user based on their current lifestyle, results and age.
Speak to our medical advisors for further guidance.</p>
                    </div>
                </div>
                {/* <div className='one-tour' ref={addToOneTour}>
                    
                    <div className='one-tour__details'>
                        <h1 className='big-header-h1'>Backed by science</h1>
                        <p className='sub-header one-tour__details__desc'>Recommendations are tailored to your body’s needs. Your recommendations are personalised to you based on your biomarker results.</p>
                    </div>
                </div> */}
                
            </div>
        </section>
    )
}

export default AppTour2
