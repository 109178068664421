

export const TIREDNESS_QUIZ_QUESTIONS = {
    title:"How likely are you to doze off or sleep in the following situations.",
    questionnaireID : 'questionnaire_how_likely_dose_off_sleep_quiz',
    questions:[
        {
            questionId:0,
            question_image:"/questionnaires/sleepiness/reading.svg",
            question_title:"Sitting and reading",
            choices:[
                {inputtype:"radio",choice_title:"Would never dose off",choice_name:"sitting_and_reading",val:0},
                {inputtype:"radio",choice_title:"Slight chance of dozzing off",choice_name:"sitting_and_reading",val:1},
                {inputtype:"radio",choice_title:"Moderate chance of dozing off",choice_name:"sitting_and_reading",val:2},
                {inputtype:"radio",choice_title:"High chance of dozing off",choice_name:"sitting_and_reading",val:3}
                
            ],
 
            accepted_no_of_ips:"single"
        },
        {
            questionId:1,
            question_image:"/questionnaires/sleepiness/People watching the news-bro.svg",
            question_title:"Watching television",
            choices:[
                {inputtype:"radio",choice_title:"Would never dose off",choice_name:"watching_television",val:0},
                {inputtype:"radio",choice_title:"Slight chance of dozzing off",choice_name:"watching_television",val:1},
                {inputtype:"radio",choice_title:"Moderate chance of dozing off",choice_name:"watching_television",val:2},
                {inputtype:"radio",choice_title:"High chance of dozing off",choice_name:"watching_television",val:3}
                
            ],
 
            accepted_no_of_ips:"single"
        },
        {
            questionId:2,
            question_image:"/questionnaires/sleepiness/Consulting-bro.svg",
            question_title:"Sitting inactive in public -- for eg: a theatre or meeting",
            choices:[
                {inputtype:"radio",choice_title:"Would never dose off",choice_name:"sitting_inactive_in_public",val:0},
                {inputtype:"radio",choice_title:"Slight chance of dozzing off",choice_name:"sitting_inactive_in_public",val:1},
                {inputtype:"radio",choice_title:"Moderate chance of dozing off",choice_name:"sitting_inactive_in_public",val:2},
                {inputtype:"radio",choice_title:"High chance of dozing off",choice_name:"sitting_inactive_in_public",val:3}
                
            ],
 
            accepted_no_of_ips:"single"
        },
        {
            questionId:3,
            question_image:"/questionnaires/sleepiness/car.svg",
            question_title:"As a passanger in a car for an hour without break",
            choices:[
                {inputtype:"radio",choice_title:"Would never dose off",choice_name:"passanger_in_car_without_break",val:0},
                {inputtype:"radio",choice_title:"Slight chance of dozzing off",choice_name:"passanger_in_car_without_break",val:1},
                {inputtype:"radio",choice_title:"Moderate chance of dozing off",choice_name:"passanger_in_car_without_break",val:2},
                {inputtype:"radio",choice_title:"High chance of dozing off",choice_name:"passanger_in_car_without_break",val:3}
                
            ],
 
            accepted_no_of_ips:"single"
        },
        {
            questionId:4,
            question_image:"/questionnaires/sleepiness/afternoon-lunch.svg",
            question_title:"Lying down to rest in the afternoon",
            choices:[
                {inputtype:"radio",choice_title:"Would never dose off",choice_name:"lying_in_afternoon",val:0},
                {inputtype:"radio",choice_title:"Slight chance of dozzing off",choice_name:"lying_in_afternoon",val:1},
                {inputtype:"radio",choice_title:"Moderate chance of dozing off",choice_name:"lying_in_afternoon",val:2},
                {inputtype:"radio",choice_title:"High chance of dozing off",choice_name:"lying_in_afternoon",val:3}
                
            ],
 
            accepted_no_of_ips:"single"
        },
        {
            questionId:5,
            question_image:"/questionnaires/sleepiness/People talking-bro.svg",
            question_title:"Sitting an talking to someone",
            choices:[
                {inputtype:"radio",choice_title:"Would never dose off",choice_name:"talking_to_someone",val:0},
                {inputtype:"radio",choice_title:"Slight chance of dozzing off",choice_name:"talking_to_someone",val:1},
                {inputtype:"radio",choice_title:"Moderate chance of dozing off",choice_name:"talking_to_someone",val:2},
                {inputtype:"radio",choice_title:"High chance of dozing off",choice_name:"talking_to_someone",val:3}
                
            ],
 
            accepted_no_of_ips:"single"
        },
        {
            questionId:6,
            question_image:"/questionnaires/sleepiness/afternoon-lunch.svg",
            question_title:"Sitting quietly after lunch",
            choices:[
                {inputtype:"radio",choice_title:"Would never dose off",choice_name:"sitting_after_lunch",val:0},
                {inputtype:"radio",choice_title:"Slight chance of dozzing off",choice_name:"sitting_after_lunch",val:1},
                {inputtype:"radio",choice_title:"Moderate chance of dozing off",choice_name:"sitting_after_lunch",val:2},
                {inputtype:"radio",choice_title:"High chance of dozing off",choice_name:"sitting_after_lunch",val:3}
                
            ],
 
            accepted_no_of_ips:"single"
        },
        {
            questionId:7,
            question_image:"/questionnaires/sleepiness/car.svg",
            question_title:"In car, while stopped in traffic",
            choices:[
                {inputtype:"radio",choice_title:"Would never dose off",choice_name:"in_car_while_stopped_traffic",val:0},
                {inputtype:"radio",choice_title:"Slight chance of dozzing off",choice_name:"in_car_while_stopped_traffic",val:1},
                {inputtype:"radio",choice_title:"Moderate chance of dozing off",choice_name:"in_car_while_stopped_traffic",val:2},
                {inputtype:"radio",choice_title:"High chance of dozing off",choice_name:"in_car_while_stopped_traffic",val:3}
                
            ],
 
            accepted_no_of_ips:"single"
        },

    ]
}