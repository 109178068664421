import React, { Fragment, useState,useEffect } from 'react'
import Swipe from 'react-easy-swipe';
import {NavLink, Redirect, useLocation} from 'react-router-dom';
import {Tabs,Tab} from 'react-bootstrap';
import {  animateScroll as scroll } from 'react-scroll';
import { useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router';
import '../css/recomendation.css';

const iconObj={
    include:'bx bx-check-circle',
    avoid:'bx bx-minus-circle' ,
    reduce:'bx bx-down-arrow-circle' 
}
const styleObj={
    slider_bef:{border:'1px solid green',
    transform:'translateX(22px)'},
    slider:{
        backgroundColor:'green'
    }
}

const recm_desc={
    superfood:'Try including these immunity boosting superfoods in your diet',
    supplement:'Given below are some Nutrition supplements that will be helpful in better management and faster recovery from the disease. Do check with your health expert for this.',
    yoga:'Following the below Yoga practices helps boost immunity, and improve organ functions. These will also help you relieve stress and anxiety',
    ayurveda:'Try including these ayurvedic food items in your diet'
}

  


function EachRecm({recm,type,filter}){
    const {url} = useRouteMatch()

    // const getStickers=()=>{
    //     if(filter==='all'){
    //         return(<div></div>)
    //     }else{
    //         if((type!=='supplement' && type!=='yoga') && recm.for_panel.includes(filter.panelName.toLowerCase().split(' ')[0])){
    //             return(<div className='filterSticker'>  <img src={`${process.env.PUBLIC_URL}/report_assets/img/panels/${filter.icon}`} alt={filter.icon} className="stickerIcon"></img></div>)
    //         }
    //     }
    // }
    const getString=()=>{
        if(type==='superfood' || type==='ayurveda'){
            let str=[]
            Object.keys(recm.why).forEach((key,i)=>{
                str.push(<div key={`${type}why_${i}`} style={{marginRight:'7px'}}>
                    {key.replaceAll('_',' ')}
                    {recm.why[key]<0?<i class='bx bx-up-arrow-alt bx-rotate-180' style={{color:'#FFB429',fontWeight:'bold'}}></i>:<i class='bx bx-up-arrow-alt' style={{color:'#FF3A1A',fontWeight:'bold'}}></i>}
                    
                </div>)
            })
            return <div style={{display:'flex',flexWrap:'wrap'}}>{str}</div>
        }else{
            return(<div>none</div>)
        }
    }
    return(
        <div className='eachRecmConatiner'>
            <div className="eachRecmImage">
            <img src={`${process.env.PUBLIC_URL}/report_assets/img/${type}/${recm.display_name.toLowerCase().replaceAll(' ','_')}.jpg`} alt={recm.display_name}></img>
            
            </div>
            <div className='eachRecmDescription'>
                <div style={{display:'flex',alignItems:'center'}}>
                <div className="eachRecmTitle">{recm.display_name}</div>
                {/* {getStickers()} */}
                </div>
                
               { type==='yoga' && <div className="eachRecmTime"><i className='bx bx-time' ></i>{recm.duration}</div>}
               
                {(type!=='yoga' && type!=='supplement') && <div className='whyRecommended'>
                    <strong>Recommended Because:- </strong>
                    {getString()}</div>}
                <NavLink to={{
                    pathname:`${url}/${type}/${recm.display_name.replace(' ','_')}`,
                    recmDetail:recm,
                    filter:filter
                }} className="practiseNow">{type==='yoga'?'Practice Now':'See Full Details'} <i class='bx bx-chevrons-right' ></i></NavLink>
            </div>
        </div>
    )
}
function DietRecommendation({data}){
    const [cat1,setCat1]=useState('all')
    const [filter,setFilter]=useState(false)
    
    const renderListElements=(option)=>{
            let li= Object.keys(data[option]).map((key,i)=>{
                let liWithin=''
                data[option][key].forEach((el,index,array)=>{
                    if(cat1==='all'){
                        liWithin+=`${el.product_details.display_name}${array[index+1]?', ':' '}`
                    }
                    else if(array[index].product_details['cat1'].includes(cat1)){
                        liWithin+=`${el.product_details.display_name}${array[index+1]?', ':' '}`
                    }
                    
                });
                if(liWithin.length>0){
                    return(
                    
                        
                        <li className="recmListItemContainer" key={i}>
                            <span className={`${option}_title recmListTitle`}><i className={iconObj[option]}></i>{`${key.split('_').join(' ')}: `} </span>
                            <span className="recmListItems">{liWithin}</span>
                        </li>
    
                
                        
                    )
                }
            })
            
            if(li.every(el=>el===undefined)){
                return('none')
            }else {
                return(
                    <ul className={`${option}List`}>
                        {li}
                    </ul>
                    )
            }
    }
   
    const getVegOptions=(e)=>{
        e.preventDefault()
        setFilter(prev=>(prev?false:true))
        setCat1(prev=>(prev==='all'?'vgtn':'all'))
        
    }
    const optionContainers=Object.keys(data).map(opt=>{
        const items=renderListElements(opt)
        
        if(items!=='none'){
            return(
                <div className={`${opt}Container`} key={`${opt}`}>
                <div className={`${opt}Head`}>{opt}</div>
                
                    {items}
                
            </div>
    
            )
        }else{
            return(<div></div>)
        }
        
        
    })
    return(
        <div className="diet_recm" >
            <div className='recmPanel_desc'>
                <p>Good nutrition is crucial for health 🥗, particularly during current times, when the immune system needs to fight back. Follow the below diet guidelines to boost your immunity 📈</p>
            </div>
            {<div className='switchWrapper'>
                <label className="switch" onClick={getVegOptions} >
                    <input type="checkbox" defaultChecked={filter} />
                    <span className="slider" style={filter?styleObj.slider:{}}>
                        <span className='slider-before' style={filter?styleObj.slider_bef:{}} >
                            
                        </span>
                    </span>
                </label>
                <span style={{marginLeft:'0.5rem'}}>Show only veg options</span>

            </div>}
            <div className='optionContainerWrapper' style={{minHeight:'50vh'}}> 

                {optionContainers}
            </div>
            
            
            
            
            
        </div>
    )
}
function KnowMore({type,filter}) {
    const recommendationData = useSelector(state=>state.analyzer.report_details.recommendations[type].recommendations)
    // temporary check
    if(Object.keys(recommendationData).length>0 ){    
        const recms=Object.keys(recommendationData).map((recmKey,i)=>{
            return(
                <EachRecm recm={recommendationData[recmKey]} type={type} key={`eachRecm_${type}${i}`} filter={filter}></EachRecm>
            )
        })


        return (
            <Fragment>
        
               
                <div className="oneRecmList" style={{minHeight:'calc(100vh - 104px)'}}>
                    <div className='recmPanel_desc'>
                        <p>{recm_desc[type]}</p> 
                    </div>
                    {recms}
                </div>

            </Fragment>
            
        )
    }else{
       return( <div style={{minHeight:'calc(100vh - 104px)',display:'flex',alignItems:'center',justifyContent:'center'}}>No {type} recommended</div>)
    }
}

function AllRecommendations() {
    scroll.scrollToTop({delay:0,duration:0})
    
    const rid = useParams().rid
    const {url} = useRouteMatch()
    const {activeState}=useLocation()
    let {filter}=useLocation() 
    // console.log(url.replace('/recommendations',''))
    filter=filter===undefined?"all":filter
    const recommendations=useSelector(state=>state.analyzer.report_details.recommendations);
    const maps={
        diet:'0',
        superfood:'1',
        ayurveda:'2',
        yoga:'3',
        supplement:'4'
      
    }
    
    

    // states
    const [activeTab,setactiveTab]=useState(maps[activeState])
    const [recmFilter,setrecmFilter]=useState(filter)
    // swipe settings
    const swipeRight=(e)=>{
        // console.log('ri')
        setactiveTab(prev=>(prev!=='0'?(parseInt(prev)-1).toString():'0'))
    }
    const swipeLeft=(e)=>{
        // console.log('lef')
        setactiveTab(prev=>(prev!=='4'?(parseInt(prev)+1).toString():'4'))
    }
    const activateKey=(k)=>{
        setactiveTab(k)
       
    }
    

    if(recommendations){
    return (
        <Fragment>
        <div className="navbar">
                    <NavLink to={{pathname:url.replace('/recommendations',''),filter:recmFilter}}><i className='bx bx-left-arrow-alt'></i></NavLink>
                    <p>Recommendations</p>
                    
        </div>
       
        
            <div className="allRecommendations" >
                <Tabs defaultActiveKey='0' activeKey={activeTab} id="contaierScroll"onSelect={(k)=>activateKey(k)}  >

                    
                        <Tab eventKey="0" title='🥗 Diet' >
                            <Swipe onSwipeLeft={swipeLeft} onSwipeRight={swipeRight} tolerance={70} allowMouseEvents={true}>
                                <DietRecommendation data={recommendations['diet']}></DietRecommendation>
                            </Swipe>
                        </Tab>
                   

                   
                        <Tab eventKey="1" title="🫐 Superfoods">
                            <Swipe onSwipeLeft={swipeLeft} onSwipeRight={swipeRight} tolerance={70} allowMouseEvents={true}>
                                <KnowMore filter={recmFilter} type='superfood' ></KnowMore>
                            </Swipe>
                        
                        </Tab>
                  

                    
                        <Tab eventKey="2" title="🌿 Ayurveda">
                            <Swipe onSwipeLeft={swipeLeft} onSwipeRight={swipeRight} tolerance={70} allowMouseEvents={true}>
                                <KnowMore filter={recmFilter} type='ayurveda' ></KnowMore>
                            </Swipe>   
                        </Tab>
                

                    
                    
                        <Tab eventKey="3" title="🧘‍♂️ Yoga">
                            <Swipe onSwipeLeft={swipeLeft} onSwipeRight={swipeRight} tolerance={70} allowMouseEvents={true}>
                                <KnowMore filter={recmFilter} type='yoga' ></KnowMore> 
                            </Swipe>
                        </Tab>
                
                    
                    <Tab eventKey="4" title="💊 Supplements">
                        <Swipe onSwipeLeft={swipeLeft} onSwipeRight={swipeRight} tolerance={70} allowMouseEvents={true}>
                    
                            <KnowMore filter={recmFilter} type='supplement' ></KnowMore >
                        </Swipe>
                    
                       
                        
                
                    </Tab>
                
                    
                    
                </Tabs>
                
                
                
            </div>
        
        </Fragment>
    )}else{
        return(<Redirect to={"wb/smart_report/"+rid}></Redirect>)
    }
}

export default AllRecommendations
