import React from 'react';
import { ImageUrl } from './HelperFunctions';

// add more loaders

function Loader() {
    const style={
        maxWidth:'100vw',
        minWidth:'100%',
        height:'100vh',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
    return (
        <div style={style}>
            {/* <img style={{height:"4rem"}} src={ImageUrl('loader.gif')} alt="Neodocs loader"></img>  */}
            <lottie-player src="https://assets1.lottiefiles.com/temp/lf20_CwlzoW.json"  background="transparent"  speed="1"  loop  autoplay style={{height:'fit-content',width:'20vw'}}></lottie-player>
        </div>
    )
}

export default Loader
