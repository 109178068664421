import React from 'react';
import Header from '../../../../components/Header/Header'
import Footer from '../../../../components/Footer/Footer'
import { NavLink } from 'react-router-dom';
import { ImageUrl } from '../../../../components/HelperFunctions';
import './Accuracy.css';

function AccuracyAndLabs() {
  return (
    <div>
        <Header></Header>
            <section className='accuracy-hero' style={{background:`url(${ImageUrl('poct/clinically-valid.jpg')})`,backgroundSize:'cover',backgroundPosition:'center',backgroundRepeat:'no-repeat',color:'#d5d5d5',paddingTop:'9rem',height:'60vh'}}>
                <div>
                    <h1 className='big-header-h1'>Neodocs and Accuracy<span style={{color:'var(--primary-color-green)'}}>.</span> </h1>
                    <h1 className='sub-header' style={{maxWidth:'80%'}}>
One of the foremost pillars of healthcare is accuracy. And here at Neodocs, our top priority across everything we do is to provide accurate results and information. 
</h1>
                </div>
                
            </section>
            <section className='accuracy-details'>
                <div className='our-test-kits'>
                    
                    <p className='sub-header'>We keep evaluating our tests extensively in our labs - across thousands of samples, while simulating multiple external conditions using different smartphones and lighting conditions. But to verify our results and get an official stamp of accuracy - we conducted an independent clinical trial at one of the leading medical institutions in the country - <strong>Sion Hospital, Mumbai. (Lokmanya Tilak Municipal General Hospital and Lokmanya Tilak Municipal Medical College, Sion Mumbai)</strong></p>
                   
                   
                </div>
                {/* <div className='our-tech'>
                    <h1 className='big-header-h2'>Our testing kits</h1>
                    <p className='sub-header'>CDSCO manufacturing license</p>
                    
                    
                </div>
                

                <div className='our-test-kits'>
                    <h1 className='big-header-h2'>Our manufacturing facility</h1>
                    <p className='sub-header'>Our manufacturing facility is ISO 13485 certified. FDA wholesale, FDA retail licence.</p>
                    
                   
                </div> */}
                
                <div className='our-thirdparty'>
                    <h1 className='big-header-h2'>Third party product evaluation results</h1>
                    <p className='sub-header'>Clinical trials are research studies on human participants designed to answer specific questions about biomedical or behavioral interventions, including new treatments and known interventions that warrant further study and comparison. Sion Hospital is one of the most reputable hospitals and medical colleges in India, with a rich culture of supporting medical innovation, as a result having a strong infrastructural setup for conducting clinical trials.<br></br><br></br>As a part of our trial - 210 patient samples were collected, & the NeoDocs test kit results were compared to those of a Gold Standard Diagnostic Machine at the hospital. , and the findings demonstrate the accuracy rate for the Neodocs test card as follows:</p>
                    <img src={ImageUrl('poct/labtest-table.png')} alt="neodocs accuracy"></img>
                
                </div>
            </section>
            <section style={{backgroundImage:`url(${ImageUrl('poct/diagnostics-future.jpg')})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',minHeight:'40vh',color:'#d5d5d5',backgroundPosition:'cennter'}}>
                        <div >
                            <h1 className="big-header-h1" >This is the future of Diagnostics<span style={{color:'var(--primary-color-green)'}}>.</span> </h1>    
                        </div>
                        <NavLink to='/our-technology' className='action-btn__primary'>Deep dive into the science</NavLink>
                        {/* <img style={{marginBottom:0}} id="hero_img" src="/docassets/img/poct/our-tech/hero-1.jpg" alt="" className="translate" /> */}
                        
                </section>
            
            
        <Footer></Footer>
    </div>
    
  )
}

export default AccuracyAndLabs