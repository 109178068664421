import React, {  useEffect, useRef, useState } from 'react';
import './Header.css';
import {NavLink} from 'react-router-dom';
import '../Header/Header.css';
import 'font-awesome/css/font-awesome.min.css';
// import {useSpring , animated, config} from 'react-spring';
import { gsap} from "gsap";            
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { ImageUrl } from '../HelperFunctions';
import ProductsDropdown from './ProductsDropdown';
gsap.registerPlugin(ScrollTrigger)


function Header() {

    const headerref = useRef(null);
    const [mobile_nav,setmobile_nav]=useState(false);
    const [productDropdown, setproductDropdown] = useState(false)
    // header color change when scrolling

    useEffect(()=>{
        
           
            gsap.fromTo(headerref.current,{backgroundColor:'transparent'},{
            
                immediateRender:false,
                backgroundColor:'#000324'
            })
        
        
        return ()=>{}
    },[])
    // slide animation for mobile
    useEffect(()=>{
        gsap.to(headerref.current.querySelector('#mobile_navs'),
            {left: mobile_nav ? 0 : window.innerWidth,})
        
    },[mobile_nav])
    return (
        <header id="header" ref={headerref}>
            <NavLink id="neodocs_logo" to='/'>
                <img src={ImageUrl('poct/neodocs-new-logo.svg')} alt="neodocs_logo"></img>
            </NavLink>
            <div id="navs">
                <div id="inner-navs"><NavLink to='/' className='nav'>Home</NavLink>
                {/* <NavLink to={{pathname:'/pricing',state:{personalisation:false,personalised_data:{}}}} className='nav'>Pricing</NavLink> */}
                <div className='nav products-nav' for='products-dropdown'><div>Products<i className='bx bx-chevron-down'></i></div><ProductsDropdown id="products-dropdown"></ProductsDropdown></div>
                
                {/* <NavLink to='/partner' className='nav'>Partner with Us</NavLink> */}
                <NavLink to='/about' className='nav' onClick={()=>setmobile_nav(false)}>Aboutus</NavLink></div>   
                <NavLink  to={{pathname:'/pricing',state:{personalisation:false,personalised_data:{}}}} className='nav action-btn__primary__bordered'>Partner with Us</NavLink>   
            </div>
            { <div id="mobile_navs" >
            
                <div className='mobile_navs__primary'>
                    <NavLink to='/' className='nav' onClick={()=>setmobile_nav(false)}>Home</NavLink>
                    {/* <NavLink to={{pathname:'/pricing',state:{personalisation:false,personalised_data:{}}}} className='nav'>Pricing</NavLink> */}
                    
                    
                    {/* <NavLink to='/partner' className='nav' onClick={()=>setmobile_nav(false)}>Partner with Us</NavLink> */}
                    <NavLink to='/about' className='nav' onClick={()=>setmobile_nav(false)}>Aboutus</NavLink>
                    {/* <NavLink to='/' className='nav' onClick={()=>setmobile_nav(false)}>Careers</NavLink> */}
                    <div className='nav products-nav'><div onClick={()=>setproductDropdown(prev=>!prev)}>Products<i className='bx bx-chevron-down'></i></div>{productDropdown && <ProductsDropdown id="products-mobile-dropdown"></ProductsDropdown>}</div>
                </div>
                <div className='mobile_navs__bottom'>
                    <h1 className='big-header-h1'>Measure what matters.</h1>
                    <NavLink to={{pathname:'/pricing',state:{personalisation:false,personalised_data:{}}}} style={{width:'12rem',height:'3rem',marginTop:'1rem'}} className=' action-btn__primary__bordered' onClick={()=>setmobile_nav(false)}>Partner with Us</NavLink>
                </div>
                
                

                
                
            </div>}
            <div className={`hamburger ${mobile_nav ? 'active' : ''}`} onClick={()=>setmobile_nav(prev=>!prev)}>
                <div className='line1'></div>
                <div className="line2"></div>
            </div>
        </header>
    )
}

export default React.memo(Header)
