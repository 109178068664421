import React,{useEffect, useRef, useState} from 'react';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
// import ParameterBiomarker from '../Usables/ParameterBiomarker';
import { ImageUrl } from '../../../../components/HelperFunctions';
import './Tech.css';
import { gsap } from "gsap";            
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { UpcomingProductContent } from '../UpcomingProducts/UpcomingProductContent';
import { NavLink } from 'react-router-dom';



function Tech() {
   
    const section_heroRef = useRef(null)
    const videoRef = useRef(null)
    const color_array = ['#3080F0','#6B60F1','#3134A3','#1E54A0','#000749'];

    useEffect(()=>{
        let videoBox = videoRef.current.querySelector('video');
        ScrollTrigger.create({
            trigger: videoRef.current,
            start: 'top center',
            end: 'bottom center',
            onEnter: () => videoBox.play(),
            onEnterBack: () => videoBox.play(),
            onLeave: () => videoBox.pause(),
            onLeaveBack: () => videoBox.pause(),
         });
       
    },[videoRef.current])
   
    return (
        <div>
            <Header></Header>
                <section id="tech_hero" ref={section_heroRef} style={{backgroundImage:`url(${ImageUrl('poct/our-tech/hero-1.jpg')})`}}>
                        <div >
                            <h1 className="big-header-h1" >This is the future of Diagnostics<span style={{color:'var(--primary-color-green)'}}>.</span> </h1>    
                        </div>
                        {/* <img style={{marginBottom:0}} id="hero_img" src="/docassets/img/poct/our-tech/hero-1.jpg" alt="" className="translate" /> */}
                        
                </section>
                    <section style={{backgroundColor:'var(--primary-color-blue)',padding:0}} ref={videoRef} >
				        
                        <video  style={{width: '100%',height:'100%',objectFit:'cover'}} playsinline="true" webkit-playsinline="true"   muted>
                            <source src={ImageUrl('poct/ourtech_new.mp4')} type="video/mp4"/>
                        </video>
			        </section>
             
             
                    <section id="our_tech">
                        <h1 className='big-header-h1'>The anatomy of Neodocs Test Card</h1>
                        <p className="section_desc">
                            <img src={ImageUrl(`poct/our-tech/anatomy-testcard.png`)} alt="Neodocs technology"/>
                        </p>
                
                    </section>
        
                
                <section id="how_recommendations" style={{backgroundColor:'var(--primary-color-blue)',padding:0,paddingTop:'var(--padding-tb-desktop)'}}>
                    <h1 className="big-header-h1" style={{textAlign: "center",color:'var(--primary-color-white)'}}>How we create your recommendations</h1>
                    <div className="how_recms_illus">
                        {/* <lottie-player src={ImageUrl("/poct/ourtech.json")}  background="transparent"  speed="1"   loop autoplay id="desktop_howrecm"></lottie-player> */}
                        <img src={ImageUrl('poct/our-tech/recommendation-how-web.png')} alt="neodocs recommendations" className="web_howrecm"/>
                        <img src={ImageUrl('poct/our-tech/recommendation-how-phone.png')}  alt="neodocs recommendation" className="mobile_howrecm"/>

                    </div>
                </section>
                <section id="upcoming-products">
                    <h1 style={{textAlign:'center'}} className='big-header-h1'>Our upcmoming products</h1>
                    <div className='all-upcoming-products__container' >
                        {Object.keys(UpcomingProductContent).map((prod,idx)=>(
                        <div className='one-upcoming-product' style={{backgroundColor:color_array[idx]}}>
                            
                            <p className='one-upcoming-product__title'>{UpcomingProductContent[prod].display_name}</p>
                            
                            <NavLink className='read-more-btn' to={`/product/${prod}`}>Know More</NavLink>
                        </div>
                        ))}
                        
                        
                    </div>
                </section>
              
                <section  id='get_app'>
                            
                       
                            <div className='heading'>
                                <h1 className='big-header-h1'>One app that takes care of it all.</h1>
                                <p className='sub-header'>Download the Neodocs app and turn your smartphone into your personal wellness tracker today!</p>
                               
                                <div className="app_btns" >
                                        <a href='https://play.google.com/store/apps/details?id=com.app.neodocs&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                        </a>
                                        
                                        <a href="https://apps.apple.com/in/app/neodocs/id1579332354?itsct=apps_box_badge&amp;itscg=30200" >
                                            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1628553600&h=9fe675414fa4d50ce298fc80da620207" alt="Download on the App Store" />
                                        </a>
                                    </div>
                            </div>
                            <div>
                                <img src={ImageUrl('poct/new-mockups/wellness_card_app.png')} alt="Neodocs app" className='app-card-img'></img>
                            </div>
                            
                            
                        </section>
         
                    <Footer></Footer>
          
       
            
		
			
			
			
			
		
		
        
        
		
		
         
         
        </div>
    )
}

export default Tech
