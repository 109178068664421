import React, { useEffect, useState } from 'react';

import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import moment from 'moment';
import {getString} from '../../../components/HelperFunctions'

function CommentBlog(props) {
    const [commentsArray,setarray]=useState(props.commentsArray);
    const [comment, setcomment] = useState({ comment: '', email: '', name: '' });
    const [comment_alert,setalert]=useState(false)
    console.log(commentsArray)
    const submit_comment = () => {
        if(comment.comment && comment.email &&  comment.name ){
          setDoc(doc(db,`blog_meta/${props.blog_id}/blog_comments/comments_${commentsArray.length}`),{ ...comment, blog_id: props.blog_id, date:new Date().toDateString() })
          .then(res=>{
            // updateDoc(doc(db,"blog_meta", props.blog_id),{no_of_comments:commentsArray.length})
            // .then(res=>{
              setcomment({ comment: '', email: '', name: '' })
              setarray(prev=>[...prev, { ...comment, blog_id: props.blog_id, date:new Date().toDateString() }])
              setalert("Added new comment.")
            // }).catch(err=>setalert("Oops! Some error occured. Please try again."))
            
          })
          .catch(err=>setalert("Oops! Some error occured. Please try again."))
        }else{
          setalert("All fields are required.")
        }
        // axios.post('/blogs/add_comment', { ...comment, blog_id: props.blog_id })
        //   .then(res => {
        //     console.log(res.data)
        //     if (res.data === "success") {
        //         console.log(res.data)
        //         setalert('Added comment')
        //         setarray(prev=>[...prev,comment])
        //             setcomment({ comment: '', email: '', name: '' })
        //     }
        //   })         
      }
      useEffect(()=>{
        
          console.log(comment_alert)
            if(!comment_alert==="Added "){
                
                setTimeout(()=>{
                    
                    setalert(false)
                },3000)
            }
      },[comment_alert])

    return (
        <div className="commentsContainer">
            <div className="allComments">

              <h3 className='big-header-h2'>Comments {commentsArray && '('+commentsArray.length+')'}</h3>

              <ul>
                {commentsArray && commentsArray.map(comment=>(
                    <li className="commentCard" data="">

                      <div className="commentor_data">
                        <div className="comment_author">{comment.name}  <div style={{ fontSize: "small",paddingLeft:'auto',color:'#a1a0a0' }}>{moment(getString(comment.date)).format('LL')} </div></div>

                      </div>
                      <div className="comment_content">
                        {comment.comment}
                      </div>
                    </li>
                  ))
                }
              </ul>


            </div>
            
            
            <div className="addComment">
              <h4 className='sub-header'> Leave
                a comment</h4>

              <form>
                <input type="text" placeholder="Name*" id="comment_author_name" required="" name='name' value={comment.name} onChange={(e) => setcomment(prev => ({ ...prev, [e.target.name]: e.target.value }))} />
                <input type="email" placeholder="Email*" id="comment_author_email" required="" name="email" value={comment.email} onChange={(e) => setcomment(prev => ({ ...prev, [e.target.name]: e.target.value }))} />

                <textarea id="comment_data" placeholder="Comment" name="comment" required="" value={comment.comment} onChange={(e) => setcomment(prev => ({ ...prev, [e.target.name]: e.target.value }))}></textarea>

                <div>
                    
                    <div className="action-btn__secondary" id="post_comment" onClick={()=>submit_comment()}>Post Comment</div>
                </div>
                
              </form>
              <p>{comment_alert}</p>
              <p style={{ position: "relative", zindex: "200", paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>🔒 We won't post your email ID</p>
            </div>
          </div>
    )
}

export default CommentBlog
