import React from 'react';
import Pricing from '../domains/mainWebsite/Pages/Pricing/Pricing';
import Tech from '../domains/mainWebsite/Pages/Tech/Tech';
import Home2 from '../domains/mainWebsite/Pages/HomePage/Home2'
import FuturePlan from '../domains/mainWebsite/Pages/FuturePlan/FuturePlan'
import Faq from '../domains/mainWebsite/Pages/FAQ/Faq';
import Contact from '../domains/mainWebsite/Pages/Contact/Contact';
import Fertility from '../domains/mainWebsite/Pages/Fertility/Fertility';
import AboutUs from '../domains/mainWebsite/Pages/AboutUs/AboutUs';
import { Route , Switch} from 'react-router-dom';
import PersonalisedQuiz from '../domains/mainWebsite/Pages/Pricing/PersonalisedQuiz';
import Partners from '../domains/mainWebsite/Pages/Partner/Partners'
import DataPrivacy from '../domains/mainWebsite/Pages/Privacy/DataPrivacy';
import PrivacyPolicy from '../domains/mainWebsite/Pages/Privacy/PrivacyPolicy';
import TermsOfService from '../domains/mainWebsite/Pages/Privacy/TermsOfService';
import UtiService from '../domains/mainWebsite/Pages/Services/UtiServices';
import UploadPortal from '../domains/pdfAnalyzer/UploadPortal/UploadPortal';
import ElderlycareService from '../domains/mainWebsite/Pages/Services/ElderlycareService';
import PregnancyService  from '../domains/mainWebsite/Pages/Services/PregnancyService';

import SmartReportContainer from '../domains/pdfAnalyzer/SmartReport/components/SmartReportContainer';
import Register from '../domains/auth/Pages/Register/Register';
import Profile from '../domains/auth/Pages/Profile/Profile'
import { setAnalyzerSource } from '../domains/pdfAnalyzer/AnalyzerSlice';

import OrderFlow from '../domains/mainWebsite/Pages/Pricing/OrderFlow';
import { useDispatch } from 'react-redux';
import AuthRoutes from './AuthRoutes';
import AnalyzerRoutes from './AnalyzerRoutes';
import BlogsRoutes from './BlogsRoutes';
import Error404 from '../components/ErrorPages/Error404';
import CkdService from '../domains/mainWebsite/Pages/Services/CkdService';
import CareerPage from '../domains/mainWebsite/Pages/CareerPage/CareerPage';
import Questionnaire from '../domains/mainWebsite/Pages/Questionnaire/Questionnaire';
import CareerDescription from '../domains/mainWebsite/Pages/CareerPage/CareerDescription';
import UpcomingProductsPage from '../domains/mainWebsite/Pages/UpcomingProducts/UpcomingProductsPage';
import AccuracyAndLabs from '../domains/mainWebsite/Pages/Privacy/AccuracyAndLabs';
import Hcg from '../domains/mainWebsite/Pages/UpcomingProducts/Hcg';

function MainWebsiteRoutes()  {  
    const dispatch = useDispatch()
    return( 
        <Switch>
            <Route path="/" exact>
                <Home2/>
            </Route>
            
            <Route path="/about" exact>
               <AboutUs></AboutUs>
            </Route>
            <Route path="/pricing" exact component={(props)=>{
            return(<Pricing location={{...props.location,state:{'personalisation':false}}}></Pricing>)}}>
               
            </Route>
            <Route path="/order/:orderid" exact>
                <OrderFlow></OrderFlow>
            </Route>
            
            <Route path="/our-technology" exact>
               <Tech></Tech>
            </Route>
            <Route path='/faq' exact>
                <Faq></Faq>
            </Route>
            <Route path='/contactus' exact>
                <Contact></Contact>
            </Route>
            <Route path='/fertility' exact>
                <Fertility></Fertility>
            </Route>
            {/* <Route path='/personalization-quiz' exact>
                <PersonalisedQuiz></PersonalisedQuiz>
            </Route> */}
            <Route path='/future-plans' exact>
                <FuturePlan></FuturePlan>
            </Route>
            <Route path='/data-privacy' exact>
                <DataPrivacy></DataPrivacy>
            </Route>
            <Route path='/privacy-policy' exact>
                <PrivacyPolicy></PrivacyPolicy>
            </Route>
            <Route path='/terms-of-service' exact>
                <TermsOfService></TermsOfService>
            </Route>
            <Route path='/accuracy' exact>
                <AccuracyAndLabs></AccuracyAndLabs>
            </Route>
            <Route path='/partner'>
                <Partners></Partners>
            </Route>
            {/* <Route path='/products/dieticians' exact>
                <DieticianService></DieticianService>
            </Route> */}
            <Route path='/products/uti' exact>
                <UtiService></UtiService>
            </Route>
            <Route path='/products/elderly-care' exact>
                <ElderlycareService></ElderlycareService>
                
            </Route>
            <Route path='/products/hcg' exact>
                <Hcg></Hcg>
            </Route>
            <Route path='/products/pregnancy' exact>
                <PregnancyService></PregnancyService>
            </Route>
            <Route path='/products/kidney' exact>
                <CkdService></CkdService>
            </Route>
            <Route path='/product/:pname' exact component={UpcomingProductsPage}></Route>
            <Route path='/register' exact component={Register}></Route>
            <Route path='/profile' exact component={Profile}></Route>
            <Route path='/pdf-analyzer' exact component={UploadPortal}></Route>
            <Route path='/career' exact component={CareerPage}></Route>
            <Route path='/career/:type/:id' exact component={CareerDescription}></Route>

            {/* questionnaire */}
            <Route path='/questionnaire/:qid' exact component={Questionnaire}></Route>
            <Route path='/smart-report/:rid' exact component={SmartReportContainer}></Route>
            
            {/* whatsapp bot rooutes */}
            <Route path='/wb/smart_report/:rid' exact render={()=>{
                dispatch(setAnalyzerSource({analyzer_source:'whatsapp'}))
                return <SmartReportContainer/>
            }} ></Route>
            <Route path='/wb/register' exact component={Register}></Route>
            {AuthRoutes}
            {BlogsRoutes}
            {AnalyzerRoutes}
            {/* upcoming products routes */}
            
            <Route path="*" component={Error404}></Route>    
        </Switch>
    )
}

export default MainWebsiteRoutes


