import React,{useContext} from 'react';
import { useSelector } from 'react-redux';
import {patientContext} from './Context';
import {ReportImageUrl} from '../../../../components/HelperFunctions'

const whatsapp_share_msg = `I just came across this amazing product that converts your medical report such that it is coherent and its extremely easy to use! 🤯
Just send your pdf to https://wa.me/917400281971 and you will get a link containing your Smart Report along with health recommendations.`



function OverallHealthScore(){
    // useSelector to fetch health score from patient_details when using this componennt.
    const {health_score:score,score_flag}=useContext(patientContext).patientDetails
   if(score_flag===0){

   
    const transitionForOverall=()=>{
        const transitionDuration = 1000
        let res={
            circle:{},
            text:{},
            main:{},
            sticker:{}
        }
        let circumference =  2*Math.PI*48;
        let note = score;
        note =note/10;
        
        res.circle['--initialStroke']=circumference
        // 2*Math.PI*25.6(radius)
        
        if (note<6) {
            
        //    '#F65E5B'
            res.circle.stroke='lightcoral';
            res.text.color='lightcoral';
            res.sticker.border='0.1px solid lightcoral'
            res.sticker.color='lightcoral'
            // res.main.backgroundColor='lightcoral';
            
    
        } else if(note<8){
            res.circle.stroke=' #FFB429';
            res.text.color=' #FFB429';
            res.sticker.border='0.1px solid  #FFB429'
            res.sticker.color=' #FFB429'
            
            // res.main.backgroundColor=;
        }
        else{
            res.circle.stroke='#23E5AF';
            res.text.color='#23E5AF';
            res.sticker.border='0.1px solid #23E5AF'
            res.sticker.color='#23E5AF'
           
            

        }
        let offset = circumference * (10 - note) / 10;
        res.circle['strokeDashoffset']=offset
        res.circle['--transitionDuration']=`${transitionDuration}ms`
        
       
        return res;
       
       
    }

    return(
        <div className="wellness-score"
       >
            <div className="progress-bar">
            <svg width='100%' height='100%' className='circle_wrapper'>
                <circle className="circle-progress" cx='70' cy='70' r='3rem'></circle>
                <circle className="circle-progress circle-progress-fill" cx='70' cy='70' r='3rem' style={transitionForOverall().circle }></circle>
            </svg>
           
            <span id='overall-wellness-score'  style={transitionForOverall().text}> <p>{score}%</p></span>
            
            </div>
           
      </div>
        
    )}else{
        
        return(
        <div className='wellness-score'>
            <div className="progress-bar" >
                <div className='qualitativeScore'>

                </div>
                
            </div>
        </div>)
    }
}
function PatientData(){
    const patientDetails=useSelector(state=>state.analyzer.report_details.patient_details)
    
    return(
        <div className='personal-deets'>
         
           
            
            <div style={{display:'flex',height:'100%',width:'100%',margin:'1rem',marginBottom:'0px',marginTop:'0px'}} >
                {/* <div className="eachDeet">
                    <p className="eachDeetHead">Name:</p>
                    <p className="eachDeetDetail">{patientDetails.name}</p>
                </div> */}
                <div className="eachDeet">
                    <p className="eachDeetHead">Age:</p>
                    <p className="eachDeetDetail">{patientDetails.age} Years</p>
                </div>
                <div className="eachDeet">
                    <p className="eachDeetHead">Gender:</p>
                    <p className="eachDeetDetail"> {patientDetails.gender.toLowerCase().includes('f')?'Female':patientDetails.gender.toLowerCase().includes('m')?'Male':patientDetails.gender.toLowerCase().includes('non')?'Non-Binary':''} </p>
                    
                </div>
                
                
                
            
                
               
              
               
             
                
            </div>
               
        </div>
    )
}

function PatientInfo(props){
    const patient_name = useSelector(state=>state.analyzer.report_details.patient_details.firstName)
    let date=new Date()
    let currTime=date.getHours()
    console.log(currTime)
    const status=currTime<12?'Good Morning':currTime<=16?'Good Afternoon':'Good Evening';
  
    return(
        <div className='PatientInfo'>
            <div className='greetingMsgBox' style={{backgroundImage:`url(${ReportImageUrl('pattern1.svg')})`}}>
                <p className="introp">
                    <h2>   <span style={{fontWeight:'100'}}>{status} <strong style={{textTransform:'capitalize'}}>{patient_name && patient_name.split(' ')[0]}</strong>,</span> <br></br>
                        {/* <span style={{textTransform:'capitalize'}}>{patientDetails.name.split(' ').slice(0,2).join(' ')}</span>, */}
                    </h2> 
                    Here is your upgraded<br></br> heath report analysis with recommendations</p>
                {/* <OverallHealthScore></OverallHealthScore> */}
                <div id="share-report-whatsapp"><a href={`whatsapp://send?text=${whatsapp_share_msg}`} data-action="share/whatsapp/share"  
        target="_blank"><i className='bx bx-share-alt' ></i> Share the app </a> </div>

            </div>
            
           
            {/* <OverallHealthScore ></OverallHealthScore> */}
            <PatientData></PatientData>
        </div>
    )
}
export default PatientInfo;