import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ImageUrl } from '../../../../components/HelperFunctions';
import './Testimonials.css';
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const testimonialArray=[
    {
        username:'Rushabh Rambhia',
        designation:'happy user',
        testimonial:'Love this app, I use it every other week to gauge my progress and it really goes hand in hand with my performance. If I lack water it will let me know, and when I follow the instructions my performance is amazing. Give it a go you will not be disappointed!'
    },
    {
        username:'Kapil Deshpande',
        designation:'happy user',
        testimonial:'I love this product. Made my whole family try and now we are having a competition to see who can score 10/10 for everything first! I like how it gives suggestions on things like what you can eat to improve your pH levels. Very easy to use.'
    },
    {
        username:'Venkatesh Amrutwar',
        designation:'happy user',
        testimonial:'This product is simple and personal. After only 1 minute, my analysis came on my screen. It gave me info about what nutrients I was lacking, along with the need to hydrate.'
    },
    {
        username:'Akash Gangwar',
        designation:'happy user',
        testimonial:'Personalized nutrition advice is amazing. I can track my body data at home. It is like 2030'
    },
    {
        username:'Manasvi Paradkar',
        designation:'happy user',
        testimonial:'Was extremely curious to try this and it has lived up to that. Love that they have registered nutritionists to guide with all queries.'
    },
    {
        username:'Rucha Walawalkar',
        designation:'happy user',
        testimonial:'It’s great how the results are on your phone right away. I will keep using it because it allows me to see results in the comfort of my own home. Using it is a part of my weekly routine now.'
    },
    {
        username:'Jaivardhan Lal',
        designation:'happy user',
        testimonial:'Was an eye-opener. Didn’t know my hydration levels were so off. Love that all guidance has been backed by detailed research.'
    },
    {
        username:'Yash Sakhlecha',
        designation:'happy user',
        testimonial:'App is perfect, strips are very easy to use. Thank you very much guys I am a lazy person but I can finally track my health status and give attention to my body.'
    }
]
const hcgTestimonialArray = [
    
    {
        username:'Manasvi Paradkar',
        designation:'happy user',
        testimonial:'Was extremely curious to try this and it has lived up to that. Love that they have registered nutritionists to guide with all queries.'
    },
    {
        username:'Rucha Walawalkar',
        designation:'happy user',
        testimonial:'It’s great how the results are on your phone right away. I will keep using it because it allows me to see results in the comfort of my own home. Using it is a part of my weekly routine now.'
    },
    {
        username:'Rucha Walawalkar',
        designation:'happy user',
        testimonial:'It’s great how the results are on your phone right away. I will keep using it because it allows me to see results in the comfort of my own home. Using it is a part of my weekly routine now.'
    },
    {
        username:'Rucha Walawalkar',
        designation:'happy user',
        testimonial:'It’s great how the results are on your phone right away. I will keep using it because it allows me to see results in the comfort of my own home. Using it is a part of my weekly routine now.'
    },
    
]

function Testimonials(props) {
    const testimonialRef = useRef([]);
    const array = props.type ? hcgTestimonialArray : testimonialArray;
    const actionBtnLink = props.link || '/pricing'
    const testimonialCards = array.map(testimonial=>(<div className='testimonial-card'>
        <div className='testimonial-quote'>
        <i class='bx bxs-quote-alt-left' style={{color:'#dadada'}}  ></i>
            {testimonial.testimonial}
            
        </div>
        <div className='testimonial-author-details'>
            <div className='testimonial-autor-img'>
                <img src={ImageUrl('testimonials/'+testimonial.username+'.jpg')} alt="Neodocs review"></img>
            </div>
            <div className='testimonial-autor-meta'>
                <div className='testimonial-autor-name'>{testimonial.username}</div>
                <div className='testimonial-autor-designation'>{testimonial.designation}</div>
            </div>
        </div>
    </div>))

    useEffect(()=>{
        let communityArray = [...testimonialRef.current.querySelectorAll('.community-illustration__person')]
        let testimonialTimelime = gsap.timeline({scrollTrigger:{
            trigger:testimonialRef.current,
            start:'top top+=50%',
            end: ()=> window.innerHeight,
            id:'testimonialTriggers',
    
            
            
           
        }})
        communityArray.forEach((person,i) => {
            testimonialTimelime.fromTo(person,{opacity:0},{opacity:1,duration:0.2})
        })
        return () => {ScrollTrigger.getAll("testimonialTriggers").forEach(trigger => trigger.kill())}
    },[])
    
    return (<section id="testimonials" ref={testimonialRef}>
        <h1 className='big-header-h1'>Join a community of happy customers.
<span style={{color:'var(--primary-color-green)'}}>...</span></h1>
        <div className='community-illustration-wrapper'>
            <div className='community-illustration__person you_div'>You</div>
            <img src={ImageUrl('testimonials/Wave.svg')} alt="wave-pattern" className='wave-patten'></img>
           
            <img src={ImageUrl('testimonials/Rushabh Rambhia.jpg')} style={{top:'15rem',left:'1rem'}} alt="Neodocs review" className='community-illustration__person'></img>

            <img src={ImageUrl('testimonials/Yash Sakhlecha.jpg')} alt="Neodocs review"  style={{top:'7.2rem',left:'3.44rem'}} className='community-illustration__person'></img>

            <img src={ImageUrl('testimonials/Venkatesh Amrutwar.jpg')} alt="Neodocs review"  style={{top:'15rem',right:'8rem'}} className='community-illustration__person'></img>
            <img src={ImageUrl('testimonials/Rucha Walawalkar.jpg')} alt="Neodocs review"  style={{top:'-0.5rem',right:'13rem'}} className='community-illustration__person'></img>

            <img src={ImageUrl('testimonials/Akash Gangwar.jpg')} alt="Neodocs review"  style={{top:'-0.3rem',left:'0rem'}} className='community-illustration__person'></img>

            <img src={ImageUrl('testimonials/Kapil Deshpande.jpg')} alt="Neodocs review"  style={{top:'16rem',left:'20rem'}} className='community-illustration__person'></img>
            <img src={ImageUrl('testimonials/Jaivardhan Lal.jpg')} alt="Neodocs review"  style={{top:'8rem',right:'0rem'}} className='community-illustration__person'></img>
            <img src={ImageUrl('testimonials/Manasvi Paradkar.jpg')} alt="Neodocs review"  style={{top:'-1rem',right:'3rem'}} className='community-illustration__person'></img>
            {/* additional for pc */}
            <img src={ImageUrl('testimonials/ShrutiKhairkar.jpg')} alt="Neodocs review"  style={{top:'-1rem',right:'3rem'}} className='community-illustration__person additional_pc'></img>
            <img src={ImageUrl('testimonials/rajendra.png')} alt="Neodocs review"  style={{bottom:'3rem',right:'30%'}} className='community-illustration__person additional_pc'></img>
            <img src={ImageUrl('testimonials/rajesh.png')} alt="Neodocs review"  style={{top:'0rem',right:'55%'}} className='community-illustration__person additional_pc'></img>
            <img src={ImageUrl('testimonials/testimo3.png')} alt="Neodocs review"  style={{top:'7rem',right:'70%'}} className='community-illustration__person additional_pc'></img>
            <img src={ImageUrl('testimonials/testimo4.png')} alt="Neodocs review"  style={{bottom:'0rem',right:'50%'}} className='community-illustration__person additional_pc'></img>
        </div>
        <div className='testimonial-card-wrapper'>
            <div className='testimonial-card-scroller'>
                {testimonialCards}
                
            </div>
        </div>
        <NavLink to={actionBtnLink} className="action-btn__primary center" style={{width:'fit-content',marginTop:'3rem'}}>Join the community</NavLink>
    </section>)
}

export default Testimonials
