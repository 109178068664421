import React,{useState,useEffect} from 'react';
import QuestionSlide from './QuestionSliders/QuestionSlide';
import AnalyzerRoutes from '../../../../routes/AnalyzerRoutes'


import { useDispatch, useSelector } from 'react-redux';
import { putReport } from '../../AnalyzerSlice';
import { useParams, useHistory } from 'react-router-dom';
import * as QuestionData from "../../../../components/Constants/Questions"


function SmartReportContainer() {
    const rid=useParams().rid;
    const dispatch = useDispatch();
    const initialState = useSelector(state=>state.analyzer.report_details);
    const [modal,setmodal] = useState(true);
    
    useEffect(()=>{
        dispatch(putReport(rid))
    },[rid])
    
    if(Object.keys(initialState).includes('panel_details')){
    
        return (
            <div className="mainContainer " id="smartReportMainContainer" onClick={()=>setmodal(false)}>
                <div style={modal?{display:'block'}:{display:'none'}} className='disclaimerModalContainer'>
                    <div className='disclaimerModal'>
                        <h5 style={{color:'#FF5F45',fontWeight:'bold',display:'flex',alignItems:'center'}}><i class='bx bx-info-circle'></i>Medical Disclaimer</h5>
                        <div>The information on this site is not intended or
                        implied to be a substitute for professional medical advice, diagnosis or treatment. All content,
                        including text, graphics, images and information, contained on or available through this web site is for
                        general information purposes only. You are encouraged to confirm any information obtained from or
                        through this web site with a physician. Never disregard professional medical advice or delay seeking
                        medical treatment because of something you have read on or accessed through this web site.</div>
                        <div onClick={()=>setmodal(false)} className="modalOk"> I understand</div>
                    </div>

                </div>
                <QuestionSlide opt={(initialState.is_other_patient && !initialState.is_result_updated) || (initialState.test_taken_date === null)} QuestionData={initialState.test_taken_date === null ? initialState.is_other_patient ? QuestionData.NO_TEST_DATE_DIFFERENT_USER_QUESTIONS : QuestionData.NO_TEST_DATE_SAME_USER_QUESTIONS : QuestionData.DIFFERENT_USER_QUESTIONS} ></QuestionSlide>
                {AnalyzerRoutes}
            </div>
            
        
    )}
    else{
        return<div style={{height:'100vh',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',fontSize:'1.5rem'}} className='reportLoader'>
            Loading your report
            <lottie-player src="https://assets1.lottiefiles.com/temp/lf20_CwlzoW.json"  background="transparent"  speed="1"  loop  autoplay style={{height:'fit-content'}}></lottie-player>
            </div>
    }
}

export default SmartReportContainer
