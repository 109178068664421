import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../domains/auth/Pages/Login/Login';
import Register from '../domains/auth/Pages/Register/Register';

export default [ <Route path='/login' exact component={Login}></Route>,
   <Route path='/register' exact component={Register}></Route>]




