import Patterns from "./Patterns"

// returns public image urls 
export const ImageUrl = (relativePath) => (`${process.env.PUBLIC_URL}/docassets/img/${relativePath}`)
export const ReportImageUrl = (relativePath) => (`${process.env.PUBLIC_URL}/report_assets/img/${relativePath}`)
export const S3ImageUrl = (mainurl,extended) => (mainurl+'/'+extended)
// Getter methods that handle undefined and null values 
export const getString = (string, defaultString="") => {
    if(string===undefined || string===null){
        return defaultString
    }else{
        return string
    }
}
export const getArray = (array, defaultArray=[]) => {
    if(array===undefined || array===null){
        return defaultArray
    }else{
        return array
    }
}



