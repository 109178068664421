export const ORDER_DETAILS =  {
    18:{
        "id": 18,
        "name": "Twelve Month Box",
        "slug": "twelve-month-box",
        "permalink": "https://wellnesscards.in/product/twelve-month-box/",
        "date_created": "2022-02-10T15:24:20",
        "date_created_gmt": "2022-02-10T15:24:20",
        "date_modified": "2022-02-10T18:30:43",
        "date_modified_gmt": "2022-02-10T18:30:43",
        "type": "simple",
        "status": "publish",
        "featured": false,
        "catalog_visibility": "visible",
        "description": "",
        "short_description": "",
        "sku": "",
        "price": "2499",
        "regular_price": "4999",
        "sale_price": "2499",
        "date_on_sale_from": null,
        "date_on_sale_from_gmt": null,
        "date_on_sale_to": null,
        "date_on_sale_to_gmt": null,
        "on_sale": true,
        "purchasable": true,
        "total_sales": 0,
        "virtual": false,
        "downloadable": false,
        "downloads": [],
        "download_limit": -1,
        "download_expiry": -1,
        "external_url": "",
        "button_text": "",
        "tax_status": "taxable",
        "tax_class": "",
        "manage_stock": false,
        "stock_quantity": null,
        "backorders": "no",
        "backorders_allowed": false,
        "backordered": false,
        "low_stock_amount": null,
        "sold_individually": true,
        "weight": "0.450",
        "dimensions": {
            "length": "21",
            "width": "17",
            "height": "7"
        },
        "shipping_required": true,
        "shipping_taxable": true,
        "shipping_class": "",
        "shipping_class_id": 0,
        "reviews_allowed": true,
        "average_rating": "0.00",
        "rating_count": 0,
        "upsell_ids": [],
        "cross_sell_ids": [],
        "parent_id": 0,
        "purchase_note": "",
        "categories": [
            {
                "id": 15,
                "name": "Uncategorised",
                "slug": "uncategorised"
            }
        ],
        "tags": [],
        "images": [],
        "attributes": [],
        "default_attributes": [],
        "variations": [],
        "grouped_products": [],
        "menu_order": 0,
        "price_html": "<del aria-hidden=\"true\"><span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">&#8377;</span>4,999.00</bdi></span></del> <ins><span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">&#8377;</span>2,499.00</bdi></span></ins>",
        "related_ids": [
            15,
            16
        ],
        "meta_data": [],
        "stock_status": "instock",
        "_links": {
            "self": [
                {
                    "href": "https://wellnesscards.in/wp-json/wc/v3/products/18"
                }
            ],
            "collection": [
                {
                    "href": "https://wellnesscards.in/wp-json/wc/v3/products"
                }
            ]
        }
    },
    16:{
        "id": 16,
        "name": "Three Month Box",
        "slug": "three-month-box",
        "permalink": "https://wellnesscards.in/product/three-month-box/",
        "date_created": "2022-02-10T15:23:35",
        "date_created_gmt": "2022-02-10T15:23:35",
        "date_modified": "2022-02-10T18:30:14",
        "date_modified_gmt": "2022-02-10T18:30:14",
        "type": "simple",
        "status": "publish",
        "featured": false,
        "catalog_visibility": "visible",
        "description": "",
        "short_description": "",
        "sku": "",
        "price": "999",
        "regular_price": "1999",
        "sale_price": "999",
        "date_on_sale_from": null,
        "date_on_sale_from_gmt": null,
        "date_on_sale_to": null,
        "date_on_sale_to_gmt": null,
        "on_sale": true,
        "purchasable": true,
        "total_sales": 4,
        "virtual": false,
        "downloadable": false,
        "downloads": [],
        "download_limit": -1,
        "download_expiry": -1,
        "external_url": "",
        "button_text": "",
        "tax_status": "taxable",
        "tax_class": "",
        "manage_stock": false,
        "stock_quantity": null,
        "backorders": "no",
        "backorders_allowed": false,
        "backordered": false,
        "low_stock_amount": null,
        "sold_individually": true,
        "weight": "0.450",
        "dimensions": {
            "length": "21",
            "width": "17",
            "height": "7"
        },
        "shipping_required": true,
        "shipping_taxable": true,
        "shipping_class": "",
        "shipping_class_id": 0,
        "reviews_allowed": true,
        "average_rating": "0.00",
        "rating_count": 0,
        "upsell_ids": [],
        "cross_sell_ids": [],
        "parent_id": 0,
        "purchase_note": "",
        "categories": [
            {
                "id": 15,
                "name": "Uncategorised",
                "slug": "uncategorised"
            }
        ],
        "tags": [],
        "images": [],
        "attributes": [],
        "default_attributes": [],
        "variations": [],
        "grouped_products": [],
        "menu_order": 0,
        "price_html": "<del aria-hidden=\"true\"><span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">&#8377;</span>1,999.00</bdi></span></del> <ins><span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">&#8377;</span>999.00</bdi></span></ins>",
        "related_ids": [
            18,
            15
        ],
        "meta_data": [],
        "stock_status": "instock",
        "_links": {
            "self": [
                {
                    "href": "https://wellnesscards.in/wp-json/wc/v3/products/16"
                }
            ],
            "collection": [
                {
                    "href": "https://wellnesscards.in/wp-json/wc/v3/products"
                }
            ]
        }
    },
    15:{
        "id": 15,
        "name": "One Month Box",
        "slug": "3-month-subscription",
        "permalink": "https://wellnesscards.in/product/3-month-subscription/",
        "date_created": "2022-02-10T14:58:32",
        "date_created_gmt": "2022-02-10T14:58:32",
        "date_modified": "2022-02-10T18:29:34",
        "date_modified_gmt": "2022-02-10T18:29:34",
        "type": "simple",
        "status": "publish",
        "featured": false,
        "catalog_visibility": "visible",
        "description": "",
        "short_description": "",
        "sku": "",
        "price": "499",
        "regular_price": "749",
        "sale_price": "499",
        "date_on_sale_from": null,
        "date_on_sale_from_gmt": null,
        "date_on_sale_to": null,
        "date_on_sale_to_gmt": null,
        "on_sale": true,
        "purchasable": true,
        "total_sales": 15,
        "virtual": false,
        "downloadable": false,
        "downloads": [],
        "download_limit": -1,
        "download_expiry": -1,
        "external_url": "",
        "button_text": "",
        "tax_status": "taxable",
        "tax_class": "",
        "manage_stock": false,
        "stock_quantity": null,
        "backorders": "no",
        "backorders_allowed": false,
        "backordered": false,
        "low_stock_amount": null,
        "sold_individually": true,
        "weight": "0.450",
        "dimensions": {
            "length": "21",
            "width": "17",
            "height": "7"
        },
        "shipping_required": true,
        "shipping_taxable": true,
        "shipping_class": "",
        "shipping_class_id": 0,
        "reviews_allowed": true,
        "average_rating": "0.00",
        "rating_count": 0,
        "upsell_ids": [],
        "cross_sell_ids": [],
        "parent_id": 0,
        "purchase_note": "",
        "categories": [
            {
                "id": 15,
                "name": "Uncategorised",
                "slug": "uncategorised"
            }
        ],
        "tags": [],
        "images": [],
        "attributes": [],
        "default_attributes": [],
        "variations": [],
        "grouped_products": [],
        "menu_order": 0,
        "price_html": "<del aria-hidden=\"true\"><span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">&#8377;</span>749.00</bdi></span></del> <ins><span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">&#8377;</span>499.00</bdi></span></ins>",
        "related_ids": [
            18,
            16
        ],
        "meta_data": [],
        "stock_status": "instock",
        "_links": {
            "self": [
                {
                    "href": "https://wellnesscards.in/wp-json/wc/v3/products/15"
                }
            ],
            "collection": [
                {
                    "href": "https://wellnesscards.in/wp-json/wc/v3/products"
                }
            ]
        }
    },
    11:{
        "id": 11,
        "name": "Test",
        "slug": "test",
        "permalink": "https://wellnesscards.in/?post_type=product&p=11",
        "date_created": "2022-02-09T13:04:52",
        "date_created_gmt": "2022-02-09T13:04:52",
        "date_modified": "2022-02-10T16:59:00",
        "date_modified_gmt": "2022-02-10T16:59:00",
        "type": "simple",
        "status": "draft",
        "featured": false,
        "catalog_visibility": "visible",
        "description": "",
        "short_description": "",
        "sku": "",
        "price": "0",
        "regular_price": "0",
        "sale_price": "",
        "date_on_sale_from": null,
        "date_on_sale_from_gmt": null,
        "date_on_sale_to": null,
        "date_on_sale_to_gmt": null,
        "on_sale": false,
        "purchasable": true,
        "total_sales": 1,
        "virtual": false,
        "downloadable": false,
        "downloads": [],
        "download_limit": -1,
        "download_expiry": -1,
        "external_url": "",
        "button_text": "",
        "tax_status": "taxable",
        "tax_class": "",
        "manage_stock": false,
        "stock_quantity": null,
        "backorders": "no",
        "backorders_allowed": false,
        "backordered": false,
        "low_stock_amount": null,
        "sold_individually": false,
        "weight": "",
        "dimensions": {
            "length": "",
            "width": "",
            "height": ""
        },
        "shipping_required": true,
        "shipping_taxable": true,
        "shipping_class": "",
        "shipping_class_id": 0,
        "reviews_allowed": true,
        "average_rating": "0.00",
        "rating_count": 0,
        "upsell_ids": [],
        "cross_sell_ids": [],
        "parent_id": 0,
        "purchase_note": "",
        "categories": [
            {
                "id": 15,
                "name": "Uncategorised",
                "slug": "uncategorised"
            }
        ],
        "tags": [],
        "images": [],
        "attributes": [],
        "default_attributes": [],
        "variations": [],
        "grouped_products": [],
        "menu_order": 0,
        "price_html": "<span class=\"woocommerce-Price-amount amount\"><bdi><span class=\"woocommerce-Price-currencySymbol\">&#8377;</span>0.00</bdi></span>",
        "related_ids": [
            15,
            18,
            16
        ],
        "meta_data": [],
        "stock_status": "instock",
        "_links": {
            "self": [
                {
                    "href": "https://wellnesscards.in/wp-json/wc/v3/products/11"
                }
            ],
            "collection": [
                {
                    "href": "https://wellnesscards.in/wp-json/wc/v3/products"
                }
            ]
        }
    }
}

export const CREATE_ORDER_BIOLERPLATE = { 
    "payment_method": "razorpay",
    "payment_method_title": "razorpay",
    "set_paid": false,
    "billing": {
      "first_name": "",
      "last_name": "",
      "address_1": "",
      "address_2": "",
      "city": "",
      "state": "",
      "postcode": "",
      "country": "",
      "email": "",
      "phone": ""
    },
    "shipping": {
      "first_name": "",
      "last_name": "",
      "address_1": "",
      "address_2": "",
      "city": "",
      "state": "",
      "postcode": "",
      "country": "",
      "phone":""
    },
    "line_items": [
      {
        "product_id": 15,
        "quantity": 1
      }
    ],
    "shipping_lines" :[
        {
            "method_id": "free_shipping", 
            "method_title": "Free shipping", 
            "total": "0.00"
      }
    ],
    "coupon_lines": [
        
    ]
  }