import React, { useState,useEffect } from 'react'

const choiceCategory={
    single:['radio','text'],
    multiple:['checkbox']
}

// returns a radio or checkbox 
function Option(props) {
 
    const [value,setvalue]=useState(props.choice.inputtype==='checkbox' ? false : props.choice.inputtype==='radio' ? '' : '')
   
    const checkValue=(e)=>{
        if(props.choice.inputtype==='checkbox'){
            setvalue(e.target.checked)
        }else{
            console.log(e.target.value)
            setvalue(e.target.value)
        }
       
       
    }
    useEffect(() => {
        console.log({[props.choice.choice_name]:value})
        props.putValue({[props.choice.choice_name]:value})
    }, [value])

    

    return (
        <div className={`optionContainer ${(value && props.choice.inputtype!=='text')?'checked':'unchecked'} ${props.choice.inputtype}`  } >
            <label>
                <input type={props.choice.inputtype} onChange={(e)=>checkValue(e)} value={props.choice.val} placeholder={props.choice.inputtype==='text'?'Enter Value':''} name={props.choice.choice_name}></input>
                {props.choice.inputtype!=='text' && <p className='opt_name'>{props.choice.choice_title}</p>}
            </label>
            
        </div>
    )
}

export default Option
