import { createSlice } from "@reduxjs/toolkit";

export const BlogSlice=createSlice({
    name:'blogs',
    initialState:{
        blog_id : '',
        blog_details : {}
    },
    reducers: {
        getBlog:(state,action)=>{
            state.blog_id.fname='sakshi'
        }
    }
})
export default BlogSlice.reducer