import { createSlice } from "@reduxjs/toolkit";

export const WebsiteSlice=createSlice({
    name:'website',
    initialState:{
        website:'',
        ui:{
            alert:''
        }
    },
    reducers: {
        getWebsite:(state,action)=>{
            state.website='sakshi'
        },
        setAlert:(state,action)=>{
            console.log("98765432"+action.payload)
            state.ui.alert=action.payload
        }
    }
})

export const {getWebsite, setAlert} = WebsiteSlice.actions
export default WebsiteSlice.reducer