import { min } from 'moment';
import React from 'react';
import '../css/bars.css';
const colors = {
    // red: 'rgb(246,94,91)',
    red: '#FF6961',
    green: '#23e5af',
    orange: 'rgb(254,199,96)',
    greenBg: 'rgba(35,229,175,0.2)',
    redBg: 'rgba(246,94,91,0.2)'
}

function Bars({normalRange}){
    if(normalRange.is_categorical===0){

    
    let {  max_value, min_value,  value,  lb_ideal, ub_ideal, lb, ub } = normalRange
    
    min_value = min_value ? min_value : 0
    lb = lb ? lb : min_value
    lb_ideal = lb_ideal ? lb_ideal : lb
    max_value = max_value ? max_value : 0
    ub = ub ? ub : max_value
    ub_ideal = ub_ideal ? ub_ideal : ub
    max_value = value > max_value ? value : max_value;
    min_value = value < min_value ? value : min_value;
    const total = max_value - min_value;
    const pointer = typeof value!=='boolean' ?value? ((value - min_value) / total) * 100 : 0:((lb_ideal-min_value)/total)*100;
    const style={
        1:(lb - min_value) * 100 / total,
        2:(-lb + lb_ideal) * 100 / total,
        3:(-lb_ideal + ub_ideal) * 100 / total,
        4:(-ub_ideal + ub) * 100 / total,
        5:(-ub + max_value) * 100 / total,
        pointer:pointer

    }
    return(
        <div className='test_bars'>
            
             <div className="percentage-bar">
                <div  className=" chart_pointer percentage-bar__pointer bar_pointer" style={{left:style['pointer']+'%'}}>
                    <div className="pointer__value">{value} </div>
                </div>
                <div className="percentage-bar__bar red_bar" style={{width:style[1]+'%'}}></div>
                <div className="percentage-bar__bar yellow_bar" style={{width:style[2]+'%'}}></div>
                <div className="percentage-bar__bar green_bar" style={{width:style[3]+'%'}}></div>
                <div className="percentage-bar__bar yellow_bar" style={{width:style[4]+'%'}}></div>
                <div className="percentage-bar__bar red_bar" style={{width:style[5]+'%'}}></div>
        </div>
        

        </div>
       
    )}else {
        
        
        return( 
            <div className='test_bars'>
               
                <div className='percentage-bar'>
                    
                    <div  className=" chart_pointer percentage-bar__pointer bar_pointer" style={{left:`${normalRange.user_value_flag===0?75:25}%`}}>
                        <div className="pointer__value"></div>
                    </div>
                    <div className="percentage-bar__bar red_bar" style={{width:'50%'}}></div>
                    <div className="percentage-bar__bar green_bar" style={{width:'50%'}}></div>
                </div>

            </div>
            
        )
    }
}
export default Bars;