import React, { useEffect, useState } from 'react';
import { useSpring, animated, config } from 'react-spring';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import { ImageUrl } from '../../../../components/HelperFunctions';
import Testimonials from '../Usables/Testimonials';
import { NavLink } from 'react-router-dom';
import './Contact.css'

    function Contact() {
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');
    
        const handleSubmit = (event) => {
            event.preventDefault();
    
            event.preventDefault();

            // Hide the contactus form
            document.querySelector('.contactus').style.display = 'none';
          
            // Display the div with the content "We will contact you shortly"
            const contactUsSuccessDiv = document.createElement('div');
            contactUsSuccessDiv.textContent = 'We will contact you shortly.You can directly contact us at +91-9619369767 or pratik@neodocs.in';
            document.querySelector('.success').appendChild(contactUsSuccessDiv);
        };
        return (
            <div >
                <Header></Header>

                <div className='faq-container'>
                    <section className='faqHead'>

                        <h1 className='big-header-h1'>Contact Us</h1>
                        <div>for Neodocs, STD Check, PregaKool</div>
                    </section>

                    <section className='contactus'>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <textarea
                                placeholder="Message"
                                value={message}
                                onChange={(event) => setMessage(event.target.value)}
                            />
                            <button type="submit">Submit</button>
                        </form>

                    </section>
                    <section className='success'></section>
                </div>
                <Testimonials></Testimonials>
                <Footer></Footer>
            </div>
        )
    }

    export default Contact
