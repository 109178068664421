import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const AnalyzerSlice=createSlice({
    name:'analyzer',
    initialState:{
        analyzer_source: sessionStorage.getItem('analyzer_source') || '',
        report_details:{
        },

    },
    reducers: {
        setAnalyzerSource:(state,action)=>{
            console.log(action.payload.analyzer_source)
            state.analyzer_source=action.payload.analyzer_source
            
        },
        setReport:(state,action)=>{
            console.log(action.payload)
            state.report_details = action.payload.reports
        },
        setReportUserDetails:(state,action)=>{
            console.log(action.payload)
            state.report_details.patient_details = {...state.report_details.patient_details,...action.payload.updated_user_details}
            state.report_details.test_taken_date = action.payload.test_taken_date ? action.payload.test_taken_date : state.report_details.test_taken_date
            
        }

    }
})

export const {setAnalyzerSource, setReport, setReportUserDetails} = AnalyzerSlice.actions

// gets the report data from getData api-  /wb/ for without auth token 
export const putReport = (report_id) => (dispatch, getState) =>{
  
    let source = getState().analyzer.analyzer_source;
    if(source==='whatsapp'){
        
        sessionStorage.setItem('analyzer_source','whatsapp')
        const headers = {
            'x-api-key': 'vByc1RZdswafTBn87Wlqx1reenvU1HK9Yjw7a5xi',
            'Content-Type': 'application/json'
          }
        axios.post("https://1vghspa9zh.execute-api.ap-south-1.amazonaws.com/Production/botgetdata",{"report_id":report_id},{'headers':headers})
        .then(res=>{
            console.log(res.data)
            if(res.data){
                if('hormone' in res.data.panel_details){
                    delete res.data.panel_details.hormone
                } 
                
                dispatch(setReport({reports:res.data}))
                
            }
        })
        
       

    }else{
        console.log('no whatsapp')
        // axios.post('v1/pdf_analyzer/getData?result_id='+report_id,{ 'headers': { 'Authorization': "Bearer "+token  } })
        axios.post('https://1vghspa9zh.execute-api.ap-south-1.amazonaws.com/Production/botgetdata',{report_id:report_id},{headers:{
            'x-api-key':'NCqeTHkBa2QTdwM3H2UXO4H9iQbb4N1eXNKbzVi0'
        }})
    
        .then(res=>{
            // console.log(res.data)
            if(res.data.statusCode===200){
                dispatch(setReport({reports:res.data.body}))
            }
        })
    }
}



export default AnalyzerSlice.reducer