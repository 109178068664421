import React from 'react';
import { NavLink } from 'react-router-dom';
import { getString, getArray, ImageUrl } from '../../../components/HelperFunctions';
import './BlogCard.css';
import moment from 'moment';
import { BLOG_AUTHORS } from '../../../components/Constants/BlogAuthors';

function BlogCard(props) {
    
    const {blog, type='horizontal-blog-card'} = props
   
    return (
         <NavLink className={"blog-card "+type} to={{pathname:`/blogs/blog/${getString(blog.title).replaceAll(' ','-').replaceAll(/[^-a-zA-Z0-9]/g,'').toLowerCase()}__${blog.id}`}}>
            <div className='blog-metadata'>
               
                
                <div className="blog-title">
                    <div className="blog-category">{getArray(blog.categories).join(', ').replaceAll('_',' ')}</div>
                        {getString(blog.title)}
                </div>
                

                <div className='blog-date'>{moment.unix(blog.updated_on.seconds).format("LL")}   <i class='bx bx-chat'></i>{blog.no_of_comments}</div>
                {type==='latest-blog-card' && <>
                        <div className='blog-desc' dangerouslySetInnerHTML={{__html:blog.intro.substr(0,250)+'...'}}>   
                        </div>
                        <div className='blog-author'>
                                <div className='author-image'>
                                    <img src={ImageUrl('/team/BlogWriters/'+BLOG_AUTHORS[blog.author].imageUrl)} alt={BLOG_AUTHORS[blog.author].display_name+" at neodocs"}></img>
                                </div>
                                <div className='author-meta'>
                                    <div className='author-name'>{BLOG_AUTHORS[blog.author].display_name}</div>
                                    <div className='author-role'>{BLOG_AUTHORS[blog.author].role}</div>
                                </div>
                        </div>
                    </>}
            </div>
            
            
            <div className="blog-image"><img src={`https://neodocs-blogs.s3.ap-south-1.amazonaws.com/blogs_compressed/${getString(blog.id)}/${getString(blog.thumbnail)}`} alt={getString(blog.title)}></img></div>
                      
                      
                      
                      
                     
        </NavLink>
    )
}

export default BlogCard
