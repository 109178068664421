import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../../../../components/Footer/Footer'
import Header from '../../../../components/Header/Header'
import { ImageUrl } from '../../../../components/HelperFunctions'
import Testimonials from '../Usables/Testimonials'
import './hcg.css'

function Hcg() {
  return (
    <div id='hcg-product'>
      <Header></Header>
        <section id="hcg-hero">
          <div>
            <h2>WORLD'S FIRST</h2>
            <h1 className='big-header-h1'>Smartphone Based Early Result Pregnancy Test</h1>
            <h2>Detects all forms of the pregnancy hormone in 3 minutes.</h2>
            <NavLink to='/products/hcg' className='action-btn__primary'>Get it now</NavLink>
          </div>
          <div className='hero-img'>
            <img alt="Neodocs pregnancy test kit" src={ImageUrl('/poct/hcgTest/herokit.png')}></img>
          </div>
            
            
        </section>
        <section>
        <h1 style={{textAlign:'center'}} className='big-header-h2'>This test can help you find the answers you are looking for by providing clear results with accuracy and convenience </h1>
          <div className='hcg-test-properties'>
                <div className='one-property'>
                  <div className='one-property__image'>
                    <img alt="Neodocs pregnancy test kit" src={ImageUrl('/poct/hcgTest/benefits/6days.svg')}></img>
                  </div>
                  <div className='one-property__desc'>No other brand tells you earlier</div>
                </div>
                <div className='one-property'>
                  <div className='one-property__image'>
                    <img alt="Neodocs pregnancy test kit" src={ImageUrl('/poct/hcgTest/benefits/accurate.svg')}></img>
                  </div>
                  <div className='one-property__desc'>Over 99% accurate from the day of your expected period</div>
                </div>
                <div className='one-property'>
                  <div className='one-property__image'>
                    <img alt="Neodocs pregnancy test kit" src={ImageUrl('/poct/hcgTest/benefits/allhormones.svg')}></img>
                  </div>
                  <div className='one-property__desc'>Detects all forms of the pregnancy hormone</div>
                </div>
                <div className='one-property'>
                  <div className='one-property__image'>
                    <img alt="Neodocs pregnancy test kit" src={ImageUrl('/poct/hcgTest/benefits/3mins.svg')}></img>
                  </div>
                  <div className='one-property__desc'>Read your results in just 3 minutes</div>
                </div>
          </div>
        </section>
        <section className='hcg-steps'>
          <h1 className='big-header-h1' style={{textAlign:'center'}}>How to take the test?</h1>
          <div className='hcg-test-steps'>
                <div className='one-hcg-step'>
                  <div className='one-hcg-step__image'>
                    <img alt="Neodocs pregnancy test kit" src={ImageUrl('/poct/hcgTest/steps/step1.png')}></img>
                  </div>
                  <div className='one-hcg-step__desc'><span className='step-hcg-no'>1</span>Collect urine sample in the cup and carefully pour 3-4 drops on the sample well of test kit where ’S’ is marked.</div>
                </div>
                <div className='one-hcg-step'>
                  <div className='one-hcg-step__image'>
                    <img alt="Neodocs pregnancy test kit" src={ImageUrl('/poct/hcgTest/steps/step2.png')}></img>
                  </div>
                  <div className='one-hcg-step__desc'><span className='step-hcg-no'>2</span> Wait for 3 minutes before reading the result. Then, scan the test device using our Neodocs Pregnancy Test App.</div>
                </div>
                <div className='one-hcg-step'>
                  <div className='one-hcg-step__image'>
                    <img alt="Neodocs pregnancy test kit" src={ImageUrl('/poct/hcgTest/steps/step3.png')}></img>
                  </div>
                  <div className='one-hcg-step__desc'><span className='step-hcg-no'>3</span>Your pregnancy result will be generated on the app in seconds with an accuracy of >99%.e</div>
                </div>
                
          </div>
        </section>
       <Testimonials type="hcg" link="/products/hcg"></Testimonials>
        <Footer></Footer>
    </div>
  )
}

export default Hcg