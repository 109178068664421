import { createSlice } from "@reduxjs/toolkit";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier} from "firebase/auth";
import axios from 'axios';
import { func } from "prop-types";




export const AuthSlice=createSlice({
    name:'auth',
    initialState:{
        token : sessionStorage.getItem('auth_token') || '',
        user_details : JSON.parse(sessionStorage.getItem('user_details')) || {},
        statusCode:''
    },
    reducers: {
        loginUser:(state,action)=>{
            state.user_details = action.payload.statusCode === 201 ? {mobile_no:action.payload.user_details.mobile_no,uId:action.payload.user_details.uId} : action.payload.user_details;
            state.statusCode=action.payload.statusCode;
            state.token=action.payload.statusCode===200? action.payload.token :''
            
        }
    }
})

export const { loginUser } = AuthSlice.actions



export const getotp = (phoneNumber) => (dispatch) => {
    console.log("clicked", phoneNumber)
    const auth = getAuth();
    console.log(auth)
    //fn defn
    const getotpp = (phoneNumber) => {
        console.log("in")
        let appVerifier = window.recaptchaVerifier
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
        console.log(phoneNumber)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                dispatch(loginUser({user_details:{mobile_no:phoneNumber} , statusCode:201}))   
            }).catch((error) => {
                console.log(error)
                appVerifier.render().then(function(widgetId) {
                    window.grecaptcha.reset(widgetId);
                })
        });
    }

    window.recaptchaVerifier =  new RecaptchaVerifier('login-button', {'size': 'invisible',callback:(token)=>{
        console.log(token)
        getotpp(phoneNumber)
    }}, auth);
    
    
    

    
    


}



export const getOTP = (user_details) => (dispatch,getState) => {
    let {mobile_no,otp} = user_details;
    // logic-- if statusCode==='' then get otp and if statusCode===201 then login
    if(getState().auth.statusCode===''){
        
        axios.post("v1/pdf_analyzer/login",{"mobile_no":mobile_no})
            .then(res=>{
                console.log(res.data)
                if(res.data.statusCode===201){
                    dispatch(loginUser({user_details:{mobile_no:mobile_no} , statusCode:201}))   
                }
                else{
                    alert('Error')
                }
            })
    }else if(getState().auth.statusCode===201){
        axios.post("v1/pdf_analyzer/login",{"mobile_no":mobile_no,"otp":otp})
        .then(res=>{
            console.log(res.data)
            if(res.data.statusCode===200){
                
                dispatch(loginUser({user_details:res.data.body.user_details,token:res.data.body.auth_token, statusCode:200})) 
                sessionStorage.setItem('auth_token',res.data.body.auth_token)
                sessionStorage.setItem('user_details',JSON.stringify(res.data.body.user_details))
            }
            else{
                
                alert('Error')
            }
        })
    }
}

export const edit_profile = (user_details) => (dispatch,getState) => {
    axios.post('v1/pdf_analyzer/edit_profile',user_details,{headers:{'':"Bearer "+getState().auth.token}})
}


export default AuthSlice.reducer