import { useSelector } from "react-redux";
// all routes
import MainWebsiteRoutes from "./routes/MainWebsiteRoutes";
import BlogsRoutes from "./routes/BlogsRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import AnalyzerRoutes from "./routes/AnalyzerRoutes";
// alerts
import FullPagePopUp from "./components/Alerts/FullPagePopUp";
import ReactGa from "react-ga";
import { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router";
import Swipe from "react-easy-swipe";
import Error404 from "./components/ErrorPages/Error404";


function App() {
  const alert = useSelector(state=>state.website.ui.alert)
  const location = useLocation()

  // GA
  useEffect(()=>{
    ReactGa.initialize('UA-63348141-4')
    // to view page
    ReactGa.pageview(location.pathname + location.search)
    window.scrollTo(0,0)
    },[location])
  
  
  return (
    
  
    <div className="App">
 
          {alert!=='' && <FullPagePopUp></FullPagePopUp>}
          {/* <AuthRoutes></AuthRoutes> */}
          <MainWebsiteRoutes></MainWebsiteRoutes>
          {/* <BlogsRoutes></BlogsRoutes> */}
          
          
          
         

          
    </div>
 
    
  );
}

export default App;
