import React from 'react';
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import './DataPrivacy.css'
import { ImageUrl } from '../../../../components/HelperFunctions';

function DataPrivacy() {
    return (
        <div>
            <Header></Header>
            <section class="privacyhero">
        
                <div class="info">
                    <h1 className='big-header-h1'>Your privacy comes first<span style={{color:'var(--primary-color-green)'}}>.</span></h1>
                    <h2 className='sub-header' >When you test your health with NeoDocs, you entrust us with important personal information. That’s why, since day one, protecting your privacy has been our number one priority. We're committed to providing you with a safe place where you can learn about your health knowing your privacy is protected.</h2>
                </div>
            </section>
            <section class="control" style={{paddingTop:'2rem'}}>
                <div>
                    <h2 className='big-header-h1'>Not just health, you also control your data</h2>
                    
                </div>
                <div class="checklist">
                    <div class="one_check"> We will never share your evaluated or self-reported data with employers, insurance companies, public databases or 3rd party marketers without your explicit consent.</div>
                    <div class="one_check">We give you full control to decide how your information is used and with whom it is shared.</div>
                    <div class="one_check">We encrypt all sensitive information and conduct regular assessments to identify security vulnerabilities and threats.</div>
                    
                </div>
            </section>
            <Footer></Footer>
        </div>
    )
}

export default DataPrivacy
