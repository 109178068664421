import React, { Fragment, useEffect, useRef } from 'react';
import Footer from '../../../../components/Footer/Footer';
import Header from '../../../../components/Header/Header';
import './Partners.css'
function Partners() {
    const ref = useRef([]);
    useEffect(()=>{
        ref.current.forEach(el=>{
           el.addEventListener("click",function(e){
               e.target.closest('.one-partner').querySelector('.dropdown-content').classList.toggle('hide')
               
           })
        })
    },[ref])
    const addToPartner = (el) =>{
        if(el && !ref.current.includes(el)){
            ref.current.push(el)
        }
    }
    return (
        <Fragment>
            <Header/>
            <div className="partners">
            <div style={{fontSize:'3rem',fontWeight:'700'}}>I'm a...</div>
            <div className="influencer one-partner" ref={addToPartner}>
                <div className="partner-type">Influencer<i class='bx bxs-chevron-down'></i></div>
                <div className="partner-desc">Apply to be part of the Neodocs Collective, where influencers share their experience using Neodocs products.</div>
                <div className="dropdown-content hide">
                    form
                </div>
            </div>
            <div className="ambassador one-partner" ref={addToPartner}>
                <div className="partner-type">Ambassador<i class='bx bxs-chevron-down'></i></div>
                <div className="partner-desc">Become an ambassador of Neodocs by sharing Neodocs products with your clients, patients, and community.</div>
                <div className="dropdown-content hide">
                    form
                </div>
            </div>
            <div className="affiliate one-partner" ref={addToPartner}>
                <div className="partner-type">Affiliate<i class='bx bxs-chevron-down'></i></div>
                <div className="partner-desc">Share Neodocs through affiliate marketing channels.</div>
                <div className="dropdown-content hide">
                    Join via Share a Sale
                </div>
            </div>
            <div className="wholesale one-partner" ref={addToPartner}>
                <div className="partner-type">Wholesale, Brand Partners, All Others <i class='bx bxs-chevron-down'></i></div>
                <div className="partner-desc"></div>
                <div className="dropdown-content hide">
                    Send us an email to  with a bit about your company and how you'd like to work with Neodocs.
                </div>
            </div>
            
        </div>
        <Footer/>

        </Fragment>
        
    )
}

export default Partners
