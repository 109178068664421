import React, { Fragment } from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import './RecipeBlog.css';
import Loader from '../../../components/Loader';
import './SingleBlog.css'
function RecipeBlog({ recipeBlogs }) {

    const recipe_details = {
        "section": [
            {
                "prep_time": "1.5hrs",
                "ingredients": [
                    "Paneer","Bell Peppers","Onions",
                    "Mushrooms & Zucchini (Optional)",
                    "Yogurt",
                    "Gram Flour",
                    "Ginger & Garlic",
                    "Spices (Turmeric, Red Chilli Powder, Cumin Powder, Garam Masala, Chat Masala)",
                    "Salt","Oil","Lemon Juice","Dry Fenugreek (Methi) Leaves"
                ],
                "instructions": [
                    "In a large bowl add hung curd, besan, ginger-garlic paste, turmeric powder, red chili powder, cumin powder, garam masala, chaat masala, kasuri methi, lemon juice, salt, oil, and mix everything together.",
                    "Add the paneer, bell peppers, and onion. Toss well to coat. Cover the bowl and refrigerate for at least 30 minutes or refrigerate for up to 24 hours.",
                    "Meanwhile, soak wooden skewers in cold water for at least 10 minutes which prevents them from burning while in the air fryer, oven or grilling.",
                    "Take a skewer, gently thread paneer, bell peppers and onion on a skewer, alternating with each other. Similarly prepare the remaining skewers.",
                    "Preheat the air fryer/oven to 360°F (180°C). Arrange all the prepared skewers inside the basket ½-inch apart in a single layer. Brush or spray some oil on the top, this ensures the paneer and veggies don't dry out.",
                    "Cook for 5 minutes at 360°F (180°C). After 5 minutes, turn the skewers, lightly spray or brush with oil and cook for another 2 to 3 minutes.",
                    "Remove on a serving plate, squeeze some lime juice, sprinkle chaat masala and serve it immediately with mint chutney."
    
    
    
    
                ],
                "nutrition": {
                    "calories": "",
                    "proteins": ""
                }
            }
        ],
        "subtitle": "A keto recipe for vegetarians.",
        "author_name": "Aradhya Giri",
        "title": "Paneer Tikka with Veggies",
        "thumbnail": ""
    }
    


    return (
        <Fragment>
           
            {Object.keys(recipe_details).length > 0 ?
                <section id="recipe-blog-container">
                    <div className="left-side"></div>
                    <div className="blog-center">
                        <div className="blogCover" style={{ backgroundImage: `url('${recipe_details.thumbnail}')` }}>
                            <div className="blog_desc">
                                <div className="blog-type">
                                    <p>Recipe</p>
                                </div>
                            </div>
                            <h1 className="blog_title">{recipe_details.title}</h1>
                            <div className="blog_date">12-11-2021 | <span className="author_name"><b>{recipe_details.author_name}</b></span></div>
                        </div>

                        <div className="recipe-desc">
                            <h4 className="head-4">Description :</h4>
                            <p>{recipe_details.subtitle}</p>
                        </div>

                        {recipe_details.section.map((ing) => {
                            return (
                                <div className="recipe-content">
                                    <div className="prep_time">
                                        <h4 className="head-4">Preparation Time : </h4>
                                        <p>{ing.prep_time}</p>
                                    </div>
                                    <div className="ingredients-section">
                                        <h4 className="head-4">Ingredients :</h4>
                                        <ul className="section-ul">
                                            {recipe_details.section[0].ingredients.map(ing => (
                                                <li className="section-li">{ing}</li>
                                            ))}
                                        </ul>

                                    </div>
                                </div>
                            )

                        })}

                        <div className="instructions-section">
                            <h4 className="head-4">Instructions To Prepare :</h4>
                            <ul className="section-ul">
                                {recipe_details.section[0].instructions.map(ins => (
                                    <li className="section-li">{ins}</li>
                                ))}
                            </ul>

                        </div>

                        <div className="nutrition-section">
                            <h4 className="head-4">Nutritional Value : </h4>
                            {recipe_details.section.map(nut => (
                                <>
                                    <p>Calories : {nut.nutrition.calories}</p>
                                    <p>Proteins : {nut.nutrition.proteins}</p>
                                </>
                            ))}
                        </div>



                    </div>


                </section> : <Loader />}


            
        </Fragment>
    )
}

export default RecipeBlog
