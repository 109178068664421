import React, { useEffect, useState } from 'react'

/** This input takes the following properties as compulary props 
 *  type = text/email/number  - the type of input needed for the proper keypad
 *  the regex expected - the regex will be tested against the input simultaneously when the user inputs, for showing appropriate status to the user
 * onSubmit = a function that handles the submission of the input
 * */ 

function KeyboardInputs(props) {
    const {type, regex, style, label, onSubmit} = props;
    const [input, setinput] = useState('');
    const [inputPhase, setInputPhase] = useState('start')

    useEffect(()=>{
        if(input!==''){
            if(regex.test(input)){
                setInputPhase('correct')
                props.onSubmit(input)
            }
            else{setInputPhase('typing')}
        }
    },[input])
  return (
    <label className='keyboard-input'>
        <div className='keyboard-input__label'>{label}</div>
        <input type={type} style={{...style}} onChange={(e)=>setinput(e.target.value)} value={input} className='keyboard-input__inputbox'></input>
        <div className='keyboard-input__input-phase-container'>
            {inputPhase==='typing' && <i class='bx bx-loader-circle bx-spin' ></i>}
            {inputPhase==='correct' && <i class='bx bxs-check-circle'></i>}
        </div>
    </label>
  )
}

export default KeyboardInputs